import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import bg from "../../assets/images/maintenance-bg.png";
//Import Breadcrumb

class ExtIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                    <Row className="justify-content-center mtrow">
                                <Col className="mtimg mt-5" sm={4}>
                                    <div className="maintenance-img">
                                        <img src={bg} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                    <h3 className="mt-5 text-center">This Function is Under Development</h3>
                                </Col>
                            </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ExtIntegration