import React, { Component } from "react";
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import {connect} from "react-redux";
import {settourtomodeltourobj} from "../../store/layout/actions";
import { withRouter } from 'react-router-dom';

const steps = [
    {
        element: '#ux_ques',
        intro: 'Please click getting started icon!',
        title: "Getting started",
        position: 'left',
      },
    {
        element: '#ux_start_model_tour',
        intro: 'Please start the tour to learn how to create model and get model API endpoint!',
        title: "Getting started",
        position: 'right',
    },

];
const options = {
    exitOnOverlayClick: true,
    exitOnEsc: false, // switch to false in production
    overlayOpacity: 0.5,
    showBullets: false,
    showButtons: false,
    showStepNumbers: false,
    showProgress: false,
    hidePrev: true,
    helperElementPadding:5,
    disableInteraction: false,
    scrollToElement: true,
    scrollTo: "element",
    tooltipClass: "tourtomodelglobaltooltipClass",
    highlightClass: "tourtomodelglobalhighlightClass"
};
class TourToModelTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*stepenable: this.props.enable_tour,
            initialStep: this.props.next_step_index*/
            stepenable: false,
            initialStep: 0
            /*stepenable: true,
            initialStep: 1*/
        };
    }
    onExit() {
        document.getElementById('TourToModelTourstate').value = "";
    }
    onBeforeChange() {
    }
    componentDidMount() {
        this.props.settourtomodeltourobj(this.steps);
    }
    render() {
        return (
            <React.Fragment>
                <Steps
                    enabled={this.state.stepenable}
                    steps={steps}
                    initialStep={this.state.initialStep}
                    onExit={this.onExit.bind(this)}
                    onBeforeChange ={this.onBeforeChange.bind(this)}
                    options={options}
                    ref={steps => (this.steps = steps)}
                />
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
}
export default connect(mapStatetoProps, { settourtomodeltourobj })(withRouter(TourToModelTour));
// export default Tour;
