import React, { Component, useContext } from "react";
import {Row, Col, Alert, Card, CardBody, Button, Label, Container, InputGroup} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {private_post,private_get, alert3, open_loading, close_loading} from '../../../helpers/fackBackend_Helper';
import {POLICY_URL,  APIS_URL} from "../../../helpers/url_helper";

class ADCreatePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit: "",
            price: "",
            api_id: "",
            apis: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        var apis = await private_get(APIS_URL);
        this.setState({
            apis: apis,
        });
        if (apis.length > 0) {
            this.setState({
                api_id: apis[0].id
            });
        }
    }
    handleChange(e) {
        this.setState({
            api_id: e.target.value
        });
    }
    handleCancel() {
        this.props.history.push("/admin/billing");
    }
    async handleSubmit(event, values) {
        try{
            var url = POLICY_URL;
            if (this.state.api_id === "") {
                alert3('The API is not selected or does not exist', 'warning');return;
            }
            if (isNaN(parseFloat(values.price))) {
                alert3('The price you entered is not valid', 'warning');return;
            }
            values.service_type = this.state.api_id;
            open_loading();
            const resp = await private_post(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/admin/billing");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">New Policy</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation pr-0" >
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Page </Label>
                                                <AvField
                                                name="unit"
                                                type="number"
                                                errorMessage="Enter Page count"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.unit}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Price</Label>
                                                <InputGroup className="policy_price_inputgr">
                                                    <span className="input-group-append">
                                                        <span className="input-group-text">$</span>
                                                      </span>
                                                    <AvField
                                                    name="price"
                                                    type="text"
                                                    errorMessage="Enter Price"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.price}
                                                    />
                                                </InputGroup>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Model</Label>
                                                <select className="form-control" name='api_id' value={this.state.api_id} onChange={this.handleChange.bind(this)}>
                                                  {this.state.apis.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                                                </select>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADCreatePolicy