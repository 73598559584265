import React, { Component } from "react";
import {Row, Col, Alert, Button, Container, Input, Label} from "reactstrap";
// Redux
import { AvForm } from "availity-reactstrap-validation";
import { API_URL } from "../../helpers/api_helper";
import {
    alert3, close_loading,
    getLoggedInUser,
    open_loading,
    private_put,
    public_post,
    timeout
} from "../../helpers/fackBackend_Helper";
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";
import {ME_URL} from "../../helpers/url_helper";
// action

class Survey extends Component {
    constructor(props) {
        super(props);
        // handleValidSubmit
        this.state = {
            surv1: '1',
            surv2: '1',
            surv3: '1',
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit() {
        var values ={};
        values.step = "survey";
        values.answer = this.state;

        try{
            open_loading();
            const user = getLoggedInUser();
            var url = ME_URL + user.id + '/';

            const resp = await private_put(url, values);
            if (resp || resp === ''){
                alert3('Your profile has been updated successfully.', 'success');
                localStorage.setItem("is_survey_completed", true);
                this.props.history.push("/dashboard");
            }
        }
        catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async componentDidMount() {

    }
    onSvChange(e) {
        const {name, value} = e.target;
        this.setState({
          [name]: value
        });
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <div className="preprocess-header">
                            <span className="text-logo">Ellietrac AI Document</span>
                        </div>
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={5}>
                                <div className="profile-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col>
                                                <div>
                                                    <div className="p-2 mt-4">
                                                        <AvForm className="form-horizontal" onSubmit={this.handleValidSubmit}>
                                                            <div className="mb-4">
                                                                <Label className="form-label font-size-18 mb-4">How often do you work out each week?</Label>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv1" id="workradio1" value="1" defaultChecked />
                                                                    <Label className="form-check-label form-label" htmlFor="workradio1">
                                                                        Every day
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv1" id="workradio2" value="2" />
                                                                    <Label className="form-check-label form-label" htmlFor="workradio2">
                                                                        Most days
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv1" id="workradio3" value="3" />
                                                                    <Label className="form-check-label form-label" htmlFor="workradio3">
                                                                        A few days
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv1" id="workradio4" value="4" />
                                                                    <Label className="form-check-label form-label" htmlFor="workradio4">
                                                                        Never
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                            <div className="mb-4">
                                                                <Label className="form-label font-size-18 mb-4">How would you prefer we contact you?</Label>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv2" id="contactradio1" value="1" defaultChecked />
                                                                    <Label className="form-check-label form-label" htmlFor="contactradio1">
                                                                        Phone Call
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv2" id="contactradio2" value="2" />
                                                                    <Label className="form-check-label form-label" htmlFor="contactradio2">
                                                                        Text
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv2" id="contactradio3" value="3" />
                                                                    <Label className="form-check-label form-label" htmlFor="contactradio3">
                                                                        Email
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv2" id="contactradio4" value="4" />
                                                                    <Label className="form-check-label form-label" htmlFor="contactradio4">
                                                                        By Mail
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                            <div className="mb-4">
                                                                <Label className="form-label font-size-18 mb-4">Are you able to find what you're looking for on the website homepage?</Label>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv3" id="homepageradio1" value="1" defaultChecked />
                                                                    <Label className="form-check-label form-label" htmlFor="homepageradio1">
                                                                        Yes
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <Input className="form-check-input" onChange={this.onSvChange.bind(this)} type="radio" name="surv3" id="homepageradio2" value="2" />
                                                                    <Label className="form-check-label form-label" htmlFor="homepageradio2">
                                                                        No
                                                                    </Label>
                                                                </div>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button  color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">Submit</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, { })(withRouter(Survey));
