import React, { Component } from 'react';
import {Row, Col, Button} from "reactstrap";
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import document1 from "../../assets/images/land/document1.svg";
import license from "../../assets/images/land/license.svg";
import {bookdemo} from "../../helpers/fackBackend_Helper";

class Document extends Component {

    constructor(props) {
        super(props);
        this.state = { name: "", company: "", phone: "", email: "", feedback: "", pages: "" };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
    }

    handleChange(){

    }

    handleSubmit(){

    }

    render() {

        return (
            <React.Fragment>
                <div className="main-content-land">
                    <section id="doc_demo" className="">
                        <div className="container">
                            <Row className="align-items-center mb-xl-5">
                                <Col xl={6} className="mb-5 pr-6 mobile_col">
                                    <div>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">EllieTrac For US<span className="land-main-color"> Driver License </span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-5">Go beyond OCR with Document AI for Driver Licenses. Extract numerous data fields with high accuracy, right out of the box. No training needed. Integrate into your new product or workflow with an easy to use REST API.</h4>
                                        <div className="d-flex">
                                            <Button onClick={() => {window.location.href = 'https://console.ellietrac.ai/register'}} className="btn bluebtn mr-3"><span className="d-flex align-items-center">Get Started</span></Button>
                                            <Button onClick={() => bookdemo(this.props.history)} className="btn whitebtn rightibtn bookbtn"><span className="d-flex align-items-center">Book Demo <i className="ri-arrow-right-line"></i></span></Button>

                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-xl-5 px-0 mobile_col">
                                    <div>
                                        <img className="w-100" src={document1} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section className="chart">
                        <div className="container">
                            <div className="chartbg">
                                <Row className="h-100 align-items-center">
                                    <Col className="chart-offset" xl={6}></Col>
                                    <Col xl={6}>
                                        <div className="pl-6 chart-r-card">
                                            <h3 className="white-color font-size-38 font-weight-bold mb-3">Accelerate Your</h3>
                                            <h3 className="white-color font-size-38 font-weight-bold mb-3">Business!</h3>
                                            <div className="d-flex">
                                                <Button onClick={() => {window.location.href = 'https://console.ellietrac.ai/register'}} className="btn transbtn mr-3"><span className="d-flex align-items-center">Get Started</span></Button>
                                                <Button onClick={() => bookdemo(this.props.history)} className="btn whitebtn rightibtn bookbtn"><span className="d-flex align-items-center">Book Demo <i className="ri-arrow-right-line"></i></span></Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </section>

                    <section id="driver_license">
                        <div className="container">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Extracts Numerous Fields From</h3>
                                    <h3 className="land-main-color font-size-38 font-weight-bold mb-3">US Driver License</h3>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <div className="d-flex justify-content-center">
                                        <img src={license} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-5 license-box">
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">ID Number</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">I1234568</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">State</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">California</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">EXP</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">08/31/2014</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">First and Last name</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">Ima Cardholder</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">DOB</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">08/21/1977</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">Issued Date</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">31 August, 2009 </h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">Sex</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">F</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">DD</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">00/00/0000NNNAN/ANFD/YY</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">Address</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">2570 24th Street, Anytown, CA 95818 </h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">Class</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">C</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="license_info">
                                        <h3 className="land-gray-color font-size-18  mb-3 font-weight-bold">End</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-3">None</h4>
                                    </div>
                                </Col>
                            </Row>


                        </div>
                    </section>

                    <section id="tech">
                        <div className="container">
                            <Row className="">
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-38 font-weight-bold mb-5">Technical <span className="land-main-color">Specification</span></h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                <table>
                                    <thead>
                                    <tr>
                                        <th className="fch">No.</th>
                                        <th>Feature</th>
                                        <th>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fch">1</td>
                                            <td>Accuracy</td>
                                            <td>Supported</td>
                                        </tr>
                                        <tr>
                                            <td className="fch">2</td>
                                            <td>Automatic Rotation Correction</td>
                                            <td>Supported</td>
                                        </tr>
                                        <tr>
                                            <td className="fch">3</td>
                                            <td>Acceptable States in US</td>
                                            <td>All Statues in the US</td>
                                        </tr>
                                        <tr>
                                            <td className="fch">4</td>
                                            <td>Extractable Fields</td>
                                            <td>ID Number, State, City, Name, DOB, ISS, Sex, Class, DD, End...</td>
                                        </tr>
                                        <tr>
                                            <td className="fch">5</td>
                                            <td>Multiple Cards Processing</td>
                                            <td>Process a Driver License Card in a document</td>
                                        </tr>
                                        <tr>
                                            <td className="fch">6</td>
                                            <td>Fine-Tune</td>
                                            <td>Supported</td>
                                        </tr>

                                    </tbody>
                                </table>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='justify-content-center d-flex'>
                                    <div className="tech_support_action justify-content-end d-flex mt-3">
                                        <a onClick={() => {window.location.href = 'https://docs.ellietrac.ai/'}} className="learn hand land-main-color">Learn more</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </section>

                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return {
        ...state.Layout
    };
}

export default withRouter(connect(mapStatetoProps, { })(Document));