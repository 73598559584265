import React, { Component, useContext } from "react";
import {
    Row,
    Col,
    Alert,
    Card,
    CardBody,
    Button,
    Label,
    Container,
    UncontrolledPopover,
    PopoverHeader, PopoverBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {
    back_to_dashboard,
    private_post,
    alert3,
    open_loading,
    close_loading,
    goNextStep, confirmNextStep
} from '../../helpers/fackBackend_Helper';
import {MY_PROJECT_URL} from "../../helpers/url_helper";
import Hotspot from "../../components/Common/hotspot";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';

class CreateProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Project", link: "/"},
                {title: "Create", link: "#"},
            ],
            project_id: "",
            project_desp: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        goNextStep(this.props.projecttourobj, 3);
    }
    handleCancel() {
        this.props.history.push("/project");
        const ux_state = confirmNextStep(3);
        if (ux_state) {
            this.props.projecttourobj.introJs.exit();
        }

    }
    async handleSubmit(event, values) {
        try{
            var url = MY_PROJECT_URL;
            open_loading();
            const resp = await private_post(url, values);
            if (resp) {
                alert3('Success', 'success');
                confirmNextStep(3);
                this.props.history.push("/project");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row className="mb-5">
                        <Col>
                            <Button color="primary" type="button" onClick={() => back_to_dashboard(this.props.history)}>Back to dashboard</Button>
                        </Col>
                    </Row>
                  <Row>
                        <Col xl={7}>
                            <h4 className="card-title-x">New Project</h4>
                            <Card id="ux_project_creat_div">
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">Project ID </Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter Projectt ID"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="project_id"
                                                value={this.state.project_id}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Project Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter Proect Description"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="project_desp"
                                                value={this.state.project_desp}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="button-items">
                                                <Col>
                                                    <Button id="ux_add_new_project" className="" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </Col>
                                            </div>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {})(withRouter(CreateProject));
