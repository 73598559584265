import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes, accountRoutes, adminPublicRoutes, adminProtectedRoutes, preProcessingRoutes, emailVerificatioinRoutes, landingRoutes, nfRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import AccountLayout from "./components/AccountLayout";
import AdminLayout from "./components/AdminLayout";
import EmailVerifyLayout from "./components/EmailVerifyLayout";
import LandingLayout from "./components/LandingPagesLayout";

// Import scss
import "./assets/scss/theme.scss";
import Alert2 from "./components/Common/Alert2";
import Loading from "./components/Common/Loading";
import Tour from "./components/Common/Tour";
import PaymentTour from "./components/Common/paymentTour";
import ProjectTour from "./components/Common/projectTour";
import ModelTour from "./components/Common/modelTour";
import TourToProjectTour from "./components/Common/TourToProjectTour";
import TourToModelTour from "./components/Common/TourToModelTour";

//Fake backend
// import fakeBackend from './helpers/AuthType/fakeBackend';

//Firebase helper
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_APIKEY,
// 	authDomain: process.env.REACT_APP_AUTHDOMAIN,
// 	databaseURL: process.env.REACT_APP_DATABASEURL,
// 	projectId: process.env.REACT_APP_PROJECTID,
// 	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// 	appId: process.env.REACT_APP_APPID,
// 	measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getLayout = this.getLayout.bind(this);
	}
	/**
   * Returns the layout
   */
	getLayout = () => {
		let layoutCls = VerticalLayout;

		switch (this.props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	render() {
		const Layout = this.getLayout();

		return (
			<React.Fragment>
				<Router>
					<Switch>

						{adminPublicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={true}
								isPreprocessing={false}
							/>
						))}
						{adminProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={AdminLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isAdpAdmin={true}
								isPreprocessing={false}
							/>
						))}

						{accountRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={AccountLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isAdpAdmin={false}
								isPreprocessing={false}
							/>
						))}


						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isAdpAdmin={false}
								isPreprocessing={false}
							/>
						))}
						{emailVerificatioinRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={EmailVerifyLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isAdpAdmin={false}
								isPreprocessing={true}
							/>
						))}



						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={false}
								isPreprocessing={false}

							/>
						))}

						{landingRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={LandingLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={false}
								isPreprocessing={false}
								exact={route.exact}
							/>
						))}

						{nfRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isAdpAdmin={false}
								isPreprocessing={false}

							/>
						))}



					</Switch>
				</Router>
				<Alert2/>
				<Loading/>
				<Tour/>
				<PaymentTour/>
				<ModelTour/>
				<ProjectTour/>
				<TourToProjectTour/>
				<TourToModelTour/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};


export default connect(mapStateToProps, null)(App);