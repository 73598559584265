import React, { Component } from "react";
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import {connect} from "react-redux";
import {setprojecttourobj} from "../../store/layout/actions";
import { withRouter } from 'react-router-dom';

const steps = [
    {
        element: '#ux_setting_li',
        intro: 'Please click the setting menu item!',
        title: "Getting started",
        position: 'left',
      },
    {
        element: '#ux_project_li',
        intro: 'Please click the project menu item!',
        title: "Getting started",
        position: 'left',
    },
    {
        element: '#ux_add_new_project_button',
        intro: 'Please click the "New Project" button!',
        title: "Getting started",
        position: 'left',
    },
    {
        element: '#ux_project_creat_div',
        intro: 'Please fill the project ID, description and save those!',
        title: "Getting started",
        position: 'left',
    },
    {
        element: '#ux_project_detail_view',
        intro: 'Please move to the project detail page',
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#ux_regenerate_token',
        intro: 'Please click the "Regenerate" button in Outh tokens section!',
        title: "Getting started",
        position: 'bottom',
    },
    /*{
        element: '#ux_regeneratetoken_modal .modal-content',
        intro: 'Please enter your password and submit!',
        title: "Getting started",
        position: 'bottom',
        helperElementPadding:0,
    },*/
    {
        element: '#ux_copy_access_token_button',
        intro: 'Please copy the access token!',
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#ux_copy_refresh_token_button',
        intro: 'Please copy the refresh token!',
        title: "Getting started",
        position: 'right',
    },
];
const options = {
    exitOnOverlayClick: true,
    exitOnEsc: false, // switch to false in production
    overlayOpacity: 0.5,
    showBullets: false,
    showButtons: false,
    showStepNumbers: true,
    showProgress: true,
    hidePrev: true,
    helperElementPadding:5,
    disableInteraction: false,
    scrollToElement: true,
    scrollTo: "element",
    tooltipClass: "projectglobaltooltipClass",
    highlightClass: "projectglobalhighlightClass"
};
class ProjectTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*stepenable: this.props.enable_tour,
            initialStep: this.props.next_step_index*/
            stepenable: false,
            initialStep: 0
            /*stepenable: true,
            initialStep: 1*/
        };
    }
    onExit() {
        document.getElementById('projecttourstate').value = "";
    }
    onBeforeChange() {
    }
    componentDidMount() {
        this.props.setprojecttourobj(this.steps);
    }
    render() {
        return (
            <React.Fragment>
                <Steps
                    enabled={this.state.stepenable}
                    steps={steps}
                    initialStep={this.state.initialStep}
                    onExit={this.onExit.bind(this)}
                    onBeforeChange ={this.onBeforeChange.bind(this)}
                    options={options}
                    ref={steps => (this.steps = steps)}
                />
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
}
export default connect(mapStatetoProps, { setprojecttourobj })(withRouter(ProjectTour));
// export default Tour;
