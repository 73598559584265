import React, { Component } from "react";
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import {connect} from "react-redux";
import {setpaymenttourobj} from "../../store/layout/actions";
import { withRouter } from 'react-router-dom';

const steps = [
    {
        element: '#ux_setting_li',
        intro: 'Please click the setting menu item!',
        title: "Getting started",
        position: 'left',
      },
    {
        element: '#ux_billing_li',
        intro: 'Please click the billing menu item!',
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#ux_add_payment_method_button',
        intro: 'Please click the "Add Payment Method" button!',
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#payment_method_div',
        intro: 'Please fill all of the information required and save those!',
        title: "Getting started",
        position: 'left',
    },
];
const options = {
    exitOnOverlayClick: true,
    exitOnEsc: false, // switch to false in production
    overlayOpacity: 0.5,
    showBullets: false,
    showButtons: false,
    showStepNumbers: true,
    showProgress: true,
    hidePrev: true,
    helperElementPadding:5,
    disableInteraction: false,
    scrollToElement: true,
    scrollTo: "element",
    tooltipClass: "paymentglobaltooltipClass",
    highlightClass: "paymentglobalhighlightClass"
};
class PaymentTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*stepenable: this.props.enable_tour,
            initialStep: this.props.next_step_index*/
            stepenable: false,
            initialStep: 0
            /*stepenable: true,
            initialStep: 1*/
        };
    }
    onExit() {
        document.getElementById('paymenttourstate').value = "";
    }
    onBeforeChange() {
    }
    componentDidMount() {
        this.props.setpaymenttourobj(this.steps);
    }
    render() {
        return (
            <React.Fragment>
                <Steps
                    enabled={this.state.stepenable}
                    steps={steps}
                    initialStep={this.state.initialStep}
                    onExit={this.onExit.bind(this)}
                    onBeforeChange ={this.onBeforeChange.bind(this)}
                    options={options}
                    ref={steps => (this.steps = steps)}
                />
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
}
export default connect(mapStatetoProps, { setpaymenttourobj })(withRouter(PaymentTour));
// export default Tour;
