import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import {Row, Col, Button, Alert, Container, Label, CardBody, Card} from "reactstrap";
import StarsRating from 'react-star-rate';
import {TESTIMONIAL_URL} from "../../helpers/url_helper";
import {private_get, public_get} from "../../helpers/fackBackend_Helper";
import {API_URL} from "../../helpers/api_helper";


class Slidewithindicator extends Component {

    constructor(props) {
        super(props);
        this.state = { activeIndex: 0, animating: false, items: [] };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.setState({animating: true});
    }

    onExited() {
        this.setState({animating: false});
    }

    next() {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.state.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    async componentDidMount() {
        const resp = await public_get(API_URL + TESTIMONIAL_URL);
        var cn = resp.length;
        var m = cn % 3;
        var sub_cn = (cn - m) / 3;
        if(m !== 0){
            sub_cn = sub_cn + 1;
        }
        var t = 0;
        var new_array = [];
        for (var i =0; i < sub_cn; i++) {
            var temp = [];
            if (i * 3 > cn - 1){
                break;
            } else{
                temp.push(resp[i * 3]);
            }
            if (i * 3 + 1 > cn - 1){
                new_array.push({id: t++, stuffs: temp});
                break;
            } else{
                temp.push(resp[i * 3 + 1]);
            }
            if (i * 3 + 2 > cn - 1){
                new_array.push({id: t++, stuffs: temp});
                break;
            } else{
                temp.push(resp[i * 3 + 2]);
            }
            new_array.push({id: t++, stuffs: temp});
        }
        console.log(new_array);
        this.setState({items: new_array});
    }

    render() {

        const slides = this.state.items.map((item) => {
            var stuffs = item.stuffs;
            return (

                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    slide={true}
                    key={item.id} >
                    <Row>
                        {stuffs.map(o =>
                            <Col xl={4} className="p-4" key={o.id}>
                                <Card className="feedback-card">
                                    <CardBody>
                                        <StarsRating disabled={true} value={o.rate}/>
                                        <h4 className="elip-5 mb-4 font-size-18 land-gray-100-color">{o.content}</h4>
                                        <h4 className="font-size-20 font-weight-bold land-gray-color">{ o.name}</h4>
                                        <h4 className="font-size-18 land-gray-100-color">{ o.role}</h4>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}

                    </Row>
                </CarouselItem>
            );
        });

        return (
            <React.Fragment>
                <Carousel
                    activeIndex={this.state.activeIndex}
                    next={this.next}
                    previous={this.previous} >
                    {slides}
                    <CarouselControl direction="next" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
            </React.Fragment>
        );
    }
}

export default Slidewithindicator;