import React, { Component, useContext } from "react";
import { Row, Col, Alert, Card, CardBody,  Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {back_to_dashboard, private_post,private_get, alert3, open_loading, close_loading} from '../../../helpers/fackBackend_Helper';
import {APIS_URL, API_URL_LIST_URL} from "../../../helpers/url_helper";

class ADCreateAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            uname: "",
            desc: "",
            urls: [],
            url: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        var resp = await private_get(API_URL_LIST_URL);
        if (resp.length > 0) {
            this.setState({
                urls: resp,
                url: resp[0].url
            });
        }
    }
    handleChange(e) {
        this.setState({ url: e.target.value });
    }
    handleCancel() {
        this.props.history.push("/admin/api");
    }
    async handleSubmit(event, values) {
        try{
            var url = APIS_URL;
            values.url = this.state.url;
            open_loading();
            const resp = await private_post(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/admin/api");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">New Model</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">Model Name </Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter API Name"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">Model ID </Label>
                                                <AvField
                                                name="uname"
                                                type="text"
                                                errorMessage="Enter API UName"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.uname}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="desc">Model Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter API Description"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.desc}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="url">Backend Url</Label>
                                                <select className="form-control" name='url' value={this.state.url} onChange={this.handleChange.bind(this)}>
                                                  {this.state.urls.map(o => <option key={o.id} value={o.url}>{o.name}</option>)}
                                                </select>
                                            </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADCreateAPI