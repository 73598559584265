import React, { Component, useContext } from "react";
import { Row, Col, Alert, Card, CardBody,  Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {back_to_dashboard, private_post,private_get, alert3, open_loading, close_loading} from '../../../helpers/fackBackend_Helper';
import {APIS_URL, API_URL_LIST_URL, PAYMENT_TERM_TYPE_URL} from "../../../helpers/url_helper";

class ADCreatePaymentTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rules: [{id: 0, days: "days_0", rules: "rules_0"}]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        /*

        var resp = await private_get(API_URL_LIST_URL);
        this.setState({
            urls: resp,
            url: resp[0].url
        });

         */

    }
    add_rule_row() {
        var origarray = this.state.rules;
        if (origarray.length === 3) return;
        const param = {
            id: origarray.length, days: "days_" + origarray.length, rules: "rules_" + origarray.length
        };
        origarray.push(param);
        this.setState({
            rules: origarray
        });
    }
    remove_rule_row() {
        var origarray = this.state.rules;
        if (origarray.length === 1) return;
        origarray.pop();
        this.setState({
            rules: origarray
        });
    }
    handleCancel() {
        this.props.history.push("/admin/term-type");
    }
    async handleSubmit(event, values) {
        try{
            const rules_cn = this.state.rules.length;
            var rules = [];
            for (var i = 0; i < rules_cn; i++) {
                var day = document.getElementById('days_' + i).value;
                var rule = document.getElementById('rules_' + i).value;
                var param = {day: day, rule: rule};
                rules.push(param);
            }
            var newvalues = {};

            newvalues.rules = JSON.stringify(rules);
            newvalues.name = values.name;
            newvalues.desc = values.desc;
            newvalues.days = values.days;
            var url = PAYMENT_TERM_TYPE_URL;
            open_loading();
            const resp = await private_post(url, newvalues);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/admin/term-type");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">New Payment Term</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">Name </Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter the name of payment term type"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="desc">Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter the description of payment term type"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="days">Days</Label>
                                                <AvField
                                                name="days"
                                                type="number"
                                                errorMessage="Enter the days"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} className="d-flex justify-content-between">
                                                <Label className="form-label">Rules</Label>
                                                <div>
                                                    <a onClick={this.add_rule_row.bind(this)} className="hand me-1"><i className=" ri-add-circle-line font-size-16"></i></a>
                                                    <a onClick={this.remove_rule_row.bind(this)} className="hand"><i className=" ri-indeterminate-circle-line font-size-16"></i></a>
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.state.rules.map(o =>
                                            <Row key={o.id}>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                    <AvField
                                                    name={o.days}
                                                    id={o.days}
                                                    placeholder="Days"
                                                    type="number"
                                                    errorMessage="Enter the days"
                                                    className="form-control"
                                                    />
                                                </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                    <select className="form-control" id={o.rules} name={o.rules}>
                                                        <option value="email">Send an email</option>
                                                        <option value="suspend">Suspend an account</option>
                                                    </select>
                                                </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADCreatePaymentTerm