import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import EmailVerification from "../pages/Authentication/EmailVerification";
import Recover from "../pages/Authentication/RecoverPwd";

// Dashboard
import Home from "../pages/Landing/Home";
import Dashboard from "../pages/Dashboard/index";
import Profile from "../pages/Profile/index";
import Survey from "../pages/Profile/survey";
import Emailverify from "../pages/Profile/emailverify";
import Additionalinfo from "../pages/Profile/additionalinfo";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

import Product from "../pages/Landing/Product";
import Document from "../pages/Landing/document";
import d_1003 from "../pages/Landing/1003";

import Project from "../pages/Project/index";
import CreateProject from "../pages/Project/create";
import ProjectDetailView from "../pages/Project/detail";
import ProjectUpdateView from "../pages/Project/update";
import Billing from "../pages/Billing/index";
import ExtIntegration from "../pages/ExternalIntegtation/index";
import HumanInLoop from "../pages/HumanInLoop/index";
import Model from "../pages/Model/index";
import ModelDetailView from "../pages/Model/detail";
import CreateModel from "../pages/Model/create";
import EditModel from "../pages/Model/edit";
import ADLogin from "../pages/Authentication/admin/Login";
import ADProject from "../pages/Project/admin/index";
import ADProjectDetailView from "../pages/Project/admin/detail";
import ADUser from "../pages/User/admin/index";
import ADBilling from "../pages/Billing/admin/index";
import ADApi from "../pages/API/admin/index";
import ADCreateProject from "../pages/Project/admin/create";
import ADCreateUser from "../pages/User/admin/create";
import ADCreateAPI from "../pages/API/admin/create";
import ADUpdateAPI from "../pages/API/admin/edit";
import ADCreatePolicy from "../pages/Billing/admin/policy_create";
import ADOperator from "../pages/Operator/admin/index";
import ADCreateOperator from "../pages/Operator/admin/create";
import ADOperatorProfile from "../pages/Operator/admin/profile";
import ADUserProfile from "../pages/User/admin/profile";
import ADProfile from "../pages/Profile/admin/profile";
import ADPaymentTerm from "../pages/Billing/admin/payment_term";
import ADCreatePaymentTerm from "../pages/Billing/admin/payment_term_create";
import ADUpdatePaymentTerm from "../pages/Billing/admin/payment_term_update";
import ADMembership from "../pages/Billing/admin/membership";
import ADCreateMembership from "../pages/Billing/admin/membership_create";
import ADUpdateMembership from "../pages/Billing/admin/membership_update";
import Join from "../pages/Project/join";
import ADTestimonial from "../pages/Testimonial";
import ADCreateTestimonial from "../pages/Testimonial/create";
import ADUpdateTestimonial from "../pages/Testimonial/update";
import ADPaymentGateway from "../pages/Billing/admin/payment-gateway";

const authProtectedRoutes = [
	{ path: "/ext-integration", component: ExtIntegration },
	{ path: "/humaninloop", component: HumanInLoop },
	{ path: "/model", component: Model },
	{ path: "/model-detail/:id", component: ModelDetailView },
	{ path: "/model-edit/:id", component: EditModel },
	{ path: "/model-create", component: CreateModel },
	{ path: "/dashboard", component: Dashboard },
	{ path: "/welcome", component: Additionalinfo },
	{ path: "/logout", component: Logout },
	// this route should be at the end of all other routes
];

const accountRoutes = [
	{ path: "/profile", component: Profile },
	{ path: "/billing", component: Billing },
	{ path: "/project", component: Project },
	{ path: "/project-create", component: CreateProject },
	{ path: "/project-detail/:id", component: ProjectDetailView },
	{ path: "/project-update/:id", component: ProjectUpdateView}
];

const preProcessingRoutes = [
	{ path: "/survey", component: Survey },
];

const emailVerificatioinRoutes = [
	{ path: "/email-verify", component: Emailverify },
];

const adminPublicRoutes = [
	{ path: "/admin/login",  component: ADLogin },
];
const adminProtectedRoutes = [
	{ path: "/admin/project", component: ADProject },
	{ path: "/admin/project-create", component: ADCreateProject },
	{ path: "/admin/user", component: ADUser },
	{ path: "/admin/user-create", component: ADCreateUser },
	{ path: "/admin/billing", component: ADBilling },
	{ path: "/admin/billing-policy-create", component: ADCreatePolicy },
	{ path: "/admin/api", component: ADApi },
	{ path: "/admin/api-create", component: ADCreateAPI },
	{ path: "/admin/api-update/:id", component: ADUpdateAPI },
	{ path: "/admin/project-detail/:id", component: ADProjectDetailView },
	{ path: "/admin/operator", component: ADOperator },
	{ path: "/admin/operator-create", component: ADCreateOperator },
	{ path: "/admin/operator-profile/:id", component: ADOperatorProfile },
	{ path: "/admin/user-profile/:id", component: ADUserProfile },
	{ path: "/admin/profile", component: ADProfile },
	{ path: "/admin/term-type", component: ADPaymentTerm },
	{ path: "/admin/term-type-create", component: ADCreatePaymentTerm },
	{ path: "/admin/term-type-edit/:id", component: ADUpdatePaymentTerm },
	{ path: "/admin/membership-type", component: ADMembership },
	{ path: "/admin/membership-type-create", component: ADCreateMembership },
	{ path: "/admin/membership-type-edit/:id", component: ADUpdateMembership },
	{ path: "/admin/testimonial", component: ADTestimonial },
	{ path: "/admin/testimonial-create", component: ADCreateTestimonial },
	{ path: "/admin/testimonial-edit/:id", component: ADUpdateTestimonial },
	{ path: "/admin/payment-gateway", component: ADPaymentGateway },

];

const landingRoutes = [
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const nfRoutes = [
	{ component: Error404},
];

const publicRoutes = [
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/recover-password/:token", component: Recover },
	{ path: "/email-verification/:token", component: EmailVerification },
	{ path: "/join-project/:token", component: Join },
	{ path: "/register", component: Register },
	{ path: "/500", component: Error500 },
	{ path: "/404", component: Error404 },


];

export { nfRoutes, landingRoutes, authProtectedRoutes, publicRoutes, accountRoutes, adminProtectedRoutes, adminPublicRoutes, preProcessingRoutes, emailVerificatioinRoutes };
