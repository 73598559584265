import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody,  Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {
    private_del,
    private_post,
    private_get,
    private_put,
    alert3,
    open_loading,
    close_loading,
    back_to_dashboard,
    Route2
} from '../../helpers/fackBackend_Helper';
import {MY_PROJECT_URL} from "../../helpers/url_helper";


class ProjectUpdateView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Project", link: "/"},
                {title: "Edit", link: "#"},
            ],
            name: "",
            desc: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel() {
        this.props.history.push('/project');
    }
    async componentDidMount() {
        const project_id = this.props.match.params.id;
        const url = MY_PROJECT_URL + project_id + "/";
        var resp = await private_get(url);
        if (resp) {
            this.setState({
                name: resp.name,
                id: resp.id,
                desc: resp.desc,
            });
        }
    }

    async handleSubmit(event, values) {
        try{
            open_loading();
            const url = MY_PROJECT_URL + this.props.match.params.id + "/";
            const resp = await private_put(url, values);
            if (resp) {
                alert3("Success", "success");
                this.props.history.push("/project");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row className="mb-5">
                        <Col>
                            <Button color="primary" type="button" onClick={() => back_to_dashboard(this.props.history)}>Back to dashboard</Button>
                        </Col>
                    </Row>
                  <Row>
                        <Col xl={7}>
                            <h4 className="card-title-x">Edit your Project</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">Project Name</Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter Project Name"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="name"
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Project Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter Project Description"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="desc"
                                                value={this.state.desc}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="button-items">
                                                <Col>
                                                    <Button color="primary" type="submit">Save changes</Button>
                                                    <Button type="button" onClick={this.handleCancel} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </Col>
                                            </div>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ProjectUpdateView