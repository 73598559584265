import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Label, Input, Container, Modal,ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {
    back_to_dashboard,
    private_get,
    private_post,
    private_put,
    alert3,
    open_loading,
    close_loading
} from '../../../helpers/fackBackend_Helper';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {MODEL_URL, PROJECT_URL} from "../../../helpers/url_helper";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

class ADProjectDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Project", link: "/"},
                {title: "Detail", link: "#"},
            ],
            name: "",
            project_id: "",
            project_desc: "",
            project_uuid: "",
            project_secret: "",
            is_active: false,
            keyclock_pwd_modal: false,
            password: "",
            access_token_expiry_unit: "minute",
            access_token_expiry_count: "",
            refresh_token_expiry_unit: "minute",
            refresh_token_expiry_count: "",
            access_token: "",
            refresh_token: "",
            modelData: []

        };
        this.default_access_token_expiry_unit = "minute";
        this.default_access_token_expiry_count = "";
        this.default_refresh_token_expiry_unit = "day";
        this.default_refresh_token_expiry_count = "";
        this.width_portion = {'title': '4', 'content': '8'};
        this.date_units = [{value: "day", name: "Days"}, {value: "hour", name: "Hours"}, {value: "minute", name: "Minutes"}];
        this.handleswitchingstatus = this.handleswitchingstatus.bind(this);
        this.handleRegenerateSecret = this.handleRegenerateSecret.bind(this);
        this.handleAccesstokenExpiry = this.handleAccesstokenExpiry.bind(this);
        this.handleRefreshtokenExpiry = this.handleRefreshtokenExpiry.bind(this);
        this.handleGenerateOuthToken = this.handleGenerateOuthToken.bind(this);
        this.tog_keyclock_pwd_modal = this.tog_keyclock_pwd_modal.bind(this);
        this.change_access_token_expiry_unit = this.change_access_token_expiry_unit.bind(this);
        this.change_refresh_token_expiry_unit = this.change_refresh_token_expiry_unit.bind(this);
        this.enableAccesstokenExpiry = this.enableAccesstokenExpiry.bind(this);
        this.cancelAccesstokenExpiry = this.cancelAccesstokenExpiry.bind(this);
        this.change_access_token_expiry_count = this.change_access_token_expiry_count.bind(this);
        this.change_refresh_token_expiry_count = this.change_refresh_token_expiry_count.bind(this);
        this.enableRefreshtokenExpiry = this.enableRefreshtokenExpiry.bind(this);
        this.cancelRefreshtokenExpiry = this.cancelRefreshtokenExpiry.bind(this);
    }

    cancelRefreshtokenExpiry() {
        document.getElementById("cancelRefreshtokenExpirybtn").style.display = 'none';
        document.getElementById("enableRefreshtokenExpirybtn").style.display = 'inline-block';
        document.getElementById("handleRefreshtokenExpirybtn").style.display = 'none';
        document.getElementById('refresh_token_expiry_unit').setAttribute("disabled","disabled");
        document.getElementById('refresh_token_expiry_count').setAttribute("disabled","disabled");
        this.setState({refresh_token_expiry_count: this.default_refresh_token_expiry_count});
        this.setState({refresh_token_expiry_unit: this.default_refresh_token_expiry_unit});
    }
    enableRefreshtokenExpiry() {
        document.getElementById("cancelRefreshtokenExpirybtn").style.display = 'inline-block';
        document.getElementById("enableRefreshtokenExpirybtn").style.display = 'none';
        document.getElementById("handleRefreshtokenExpirybtn").style.display = 'inline-block';
        document.getElementById('refresh_token_expiry_unit').removeAttribute("disabled");
        document.getElementById('refresh_token_expiry_count').removeAttribute("disabled");
    }
    cancelAccesstokenExpiry(){
        document.getElementById("cancelAccesstokenExpirybtn").style.display = 'none';
        document.getElementById("enableAccesstokenExpirybtn").style.display = 'inline-block';
        document.getElementById("handleAccesstokenExpirybtn").style.display = 'none';
        document.getElementById('access_token_expiry_unit').setAttribute("disabled","disabled");
        document.getElementById('access_token_expiry_count').setAttribute("disabled","disabled");
        this.setState({access_token_expiry_count: this.default_access_token_expiry_count});
        this.setState({access_token_expiry_unit: this.default_access_token_expiry_unit});
    }

    enableAccesstokenExpiry() {
        document.getElementById("cancelAccesstokenExpirybtn").style.display = 'inline-block';
        document.getElementById("enableAccesstokenExpirybtn").style.display = 'none';
        document.getElementById("handleAccesstokenExpirybtn").style.display = 'inline-block';
        document.getElementById('access_token_expiry_unit').removeAttribute("disabled");
        document.getElementById('access_token_expiry_count').removeAttribute("disabled");
    }

    change_refresh_token_expiry_unit(e) {
        this.setState({refresh_token_expiry_unit: e.target.value});
    }
    change_access_token_expiry_unit(e) {
        this.setState({access_token_expiry_unit: e.target.value});
    }
    change_access_token_expiry_count(e) {
        this.setState({access_token_expiry_count: e.target.value});
    }
    change_refresh_token_expiry_count(e) {
        this.setState({refresh_token_expiry_count: e.target.value});
    }
    tog_keyclock_pwd_modal() {
        this.setState(prevState => ({
          keyclock_pwd_modal: !prevState.keyclock_pwd_modal
        }));
        this.removeBodyCss();
    }
    removeBodyCss(){
        document.body.classList.add("no_padding");
    }
    async handleGenerateOuthToken(event, values){
        const url = PROJECT_URL + this.props.match.params.id + "/tokens/";
        const data = {
            password: values.password
        };
        try{
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                alert3("Success", 'success');
                this.setState({
                    access_token: resp.access_token,
                    refresh_token: resp.refresh_token
                })
            }
        } catch (err) {
            alert3(err, 'error');
        }
        this.setState({ keyclock_pwd_modal: false });
        close_loading();
    }
    dateunittosecond(unit) {
        if (unit === 'day') {
            return 86400;
        } else if (unit === 'hour') {
            return 3600;
        } else if (unit === 'minute') {
            return 60;
        } else {
            return 0;
        }
    }
    async handleRefreshtokenExpiry() {
        const url = PROJECT_URL + this.props.match.params.id + "/refresh_token_lifespan/";
        if (this.state.refresh_token_expiry_count === '') {
            alert3('Expiry time interval can not be blank', 'warning');
            return;
        }
        const refresh_token_expiry_count = parseInt(this.state.refresh_token_expiry_count);
        if (refresh_token_expiry_count === 0) {
            alert3('Expiry time interval can not be zero', 'warning');
            return;
        }
        const lifespan = this.dateunittosecond(this.state.refresh_token_expiry_unit) * refresh_token_expiry_count;
        const data = {
            lifespan: lifespan.toString()
        };
        try{
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                alert3("Success", 'success');
                this.default_refresh_token_expiry_count = this.state.refresh_token_expiry_count;
                this.default_refresh_token_expiry_unit = this.state.refresh_token_expiry_unit;
                this.cancelRefreshtokenExpiry();
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async handleAccesstokenExpiry() {
        const url = PROJECT_URL + this.props.match.params.id + "/access_token_lifespan/";
        if (this.state.access_token_expiry_count === '') {
            alert3('Expiry time interval can not be blank', 'warning');
            return;
        }
        const access_token_expiry_count = parseInt(this.state.access_token_expiry_count);
        if (access_token_expiry_count === 0) {
            alert3('Expiry time interval can not be zero', 'warning');
            return;
        }
        const lifespan = this.dateunittosecond(this.state.access_token_expiry_unit) * access_token_expiry_count;
        const data = {
            lifespan: lifespan.toString()
        };
        try{
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                alert3("Success", 'success');
                this.default_access_token_expiry_count = this.state.access_token_expiry_count;
                this.default_access_token_expiry_unit = this.state.access_token_expiry_unit;
                this.cancelAccesstokenExpiry();
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }

    async handleswitchingstatus() {
        const url = PROJECT_URL + this.props.match.params.id + "/";
        const data = {
            is_active: !this.state.is_active
        };
        try{
            open_loading();
            const resp = await private_put(url, data);
            if (resp) {
                this.setState({
                    is_active: !this.state.is_active
                });
                alert3("Success", 'success');
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }

    async handleRegenerateSecret() {
        const url = PROJECT_URL + this.props.match.params.id + "/secret/";
        try{
            open_loading();
            const resp = await private_post(url);
            if (resp) {
                this.setState({project_secret: resp.secret});
                alert3("Success", 'success');
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async loadTable() {
        const project_id = this.props.match.params.id;
        const url = MODEL_URL + "?project=" + project_id + "&all=true";
        const resp = await private_get(url);
        const data = [];
        for (var i=0; i < resp.length; i++) {
          var param = {};
          param.id = resp[i].id;
          param.name = resp[i].name;
          param.url = resp[i].url;
          data.push(param);
        }
        this.setState({modelData: data});
    }
    async componentDidMount() {
        try {
            open_loading();
            this.cancelAccesstokenExpiry();
            this.cancelRefreshtokenExpiry();
            const project_id = this.props.match.params.id;
            const url = PROJECT_URL + project_id + "/";
            var resp = await private_get(url);
            if (resp) {
                this.setState({
                    name: resp.name,
                    project_id: resp.id,
                    project_desc: resp.desc,
                    project_uuid: resp.id,
                    project_secret: resp.secret,
                    is_active: resp.is_active,
                    project_owner: resp.owner
                });
            }
            const access_token_lifespan_url = PROJECT_URL + project_id + "/access_token_lifespan/";
            resp = await private_get(access_token_lifespan_url);
            if (resp) {
                if (resp.lifespan !== 0) {
                    var access_token_lifespan = this.lifespan_to_unit(resp.lifespan);
                    this.setState({
                        access_token_expiry_unit: access_token_lifespan.unit,
                        access_token_expiry_count: access_token_lifespan.count
                    });
                }
            }
            const refresh_token_lifespan_url = PROJECT_URL + project_id + "/refresh_token_lifespan/";
            resp = await private_get(refresh_token_lifespan_url);
            if (resp) {
                if (resp.lifespan !== 0) {
                    var refresh_token_lifespan = this.lifespan_to_unit(resp.lifespan);
                    this.setState({
                        refresh_token_expiry_unit: refresh_token_lifespan.unit,
                        refresh_token_expiry_count: refresh_token_lifespan.count
                    });
                }
            }
            this.default_access_token_expiry_unit = this.state.access_token_expiry_unit;
            this.default_access_token_expiry_count = this.state.access_token_expiry_count;
            this.default_refresh_token_expiry_unit = this.state.refresh_token_expiry_unit;
            this.default_refresh_token_expiry_count = this.state.refresh_token_expiry_count;
            this.loadTable();
        }
        catch(err){

        }
        close_loading();
    }

    lifespan_to_unit(seconds) {
        var res = seconds / 86400;
        if (res >= 1) {
            return {"unit": "day", "count": res};
        }
        res = seconds / 3600;
        if (res >= 1) {
            return {"unit": "hour", "count": res};
        }
        res = seconds / 60;
        return {"unit": "minute", "count": res};
    }
    render() {
        const columns = [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        },
        {
          dataField: 'name',
          text: 'Name',
          sort: true,
          style: {width: "20%"},
          headerStyle: {width: "20%"},
        }, {
          dataField: 'url',
          text: 'URL',
          sort: true,
          style: {width: "80%"},
          headerStyle: {width: "80%"},
        }
        ];
        const defaultSorted = [{
          dataField: 'name',
          order: 'asc'
        }];
        const customTotal = (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Showing { from } to { to } of { size } Results
          </span>
        );
        const { SearchBar } = Search;
        const pageOptions = {
            alwaysShowAllBtns: true,
            prePageText: '<',
            nextPageText: '>',
            withFirstAndLast : true,
            firstPageText: '<<',
            lastPageText: '>>',
            showTotal: true,
            disablePageTitle: true,
            sizePerPage: 20,
            paginationTotalRenderer: customTotal,
            totalSize: this.state.modelData.length,
            sizePerPageList: [{
                text: '5', value: 5
              }, {
                text: '10', value: 10
              }, {
                text: '20', value: 20
              }, {
                text: 'All', value: this.state.modelData.length
              }]
        };
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}

                  <Row>
                        <Col md={6}>
                            <h4 className="card-title-x">Project Information</h4>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Project Name:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.name}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Project Description:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.project_desc}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Status:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                    <Input onChange={this.handleswitchingstatus} type="checkbox" className="form-check-input" id="project_isactive" checked={this.state.is_active} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Owner:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.project_owner}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h4 className="card-title-x">Oauth Tokens</h4>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-center mb-3">
                                        <Col xs={this.width_portion.title}>
                                            <div className="text-right">
                                                <span>Access Token:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div>
                                                <AvForm className="needs-validation d-inline-block">

                                                <div className="d-inline-block">
                                                    <AvField
                                                    name="access_token_expiry_count"
                                                    type="number"
                                                    className="form-control expiry_item me-1"
                                                    id="access_token_expiry_count"
                                                    onChange={this.change_access_token_expiry_count}
                                                    value={this.state.access_token_expiry_count}
                                                />
                                                </div>
                                                    <select className="form-control expiry_item me-1" name='access_token_expiry_unit' id='access_token_expiry_unit' value={this.state.access_token_expiry_unit} onChange={this.change_access_token_expiry_unit}>
                                                  {this.date_units.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                                </select>
                                                </AvForm>
                                                <Button id="enableAccesstokenExpirybtn" onClick={this.enableAccesstokenExpiry} color="primary" type="button">Edit</Button>
                                                <Button className="me-1" id="handleAccesstokenExpirybtn" onClick={this.handleAccesstokenExpiry} color="primary" type="button">Set expiry</Button>
                                                <Button id="cancelAccesstokenExpirybtn" onClick={this.cancelAccesstokenExpiry} color="primary" type="button">Cancel</Button>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-center mb-3">
                                        <Col xs={this.width_portion.title}>
                                            <div className="text-right">
                                                <span>Refresh Token:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div>
                                                <AvForm className="needs-validation d-inline-block">

                                                <div className="d-inline-block">
                                                    <AvField
                                                    name="refresh_token_expiry_count"
                                                    type="number"
                                                    onChange={this.change_refresh_token_expiry_count}
                                                    className="form-control expiry_item me-1"
                                                    id="refresh_token_expiry_count"
                                                    value={this.state.refresh_token_expiry_count}
                                                />
                                                </div>
                                                    <select className="form-control expiry_item me-1" name='refresh_token_expiry_unit' id='refresh_token_expiry_unit' value={this.state.refresh_token_expiry_unit} onChange={this.change_refresh_token_expiry_unit}>
                                                  {this.date_units.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                                </select>
                                                </AvForm>
                                                <Button id="enableRefreshtokenExpirybtn" onClick={this.enableRefreshtokenExpiry} color="primary" type="button">Edit</Button>
                                                <Button id="handleRefreshtokenExpirybtn" className="me-1" onClick={this.handleRefreshtokenExpiry} color="primary" type="button">Set Expiry</Button>
                                                <Button id="cancelRefreshtokenExpirybtn" onClick={this.cancelRefreshtokenExpiry} color="primary" type="button">Cancel</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <Button onClick={this.tog_keyclock_pwd_modal} color="primary" type="button">Regenerate</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Access Token:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.access_token}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Refresh Token:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.refresh_token}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h4 className="card-title-x">Credentials</h4>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Project ID:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.project_uuid}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 text-right">
                                                <span>Project Secret:</span>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>{this.state.project_secret}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}></Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <Button onClick={this.handleRegenerateSecret} color="primary" type="button">Regenerate</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h4 className="card-title-x">Model List</h4>
                            <div className="custom-table">
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField='id'
                                  columns={columns}
                                  data={this.state.modelData}
                                >
                                  {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField='id'
                                      columns={columns}
                                      data={this.state.modelData}
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>

                                          <Row>
                                            <Col>
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="ri-search-line appsearch" />
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col>
                                              <div className="custom-table">
                                                <BootstrapTable
                                                  noDataIndication="Table is Empty"
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  classes={
                                                    "table align-middle table-nowrap"
                                                  }
                                                  headerWrapperClasses={"thead-light"}
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />

                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )
                                      }
                                    </ToolkitProvider>
                                  )
                                  }</PaginationProvider>
                              </div>
                        </Col>
                    </Row>
                    <Row><Col sm={6}>
                    <Modal
                          size="sm"
                          isOpen={this.state.keyclock_pwd_modal}
                          toggle={this.tog_keyclock_pwd_modal}
                          centered={true}
                        >
                          <ModalHeader toggle={() => this.setState({ keyclock_pwd_modal: false })}>
                              Input your password
                          </ModalHeader>
                        <AvForm className="needs-validation" onValidSubmit={this.handleGenerateOuthToken}>
                        <ModalBody>

                              <div className="mb-3">
                                <Label className="form-label" htmlFor="password">Password</Label>
                                <AvField
                                name="password"
                                type="password"
                                errorMessage="Enter password"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="password"
                                value={this.state.password}
                                />
                            </div>

                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="primary" className="waves-effect waves-light">Submit</Button>
                          </ModalFooter>
                            </AvForm>
                        </Modal>
                    </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADProjectDetailView