import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody,  Button, Label, Container,Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {private_del, private_post, private_get, private_put, alert3, open_loading, close_loading} from '../../helpers/fackBackend_Helper';
import {MY_MODEL_URL, PROJECT_URL} from "../../helpers/url_helper";
import {APIS_URL} from "../../helpers/url_helper";

class EditModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Model", link: "/"},
                {title: "Edit", link: "#"},
            ],
            name: "",
            desc: "",
            is_active: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel() {
        this.props.history.push("/model");
    }
    async componentDidMount() {
        const model_id = this.props.match.params.id;
        const url = MY_MODEL_URL + model_id + "/";
        var resp = await private_get(url);
        if (resp) {
            this.setState({
                name: resp.name,
                id: resp.id,
                desc: resp.desc,
                is_active: resp.is_active,
            });
        }
    }
    async handleswitchingstatus() {
        const url = MY_MODEL_URL + this.props.match.params.id + "/";
        const data = {
            is_active: !this.state.is_active
        };
        try{
            open_loading();
            const resp = await private_put(url, data);
            if (resp) {
                this.setState({
                    is_active: !this.state.is_active
                });
                alert3("Success", 'success');
                this.props.history.push("/model");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async handleSubmit(event, values) {
        try{
            open_loading();
            const url = MY_MODEL_URL + this.props.match.params.id + "/";
            const resp = await private_put(url, values);
            if (resp) {
                alert3("Success", "success");
                this.props.history.push("/model");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                    <Row>
                        <Col md={6}>
                            <h4 className="card-title-x">Edit your Model</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">Model Name</Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter Model Name"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="name"
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Model Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter Model Description"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="desc"
                                                value={this.state.desc}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Status</Label>
                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                    <Input onChange={this.handleswitchingstatus.bind(this)} type="checkbox" className="form-check-input cswitch" checked={this.state.is_active} />
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="button-items">
                                            <Button color="primary" type="submit">Save changes</Button>
                                                <Button type="button" onClick={this.handleCancel} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                            </div>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EditModel