import React, { Component } from 'react';

import { Row, Col, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {public_get, public_post, timeout} from "../../../helpers/fackBackend_Helper";
import {API_URL, axiosApi} from '../../../helpers/api_helper';

class ADLogin extends Component {

    constructor(props) {
        super(props);
        this.state = { email: "", password: "", loginError: null };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event, values) {
        try {
            const url = API_URL + "/auth/login/";
            await public_get(url);
            values.is_operator = true;
            const response = await public_post(url, values);
            axiosApi.defaults.headers['Authorization'] = "JWT " + response.access;
            localStorage.setItem("access_token", response.access);
            localStorage.setItem("refresh_token", response.refresh);
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("is_operator", 'true');
            localStorage.removeItem("current_project");
            await timeout(2000);
            this.props.history.push('/admin/project');
        } catch (err){
            this.setState({loginError: err});
        }

    }

    componentDidMount() {
        document.body.classList.add("auth-body-bg");
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    render() {

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={4}>
                                <div className="profile-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <h4 className="font-size-28 mt-4 font-w-600">Log in</h4>
                                                        <p className="text-muted mt-4">Sign in to your account</p>
                                                    </div>

                                                    {this.state.loginError ? <Alert color="danger">{this.state.loginError}</Alert> : null}

                                                    <div className="p-2 mt-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="email">Email</Label>
                                                                <AvField name="email" value={this.state.email} type="text" className="form-control" id="email" validate={{ email: true, required: true }} placeholder="Enter email" />
                                                            </div>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password</Label>
                                                                <AvField name="password" value={this.state.password} type="password" className="form-control" id="password" validate={{ required: true }} placeholder="Enter password" />
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">Log In</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}


export default (withRouter(ADLogin));
