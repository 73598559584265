import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Container,
    Input,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Modal,
    Card,
    CardBody
} from "reactstrap";
import {
    private_get,
    private_del,
    private_put,
    back_to_dashboard,
    alert3,
    open_loading, close_loading
} from '../../../helpers/fackBackend_Helper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../Tables/datatables.scss";
import {POLICY_URL} from "../../../helpers/url_helper";

class ADBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 10,
      policyData: [],
      row_id: null

    };
    this.linkFollow = this.linkFollow.bind(this);
  }

  handlecreatepolicy() {
      this.props.history.push("/admin/billing-policy-create");
  }
  async savepolicy(cell, row, row_index) {
      const url = POLICY_URL + row.id + "/";
      var unit = parseInt(document.getElementById('unit_cell_' + row.id).value);

      var price = document.getElementById('price_cell_' + row.id).value;
      if (isNaN(parseFloat(price))) {
          alert3('The Price entered is not valid', 'warning');return;
      }

      var data = {};
      data.unit = unit;
      data.price = price;
      try{
          open_loading();
          const resp = await private_put(url, data);
          if (resp) {
              alert3("Success", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }

  async loadTable() {
    const url = POLICY_URL;
    const resp = await private_get(url);
    const data = [];
    for (var i=0; i < resp.length; i++) {
      var param = {};
      param.id = resp[i].id;
      param.unit = resp[i].unit;
      param.price = resp[i].price;
      param.service_type = resp[i].service_type;
      param.service_type_name = resp[i].service_type_name;
      data.push(param);
    }
    this.setState({policyData: data});
  }

  componentDidMount() {
    this.loadTable();
  }
  linkFollow (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <Button onClick={this.savepolicy.bind(this, cell, row, rowIndex)} className="me-1 actionbtn" color="primary">SAVE</Button>
      </div>
    )
  }
  unit_formatter(cell, row, rowIndex, formatExtraData) {
      const unit_cell_id = "unit_cell_" + row.id;
     return (
      <Input
        name="unit"
        type="number"
        className="form-control me-1"
        id={unit_cell_id}
        defaultValue={row.unit}
    />
    )
  }
  price_formatter(cell, row, rowIndex, formatExtraData) {
      const price_cell_id = "price_cell_" + row.id;
      return (
          <div>
              <Input
                name="price"
                type="text"
                className="form-control me-1"
                defaultValue={row.price}
                id={price_cell_id}
            />
          </div>
    )
  }
  render() {

    const columns = [
    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'service_type_name',
      text: 'Api Name',
      sort: true
    }, {
      dataField: 'unit',
      text: 'Unit',
      formatter: this.unit_formatter,
      style: {width: "15%"},
      headerStyle: {width: "15%"},
    }, {
      dataField: 'price',
      text: 'Price',
      formatter: this.price_formatter,
      style: {width: "15%"},
      headerStyle: {width: "15%"},

    }, {
      dataField: 'action',
      text: 'Action',
      formatter: this.linkFollow,
      style: {width: "15%"},
      headerStyle: {width: "15%"},

    },

    ];

    const defaultSorted = [{
      dataField: 'name',
      order: 'asc'
    }];
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
      </span>
    );
    const pageOptions = {
        alwaysShowAllBtns: true,
        prePageText: '<',
        nextPageText: '>',
        withFirstAndLast : true,
        firstPageText: '<<',
        lastPageText: '>>',
        showTotal: true,
        disablePageTitle: true,
        sizePerPage: 20,
        paginationTotalRenderer: customTotal,
        totalSize: this.state.policyData.length,
        sizePerPageList: [{
            text: '5', value: 5
          }, {
            text: '10', value: 10
          }, {
            text: '20', value: 20
          }, {
            text: 'All', value: this.state.policyData.length
          }]
    };
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

              <Row>
                <Col lg={12}>
                      <Card>
                        <CardBody>
                            <Row>
                              <Col lg={12}>
                                  <div className="custom-table">
                                      <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField='id'
                                  columns={columns}
                                  data={this.state.policyData}
                                >
                                  {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField='id'
                                      columns={columns}
                                      data={this.state.policyData}
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>

                                          <Row>
                                            <Col>
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="ri-search-line appsearch" />
                                                </div>
                                              </div>
                                                <Button onClick={this.handlecreatepolicy.bind(this)} className="float-right" color="primary" type="submit">New Policy</Button>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col>
                                              <div className="custom-table">
                                                <BootstrapTable
                                                  noDataIndication="Table is Empty"
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  classes={
                                                    "table align-middle table-nowrap"
                                                  }
                                                  headerWrapperClasses={"thead-light"}
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />

                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )
                                      }
                                    </ToolkitProvider>
                                  )
                                  }</PaginationProvider>
                                  </div>
                              </Col>
                            </Row>
                        </CardBody></Card>
                </Col>
              </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ADBilling