import React, { Component } from "react";
import {Row, Col, Input, Card, CardBody, Button, Label, Container, InputGroup} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    private_post,
    private_put,
    alert3,
    open_loading,
    close_loading, private_get, getformatdate, futuredate, private_patch
} from '../../../helpers/fackBackend_Helper';
import {
    MEMBERSHIP_TYPE_URL,
    OPERATOR_URL,
    PAYMENT_TERM_TYPE_URL,
    PAYMENT_TERM_URL,
    USER_URL,
    MEMBERSHIP_URL
} from "../../../helpers/url_helper";

class ADUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_terms: [],
            payment_term: "",
            memberships: [],
            membership: "",
            no_time_limit: false
        };
    }

    async handleChangeTlimit(e) {
        this.setState({
            no_time_limit: !this.state.no_time_limit
        });
        if (this.state.no_time_limit) {
            const membership_type_id = document.getElementById('membership_type').value;
            const url = MEMBERSHIP_TYPE_URL + membership_type_id + "/";
            const resp = await private_get(url);
            const today = new Date();
            if (resp) {
                var quotas = resp.quotas;
                this.setState({
                    days: resp.days,
                    discount: resp.discount,
                    doc_size: quotas.doc_size,
                    pages: quotas.pages,
                    models: quotas.models,
                    projects: quotas.projects,
                    start_date: getformatdate(today),
                    end_date: getformatdate(futuredate(today, parseInt(resp.days)))
                });
            }
        }
    }

    handleChangepm(e){
        this.setState({ payment_term: e.target.value });
    }
    async handleChangemb(e){
        this.setState({ membership: e.target.value });
        if (e.target.value !== ""){
            const url = MEMBERSHIP_TYPE_URL + e.target.value + "/";
            const resp = await private_get(url);
            const today = new Date();
            if (resp) {
                var quotas = resp.quotas;
                this.setState({
                    days: resp.days,
                    discount: resp.discount,
                    doc_size: quotas.doc_size,
                    pages: quotas.pages,
                    models: quotas.models,
                    projects: quotas.projects,
                    start_date: getformatdate(today),
                    end_date: getformatdate(futuredate(today, parseInt(resp.days)))
                });
            }
        } else{
            this.setState({
                days: "",
                discount: "",
                doc_size: "",
                pages: "",
                models: "",
                projects: "",
                start_date: "",
                end_date: ""
            });
        }

    }
    async handleassignpaymentterm(e, values) {
        try{

            var payment_term_values = {};
            payment_term_values.user = this.props.match.params.id;
            payment_term_values.type = this.state.payment_term;
            if (this.state.payment_term === "") {
                alert3("Please select payment term.", 'warning');
                close_loading();
                return;
            }

            open_loading();
            const url = PAYMENT_TERM_URL + "?user=" + this.props.match.params.id;
            const resp = await private_get(url);
            if (resp.length === 0) {
                const po_resp = await private_post(PAYMENT_TERM_URL, payment_term_values);
                if (po_resp) {
                    alert3("Success", 'success');
                }
            } else{
                const put_url = PAYMENT_TERM_URL + resp[0].id;
                const pu_resp = await private_put(put_url, payment_term_values);
                if (pu_resp) {
                    alert3("Success", 'success');
                }
            }
        } catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async handleassignmembership(e, values){
        try{
            open_loading();
            if (this.state.membership === "") {
                alert3("Please select membership.", 'warning');
                close_loading();
                return;
            }

            values.user = this.props.match.params.id;
            values.type = this.state.membership;

            if (!this.state.no_time_limit){
                values.start_date = new Date(values.start_date).toISOString().slice(0, 10);
                values.end_date = new Date(values.end_date).toISOString().slice(0, 10);
            }

            var quotas = {
                doc_size: parseInt(values.q_docsize),
                pages: parseInt(values.q_pages),
                models: parseInt(values.q_model),
                projects: parseInt(values.q_project),
            };
            values.quotas = quotas;

            if (this.state.no_time_limit){
                values.no_time_limit = '';
            }

            delete values.q_docsize;
            delete values.q_pages;
            delete values.q_model;
            delete values.q_project;

            const url = MEMBERSHIP_URL + "?user=" + this.props.match.params.id;
            const resp = await private_get(url);
            if (resp.length === 0) {
                await private_post(MEMBERSHIP_URL, values);
                alert3("Success", 'success');
            } else {
                const put_url = MEMBERSHIP_URL + resp[0].id;
                await private_patch(put_url, values);
                alert3("Success", 'success');
            }


        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    handleCancel() {
        this.props.history.push('/admin/user');
    }
    async handleresetpassword(event, values) {
        if (values.password !== values.confirm_password) {
            alert3("Password doesn't match", "warning");return;
        }
        delete values.confirm_password;

        try{
            open_loading();
            const url = USER_URL + this.props.match.params.id + "/";

            const resp = await private_put(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push('/admin/user');
            }
        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async componentDidMount() {
        const resp_p = await private_get(PAYMENT_TERM_TYPE_URL);
        this.setState({
            payment_terms: resp_p
        });
        const resp_m = await private_get(MEMBERSHIP_TYPE_URL);
        this.setState({
            memberships: resp_m
        });

        const _resp_p_url = PAYMENT_TERM_URL + "?user=" + this.props.match.params.id;
        const _resp_p = await private_get(_resp_p_url);
        if (_resp_p.length > 0){
            this.setState({payment_term: _resp_p[0].type});
        }

        const _resp_m_url = MEMBERSHIP_URL + "?user=" + this.props.match.params.id + "&current=true";
        const _resp_m = await private_get(_resp_m_url);
        if (_resp_m.length > 0){
            const resp = _resp_m[0];
            const quotas = resp.quotas;
            this.setState({
                membership: resp.type,
                days: resp.days,
                discount: resp.discount,
                doc_size: quotas.doc_size,
                pages: quotas.pages,
                models: quotas.models,
                projects: quotas.projects,
                start_date: resp.start_date,
                end_date: resp.end_date
            });
            if (resp.end_date === '2100-01-01'){
                this.setState({no_time_limit: true});
            }
        }

    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row>
                        <Col xl={6}>
                            <h4 className="card-title-x">Reset password</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleresetpassword.bind(this)} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">New Password</Label>
                                                <AvField
                                                name="password"
                                                type="password"
                                                errorMessage="Enter password"
                                                className="form-control"
                                                validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                                value={this.state.password}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Confirm Password</Label>
                                                <AvField
                                                name="confirm_password"
                                                type="password"
                                                errorMessage="Enter password"
                                                className="form-control"
                                                validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                                value={this.state.confirm_password}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="button-items">
                                                        <Button color="primary" type="submit">Save</Button>
                                                        <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div></Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                            <h4 className="card-title-x">Assign Payment Term</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleassignpaymentterm.bind(this)} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label">Payment Term</Label>
                                                <select className="form-control" value={this.state.payment_term} onChange={this.handleChangepm.bind(this)}>
                                                    <option value="">Select a payment term</option>
                                                    {this.state.payment_terms.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                                                </select>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="button-items">
                                                        <Button color="primary" type="submit">Save</Button>
                                                        <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div></Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <h4 className="card-title-x">Assign Membership</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleassignmembership.bind(this)} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label">Membership</Label>
                                                <select id="membership_type" className="form-control" value={this.state.membership} onChange={this.handleChangemb.bind(this)}>
                                                    <option value="">Select a membership</option>
                                                    {this.state.memberships.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                                                </select>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label className="form-label" htmlFor="no_time_limit">Credit Based</Label>
                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                    <Input onChange={this.handleChangeTlimit.bind(this)} type="checkbox" className="form-check-input cswitch" checked={this.state.no_time_limit}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        {!this.state.no_time_limit &&
                                            <Row>
                                                <Col xl={6}>
                                                    <Label className="form-label" htmlFor="start_date">Start Date</Label>
                                                    <div className="mb-3">
                                                        <AvField
                                                        name="start_date"
                                                        type="date"
                                                        errorMessage="Enter the start date"
                                                        className="form-control"
                                                        value={this.state.start_date}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl={6}>
                                                    <Label className="form-label" htmlFor="end_date">End Date</Label>
                                                    <div className="mb-3">
                                                        <AvField
                                                        name="end_date"
                                                        type="date"
                                                        errorMessage="Enter the end date"
                                                        className="form-control"
                                                        value={this.state.end_date}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        }

                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="discount">Discount</Label>
                                                <InputGroup className="policy_price_inputgr">
                                                    <span className="input-group-append">
                                                        <span className="input-group-text">%</span>
                                                      </span>
                                                    <AvField
                                                    name="discount"
                                                    type="number"
                                                    step="0.01"
                                                    min="0"
                                                    max="1"
                                                    errorMessage="Enter the valid discount (Discount should be value between 0 and 1.)"
                                                    className="form-control"
                                                    value={this.state.discount}
                                                    />
                                                </InputGroup>

                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label className="form-label font-weight-bold">Quotas</Label>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_project">Projects</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_project"
                                                type="number"
                                                errorMessage="Set the maximum number of projects"
                                                className="form-control"
                                                value={this.state.projects}
                                                />
                                            </div>
                                            </Col>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_model">Models</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_model"
                                                type="number"
                                                errorMessage="Set the maximum number of models per project"
                                                className="form-control"
                                                value={this.state.models}
                                                />
                                            </div>
                                            </Col>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_pages">Pages</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_pages"
                                                type="number"
                                                errorMessage="Set the maximum number of pages"
                                                className="form-control"
                                                value={this.state.pages}
                                                />
                                            </div>
                                            </Col>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_docsize">File Size</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_docsize"
                                                type="number"
                                                errorMessage="Set the maximum file size of the document to be uploaded"
                                                className="form-control"
                                                value={this.state.doc_size}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="button-items">
                                                        <Button color="primary" type="submit">Save</Button>
                                                        <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div></Col>
                                        </Row>
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADUserProfile