
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';
import logo from "../../assets/images/logo-light.png";
// import images

class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {email: ''};

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.forgetUser(values, this.props.history);
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <div className="pub-header">
                            <div>
                            </div>
                        </div>
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={4} className="logincol">
                                <div className="authentication-page-content p-4 d-flex align-items-center">
                                    <div className="w-100 c-pubpage-signin">
                                        <Row className="justify-content-center">
                                            <Col>
                                                <div className="loginform">
                                                    <div className="text-center mb-5 mt-5">
                                                        <img src={logo} />
                                                    </div>
                                                    <div className="text-center pl-2 pr-2">
                                                        <h4 className="text-left font-size-28 mt-4 mb-3">Reset your password</h4>
                                                    </div>

                                                    <div className="p-2 mt-2">
                                                        {this.props.forgetError && this.props.forgetError ?
                                                            <Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
                                                        {
                                                            this.props.message ?
                                                                <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                        }
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="useremail">Email</Label>
                                                                <AvField name="email" value={this.state.email} type="email" validate={{ email: true, required: true }} className="form-control" id="email" placeholder="Enter email" />
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Submit"}</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>Already have an account ? <Link to="/login" className="fw-medium"> Sign in </Link> </p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(ForgetPasswordPage)
);
