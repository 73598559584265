import axios from 'axios';
import {del, get, post, put, patch, API_URL} from "./api_helper";
import * as url from "./url_helper";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {MY_MODEL_URL} from "./url_helper";

export const getTourStateValue = (tour_state) => {
    var TourState = {};
    if(tour_state.step1 === 1){
        TourState.tour_state_step1 = true;
    }else{
        TourState.tour_state_step1 = false;
    }
    if(tour_state.step2 === 1){
        TourState.tour_state_step2 = true;
    }else{
        TourState.tour_state_step2 = false;
    }
    if(tour_state.step3 === 1){
        TourState.tour_state_step3 = true;
    }else{
        TourState.tour_state_step3 = false;
    }
    return TourState;
}

export const gettourpercent = (values) => {
    var percent_scale = 0;
    if (values.step1 === 1) {
        percent_scale += 1;
    }
    if (values.step2 === 1) {
        percent_scale += 1;
    }
    if (values.step3 === 1) {
        percent_scale += 1;
    }
    if (percent_scale === 0) {
        return 0;
    }
    if (percent_scale === 1) {
        return 33;
    }
    if (percent_scale === 2) {
        return 67;
    }
    if (percent_scale === 3) {
        return 100;
    }
}

export const getformatdate = (fdate) =>{
    return fdate.toISOString().slice(0, 10);
}
export const futuredate = (fdate, days) => {
    fdate.setDate(fdate.getDate() + days);
    return fdate;
}
export const isTourOpened = (index) => {
    if (index === 1) {
        const cn = document.getElementsByClassName("introglobalhighlightClass").length;
        if (cn === 0) {
            return false;
        }
        return true;
    } else if (index === 2) {
        const cn = document.getElementsByClassName("paymentglobalhighlightClass").length;
        if (cn === 0) {
            return false;
        }
        return true;
    } else if (index === 3) {
        const cn = document.getElementsByClassName("projectglobalhighlightClass").length;
        if (cn === 0) {
            return false;
        }
        return true;
    } else if (index === 4) {
        const cn = document.getElementsByClassName("modelglobalhighlightClass").length;
        if (cn === 0) {
            return false;
        }
        return true;
    } else if (index === 5) {
        const cn = document.getElementsByClassName("tourtoprojectglobalhighlightClass").length;
        if (cn === 0) {
            return false;
        }
        return true;
    } else if (index === 6) {
        const cn = document.getElementsByClassName("tourtomodelglobalhighlightClass").length;
        if (cn === 0) {
            return false;
        }
        return true;
    }


}

export const getTourState = (index) => {
    if (index === 1) {
        const tourstate = document.getElementById('tourstate').value;
        if (tourstate === ""){
            return null;
        }
        return JSON.parse(tourstate);
    } else if (index === 2) {
        const tourstate = document.getElementById('paymenttourstate').value;
        if (tourstate === ""){
            return null;
        }
        return JSON.parse(tourstate);
    } else if (index === 3) {
        const tourstate = document.getElementById('projecttourstate').value;
        if (tourstate === ""){
            return null;
        }
        return JSON.parse(tourstate);
    } else if (index === 4) {
        const tourstate = document.getElementById('modeltourstate').value;
        if (tourstate === ""){
            return null;
        }
        return JSON.parse(tourstate);
    } else if (index === 5) {
        const tourstate = document.getElementById('TourToProjectTourstate').value;
        if (tourstate === ""){
            return null;
        }
        return JSON.parse(tourstate);
    } else if (index === 6) {
        const tourstate = document.getElementById('TourToModelTourstate').value;
        if (tourstate === ""){
            return null;
        }
        return JSON.parse(tourstate);
    }
};

export const putTourState = (state, index) => {
    if (index === 1) {
        const tourstate = JSON.stringify(state);
        document.getElementById('tourstate').value = tourstate;
    } else if (index === 2) {
        const tourstate = JSON.stringify(state);
        document.getElementById('paymenttourstate').value = tourstate;
    } else if (index === 3) {
        const tourstate = JSON.stringify(state);
        document.getElementById('projecttourstate').value = tourstate;
    } else if (index === 4) {
        const tourstate = JSON.stringify(state);
        document.getElementById('modeltourstate').value = tourstate;
    } else if (index === 5) {
        const tourstate = JSON.stringify(state);
        document.getElementById('TourToProjectTourstate').value = tourstate;
    } else if (index === 6) {
        const tourstate = JSON.stringify(state);
        document.getElementById('TourToModelTourstate').value = tourstate;
    }
}

export const confirmNextStep = (index) => {
    const cur_tour_state = getTourState(index);
    if (cur_tour_state && isTourOpened(index)) {
        const tourstatae = {
            current_step_index: cur_tour_state['current_step_index'],
            current_step_status: 1
        };
        putTourState(tourstatae, index);
        return true;
    }
    return false;
}

export const confirmInitialStep = (index) => {
    const tourstatae = {
        current_step_index: 0,
        current_step_status: 1
    };
    putTourState(tourstatae, index);
}
export const goNextStep = (tourobj, index) => {
    if (tourobj === null) return;
    const cur_tour_state = getTourState(index);
    if (cur_tour_state && cur_tour_state['current_step_status'] === 1){
        const next_step_index = cur_tour_state['current_step_index'] + 1;
        if ((window.scrollY ===0) || (index=== 3 && cur_tour_state['current_step_index'] === 6) || (index=== 3 && cur_tour_state['current_step_index'] === 7)){
            tourobj.introJs.start();
            tourobj.introJs.goToStep(next_step_index);
            const tourstate = {
                current_step_index: next_step_index,
                current_step_status: 0
            };
            putTourState(tourstate, index);
        } else{
            window.scrollTo(0, 0);
            setTimeout(function () {
                tourobj.introJs.start();
                tourobj.introJs.goToStep(next_step_index);
                const tourstate = {
                    current_step_index: next_step_index,
                    current_step_status: 0
                };
                putTourState(tourstate, index);
            }, 1000);
        }
        /*if (window.scrollY !==0){
            window.scrollTo(0, 0);
            setTimeout(function () {
                tourobj.introJs.start();
                tourobj.introJs.goToStep(next_step_index);
                const tourstate = {
                    current_step_index: next_step_index,
                    current_step_status: 0
                };
                putTourState(tourstate, index);
            }, 1000);
        } else{
            tourobj.introJs.start();
            tourobj.introJs.goToStep(next_step_index);
            const tourstate = {
                current_step_index: next_step_index,
                current_step_status: 0
            };
            putTourState(tourstate, index);
        }*/
    }

    /*//test mode
    introjs.start();
    introjs.goToStep(2);*/

}


export const alert3 = (msg, level) => {
    if (level === 'success') {
        toastr.success(msg);
    }
    if (level === 'warning') {
        toastr.warning(msg);
    }
    if (level === 'error') {
        toastr.error(msg);
    }
    if (level === 'info') {
        toastr.info(msg);
    }
};
export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const parseEmail = (e) => {
    var username = e.split("@")[0];
    const names = username.split(".");
    if(names.length === 1) {
        username = capitalize(names[0]);
    } else if (names.length === 2){
        username = capitalize(names[0]) + " " + capitalize(names[1]);
    } else if (names.length > 2) {
        const firstname = names.slice(0, names.length - 1).join(" ");
        username = capitalize(firstname) + " " + capitalize(names[names.length - 1]);
    }
    return username;
};
export const parsefullname = (e) => {
    var username = {
        firstname: "",
        lastname: ""
    };
    const names = e.split(" ");
    if(names.length === 1) {
        username.firstname = capitalize(names[0]);
    } else if (names.length === 2){
        username.firstname = capitalize(names[0]);
        username.lastname = capitalize(names[1]);
    } else if (names.length > 2) {
        const firstname = names.slice(0, names.length - 1).join(" ");
        username.firstname = firstname;
        username.lastname = capitalize(names[names.length - 1]);
    }
    return username;
};
export const alert2 = (msg, level) => {
    if (level === 'success') {
        document.getElementById('success2rect').style.display = 'block';
        document.getElementById('warning2rect').style.display = 'none';
    }
    if (level === 'warning') {
        document.getElementById('warning2rect').style.display = 'block';
        document.getElementById('success2rect').style.display = 'none';
    }
    document.getElementById('alert2modal').style.display = 'block';
    document.getElementById('alert2msg').innerHTML = msg;
};
export const open_loading = () => {
    document.getElementById('loading').style.display = 'block';
};
export const close_loading = () => {
    document.getElementById('loading').style.display = 'none';
};

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        return JSON.parse(user);
    return null;
}

const getCurrentProject = () => {
    const current_project = localStorage.getItem('current_project');
    if (current_project)
        return JSON.parse(current_project);
    return null;
}
export const copy = (el) => {
    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(el.value);
}
//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const bookdemo = (history) => {
    document.getElementById('bookdemoclicked').value = "true";
    history.push('/');
}

const back_to_dashboard = (history) => {
    history.push('/dashboard');
}

const handle_response = (response) => {
    if (response.status >= 200 || response.status <= 299){
        return response.data;
    }
    else{
        throw response.data;
    }
}
const handle_exception = (err) => {
    var message;
    var resp_payload = err.response.data
    if (err.response && err.response.status) {
        switch (err.response.status) {
            case 404: message = "Sorry! The page you are looking for could not be found"; break;
            case 500: message = "Sorry! Something went wrong, please contact our support team"; break;
            case 406: {
                message = "Sorry! Something went wrong, please contact our support team";
                if (resp_payload.result && resp_payload.message) {
                    message = resp_payload.message;
                    if (resp_payload.code && resp_payload.code === 128) {
                        message = 128;
                    }
                }
                break;
            }
            case 401: {
                if (resp_payload.code === "token_not_valid" && err.response.statusText === "Unauthorized"){
                    window.location.href = "/login/";
                } else {
                    message = err.response.statusText;
                    if (Object.keys(resp_payload).length > 0) {
                        message = resp_payload[Object.keys(resp_payload)[0]];
                    }
                    break;
                }
            }
            default: {
                message = err.response.statusText;
                if (Object.keys(resp_payload).length > 0) {
                    message = resp_payload[Object.keys(resp_payload)[0]];
                }
                break;
            }
        }
    }else {
        message = "Sorry! Something went wrong, please contact our support team"
    }
    // throw message;
    throw message
}

// Saga post
export const postSaga = (url, data) => {
    const login_url = "/auth/login/";
    var config = {};
    if (url===login_url) {
        if (process.env.REACT_APP_AXIOS_HAS_CREDENTIALS === 'true') {
            config.withCredentials = true;
        }
    }
    url = API_URL + url;
    return axios.post(url, data, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
};

// Register Method
const postRegister = (url, data) => {
    url = API_URL + url;
    return axios.post(url, data).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
};

// Login Method
const postLogin = (url, data) => {
    url = API_URL + url;
    return axios.post(url, data).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}

// postForgetPwd 
const postForgetPwd = (url, data) => {
    url = API_URL + url;
    return axios.post(url, data).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}
const postRecoverPwd = (url, data) => {
    url = API_URL + url;
    return axios.post(url, data).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}
export async function public_post(url, data) {
    const login_url = API_URL + "/auth/login/";
    var config = {};
    if (url===login_url) {
        if (process.env.REACT_APP_AXIOS_HAS_CREDENTIALS === 'true') {
            config.withCredentials = true;
        }
    }
    return await axios.post(url, data, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}
export async function public_get(url) {
    const login_url = API_URL + "/auth/login/";
    var config = {};
    if (url===login_url) {
        if (process.env.REACT_APP_AXIOS_HAS_CREDENTIALS === 'true') {
            config.withCredentials = true;
        }
    }
    return await axios.get(url, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}

export async function private_get(url, config = {}) {
    if (url.includes(MY_MODEL_URL)) {
        const cur_project = getCurrentProject();
        if (cur_project !== null) {
            config.params = {__cur_project: cur_project.id}
        }
    }
    return await get(url, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}
export async function private_post(url, data, config={}) {
    if (url.includes(MY_MODEL_URL)) {
        const cur_project = getCurrentProject();
        if (cur_project !== null) {
            if (config.data === undefined){
                config.data = {__cur_project: cur_project.id}
            }else{
                config.data.__cur_project = cur_project.id
            }
        }
    }
    return await post(url, data, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}
export async function private_del(url, config={}) {
    if (url.includes(MY_MODEL_URL)) {
        const cur_project = getCurrentProject();
        if (cur_project !== null) {
            if (config.data === undefined){
                config.data = {__cur_project: cur_project.id}
            }else{
                config.data.__cur_project = cur_project.id
            }
        }
    }
    return await del(url, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}

export async function private_put(url, data, config={}) {
    if (url.includes(MY_MODEL_URL)) {
        const cur_project = getCurrentProject();
        if (cur_project !== null) {
            if (config.data === undefined){
                config.data = {__cur_project: cur_project.id}
            }else{
                config.data.__cur_project = cur_project.id
            }
        }
    }
    return await put(url, data, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}

export async function private_patch(url, data, config={}) {
    if (url.includes(MY_MODEL_URL)) {
        const cur_project = getCurrentProject();
        if (cur_project !== null) {
            if (config.data === undefined){
                config.data = {__cur_project: cur_project.id}
            }else{
                config.data.__cur_project = cur_project.id
            }
        }
    }
    return await patch(url, data, config).then(response => {
        return handle_response(response);
    }).catch(err => {
        return handle_exception(err)
    });
}

// get Events
export const getEvents = () =>
    get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
    del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

export { getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd, postRecoverPwd, handle_exception, handle_response, getCurrentProject, back_to_dashboard }