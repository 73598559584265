import React, { Component, useContext } from "react";
import {Row, Col, Alert, Card, CardBody, Button, Label, Container, InputGroup} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {
    private_post,
    private_get,
    alert3,
    open_loading,
    close_loading,
    private_put
} from '../../../helpers/fackBackend_Helper';
import {APIS_URL, API_URL_LIST_URL, PAYMENT_TERM_TYPE_URL, MEMBERSHIP_TYPE_URL} from "../../../helpers/url_helper";

class ADUpdateMembership extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        const _id = this.props.match.params.id;
        const url = MEMBERSHIP_TYPE_URL + _id + "/";
        var resp = await private_get(url);
        if (resp) {
            // var quotas = JSON.parse(resp.quotas);
            var quotas = resp.quotas;
            this.setState({
                name: resp.name,
                id: resp.id,
                desc: resp.desc,
                days: resp.days,
                price: resp.price,
                discount: resp.discount,
                doc_size: quotas.doc_size,
                pages: quotas.pages,
                models: quotas.models,
                projects: quotas.projects,
            });
        }
    }

    handleCancel() {
        this.props.history.push("/admin/membership-type");
    }
    async handleSubmit(event, values) {
        try{
            var quotas = {
                doc_size: values.q_docsize,
                pages: values.q_pages,
                models: values.q_model,
                projects: values.q_project,
            };
            // values.quotas = JSON.stringify(quotas);
            values.quotas = quotas;
            delete values.q_docsize;
            delete values.q_pages;
            delete values.q_model;
            delete values.q_project;
            const url = MEMBERSHIP_TYPE_URL + this.props.match.params.id + "/";
            open_loading();
            const resp = await private_put(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/admin/membership-type");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">Edit Membership</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">Name </Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter the name of membership type"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="desc">Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter the description of membership type"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.desc}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="price">Price</Label>
                                                 <InputGroup className="policy_price_inputgr">
                                                    <span className="input-group-append">
                                                        <span className="input-group-text">$</span>
                                                      </span>
                                                    <AvField
                                                    name="price"
                                                    type="number"
                                                    step="0.01"
                                                    errorMessage="Enter the valid price"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.price}
                                                    />
                                                </InputGroup>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="days">Days</Label>
                                                <AvField
                                                name="days"
                                                type="number"
                                                errorMessage="Enter the days"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.days}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="discount">Discount</Label>
                                                <InputGroup className="policy_price_inputgr">
                                                    <span className="input-group-append">
                                                        <span className="input-group-text">%</span>
                                                      </span>
                                                    <AvField
                                                    name="discount"
                                                    type="number"
                                                    step="0.01"
                                                    min="0"
                                                    max="1"
                                                    errorMessage="Enter the valid discount"
                                                    className="form-control"
                                                    validate={{ required: { value: true }, min: { value: 0, errorMessage: "Min: 0" }, max: { value: 1, errorMessage: "Max: 1" } }}
                                                    value={this.state.discount}
                                                    />
                                                </InputGroup>

                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label className="form-label font-weight-bold mb-3 mt-4">Quotas</Label>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_project">Projects</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_project"
                                                type="number"
                                                errorMessage="Set the maximum number of projects"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.projects}
                                                />
                                            </div>
                                            </Col>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_model">Models</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_model"
                                                type="number"
                                                errorMessage="Set the maximum number of models per project"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.models}
                                                />
                                            </div>
                                            </Col>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_pages">Pages</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_pages"
                                                type="number"
                                                errorMessage="Set the maximum number of pages"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.pages}
                                                />
                                            </div>
                                            </Col>
                                            <Col lg="6">
                                                <Label className="form-label" htmlFor="q_docsize">File Size (MB)</Label>
                                            <div className="mb-3">
                                                <AvField
                                                name="q_docsize"
                                                type="number"
                                                errorMessage="Set the maximum file size of the document to be uploaded"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.doc_size}
                                                />
                                            </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADUpdateMembership