import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Container,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Modal,
    CardBody,
    Card, UncontrolledPopover, PopoverHeader, PopoverBody
} from "reactstrap";
import { Link } from "react-router-dom";
import {
    private_get,
    private_del,
    private_put,
    back_to_dashboard,
    alert3,
    open_loading, close_loading, goNextStep, confirmNextStep, getTourState, timeout, getLoggedInUser
} from '../../helpers/fackBackend_Helper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Tables/datatables.scss";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Hotspot from "../../components/Common/hotspot";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {MY_PROJECT_URL} from "../../helpers/url_helper";

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Project", link: "#" },
        { title: "List", link: "#" },
      ],
      sizePerPage: 10,
      projectData: [],
      row_id: null

    };
    this.handlecreateproject = this.handlecreateproject.bind(this);
    this.projectdetailview = this.projectdetailview.bind(this);
    this.linkFollow = this.linkFollow.bind(this);
    this.projectstatusupdate = this.projectstatusupdate.bind(this);
    this.deleteproject = this.deleteproject.bind(this);
    this.cancel_del_project = this.cancel_del_project.bind(this);
    this.confirm_del_project = this.confirm_del_project.bind(this);
    this.projecteditview = this.projecteditview.bind(this);
  }

  async confirm_leaveproject() {
    document.getElementById('del_confirm_modal').style.display = 'none';
    const me = getLoggedInUser();
    const user_id = me.id;
    const url = MY_PROJECT_URL + this.state.row_id + "/members?user=" + user_id;

      try{
          open_loading();
          const resp = await private_del(url);
          if (resp || resp === '') {
              alert3("You leaved the project successfully", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }
  cancel_leaveproject() {
    document.getElementById('del_confirm_modal').style.display = 'none';
  }

  projecteditview(cell, row, row_index) {
      this.props.history.push("/project-update/" + row.id);
  }

  cancel_del_project() {
      document.getElementById('project_del_confirm_modal').style.display = 'none';
  }
  async confirm_del_project() {
      document.getElementById('project_del_confirm_modal').style.display = 'none';
      const url = MY_PROJECT_URL + this.state.row_id + "/";
      try{
          open_loading();
          const resp = await private_del(url);
          if (resp || resp === '') {
              alert3("Success", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }

  deleteproject(cell, row, row_index) {
      this.setState({row_id: row.id});
      this.setState({row_name: row.name});
      document.getElementById('project_del_confirm_modal').style.display = 'block';
  }
  leaveproject(cell, row, row_index) {
      this.setState({row_id: row.id});
      this.setState({row_name: row.name});
      document.getElementById('del_confirm_modal').style.display = 'block';
  }

  async projectstatusupdate(cell, row, row_index) {
      const url = MY_PROJECT_URL + row.id + "/";
      var data = {is_active: false};
      if (row.is_active === 'Disabled') {
          data = {is_active: true};
      }
      try{
          open_loading();
          const resp = await private_put(url, data);
          if (resp) {
              alert3("Success", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }
  projectdetailview(cell, row, row_index) {
      confirmNextStep(3);
      this.props.history.push("/project-detail/" + row.id);
  }

  handlecreateproject() {
      confirmNextStep(3);
      this.props.history.push("/project-create");
  }

  async loadTable() {
    const url = MY_PROJECT_URL;
    const raw_resp = await private_get(url);
    const resp = raw_resp.sort(function(a,b){
      return new Date(b.created_date) - new Date(a.created_date);
    });
    const data = [];
    for (var i=0; i < resp.length; i++) {
      var param = {};
      param.id = resp[i].id;
      param.name = resp[i].name;
      param.desc = resp[i].desc;
      param.role = resp[i].role_id;
      param.is_active = "Disabled";
      if (resp[i].is_active){
        param.is_active = "Enabled";
      }
      param.hotspot = "none_ux" + param.id;
      if (i === 0) {
          param.hotspot = "ux_project_detail_view";
      }
      data.push(param);
    }
    this.setState({projectData: data});
  }

  async componentDidMount() {
    this.loadTable();
    const tourstate = getTourState(3);
    if (!tourstate) return;
    if (tourstate['current_step_index'] === 4) {
        this.countintervalId = setInterval(() => {
            if (document.getElementById('ux_project_detail_view') !== null) {
                goNextStep(this.props.projecttourobj, 3);
                clearInterval(this.countintervalId);
            }
        }, 100);
    } else {
        goNextStep(this.props.projecttourobj, 3);
    }

  }
  linkFollow (cell, row, rowIndex, formatExtraData) {

      return (
        <div>
          <a title="View the project information" onClick={() => {this.projectdetailview(cell, row, rowIndex)}} className="me-2 actionbtn purple" ><i id={row.hotspot} className="ri-external-link-line"></i></a>
          <a title="Edit the project" onClick={() => {this.projecteditview(cell, row, rowIndex)}} className="me-2 actionbtn purple"><i className="ri-pencil-fill"></i></a>
          <a title="Delete the project" onClick={() => {this.deleteproject(cell, row, rowIndex)}} className="me-2 actionbtn red" ><i className="ri-delete-bin-6-line"></i></a>
            {row.role !== 1 &&<a title="Leave the project" onClick={() => {this.leaveproject(cell, row, rowIndex)}} className="actionbtn red" ><i className="  ri-logout-box-r-line"></i></a>}
      </div>
    )
  }
  roleFollow(cell, row, rowIndex, formatExtraData){
    var role_rname = '';
    if (row.role === 1){
        role_rname = "Project Owner";
    }
    if (row.role === 2){
        role_rname = "Project Manager";
    }
    if (row.role === 3){
        role_rname = "Project Member";
    }
    return (
        <div>{role_rname}</div>
    )
  }

  render() {

    const columns = [
    {
      dataField: 'id',
      text: 'id',
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
        style: {width: "15%"},
      headerStyle: {width: "15%"},
    }, {
      dataField: 'desc',
      text: 'Description',
      sort: true,
            style: {width: "50%"},
      headerStyle: {width: "50%"},
    }, {
      dataField: 'role',
      text: 'Role',
      sort: true,
      formatter: this.roleFollow.bind(this),
            style: {width: "15%"},
      headerStyle: {width: "15%"},
    }, {
      dataField: 'action',
      text: 'Action',
      formatter: this.linkFollow,
            style: {width: "20%"},
      headerStyle: {width: "20%"},
    },

    ];

    const defaultSorted = [{
      dataField: 'name',
      order: 'asc'
    }];
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
      </span>
    );
    const pageOptions = {
        alwaysShowAllBtns: true,
        prePageText: '<',
        nextPageText: '>',
        withFirstAndLast : true,
        firstPageText: '<<',
        lastPageText: '>>',
        showTotal: true,
        disablePageTitle: true,
        sizePerPage: 20,
        paginationTotalRenderer: customTotal,
        totalSize: this.state.projectData.length,
        sizePerPageList: [{
            text: '5', value: 5
          }, {
            text: '10', value: 10
          }, {
            text: '20', value: 20
          }, {
            text: 'All', value: this.state.projectData.length
          }]
    };
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="mb-5">
                        <Col>
                            <Button color="primary" type="button" onClick={() => back_to_dashboard(this.props.history)}>Back to dashboard</Button>
                        </Col>
                    </Row>
            <h4 className="card-title-x">Your Projects</h4>
              <Row>
                <Col xl={7}>
                      <Card>
                        <CardBody>
                        <Row>
                          <Col>
                              <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField='id'
                                  columns={columns}
                                  data={this.state.projectData}
                                >
                                  {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField='id'
                                      columns={columns}
                                      data={this.state.projectData}
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>

                                          <Row>
                                            <Col>
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="ri-search-line appsearch" />
                                                </div>
                                              </div>
                                                <Button id="ux_add_new_project_button" onClick={this.handlecreateproject} className="float-right" color="primary" type="submit">New Project</Button>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col>
                                              <div className="custom-table">
                                                <BootstrapTable
                                                  noDataIndication="Table is Empty"
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  classes={
                                                    "table align-middle table-nowrap"
                                                  }
                                                  headerWrapperClasses={"thead-light"}
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />

                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )
                                      }
                                    </ToolkitProvider>
                                  )
                                  }</PaginationProvider>
                          </Col>
                        </Row>
                        </CardBody></Card>
                </Col></Row>


              <div className="tbindex" id="project_del_confirm_modal">
                    <div className="">
                        <div className="modal fade show d-block" role="dialog">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div>
                                                <i className="yellow ri-alert-fill font-size-80"></i>
                                            </div>
                                            <div className="mb-4">
                                            <span className="font-size-16">Are you sure you want to delete project <span className="font-weight-bold">"{this.state.row_name}"</span>?</span>
                                            </div>
                                            <div className="red font-weight-bold">
                                            <span>Once you delete project, you won't be able to revert it. Please be certain.</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.confirm_del_project} type="button" className="btn btn-primary">OK</button>
                                        <button onClick={this.cancel_del_project} type="button" className="btn btn-secondary">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                </div>
              <div className="tbindex" id="del_confirm_modal">
                        <div className="">
                            <div className="modal fade show d-block" role="dialog">
                                <div className="modal-dialog modal-sm" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="text-center">
                                                <div>
                                                    <i className="yellow ri-alert-fill font-size-80"></i>
                                                </div>
                                                <div className="mb-4">
                                                <span className="font-size-16">Are you sure you want to leave the project <span className="font-weight-bold">"{this.state.row_name}"</span>?</span>
                                                </div>
                                                <div className="red font-weight-bold">
                                                <span>Please be certain.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.confirm_leaveproject.bind(this)} type="button" className="btn btn-primary">OK</button>
                                            <button onClick={this.cancel_leaveproject.bind(this)} type="button" className="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"></div>
                        </div>
                    </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {})(withRouter(Project));

//export default Project