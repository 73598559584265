import React, { Component } from 'react';

import {Row, Col, Button, Alert, Container, Label, CardBody, Card} from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import product1 from "../../assets/images/land/product1.svg";
import model1 from "../../assets/images/land/model1.svg";
import model2 from "../../assets/images/land/model2.svg";
import model3 from "../../assets/images/land/model3.svg";
import model4 from "../../assets/images/land/model4.svg";
import model5 from "../../assets/images/land/model5.svg";
import model6 from "../../assets/images/land/model6.svg";
import {bookdemo} from "../../helpers/fackBackend_Helper";


class Product extends Component {

    constructor(props) {
        super(props);
        this.state = { name: "", company: "", phone: "", email: "", feedback: "", pages: "" };
    }

    componentDidMount() {
        var link = document.getElementById('land-product');
        link.classList.add('active');
        var link_mb = document.getElementById('land-product-mb');
        link_mb.classList.add('active');
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
    }

    handleChange(){

    }

    handleSubmit(){

    }

    render() {

        return (
            <React.Fragment>
                <div className="main-content-land">
                    <section id="product_demo" className="">
                        <div className="container">
                            <Row className="align-items-center mb-xl-5">
                                <Col xl={6} className="mb-5 pr-6 mobile_col">
                                    <div>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Accelerate Your Lending Business With <span className="land-main-color">Ellietrac Products</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-5">Fast, accurate, reliable, clean data extraction in up to 40 types of mortgage and banking documents</h4>
                                            <div className="d-flex">

                                                <Button onClick={() => {window.location.href = 'https://console.ellietrac.ai/register'}} className="btn bluebtn  mr-3"><span className="d-flex align-items-center">Get Started</span></Button>
                                                <Button onClick={() => bookdemo(this.props.history)} className="btn whitebtn rightibtn bookbtn"><span className="d-flex align-items-center">Book Demo <i className="ri-arrow-right-line"></i></span></Button>
                                            </div>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-xl-5 px-0 mobile_col">
                                    <div>
                                        <img className="w-100" src={product1} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section className="chart">
                        <div className="container">
                            <div className="chartbg">
                                <Row className="h-100 align-items-center">
                                    <Col className="chart-offset" xl={6}></Col>
                                    <Col xl={6}>
                                        <div className="pl-6 chart-r-card">
                                            <h3 className="white-color font-size-38 font-weight-bold mb-3">Accelerate Your</h3>
                                            <h3 className="white-color font-size-38 font-weight-bold mb-3">Business!</h3>
                                            <div className="d-flex">
                                                <Button onClick={() => {window.location.href = 'https://console.ellietrac.ai/register'}} className="btn transbtn mr-3"><span className="d-flex align-items-center">Get Started</span></Button>
                                                <Button onClick={() => bookdemo(this.props.history)} className="btn whitebtn rightibtn bookbtn"><span className="d-flex align-items-center">Book Demo <i className="ri-arrow-right-line"></i></span></Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </section>

                    <section id="model">
                        <div className="container">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-50 font-weight-bold mb-3">Most Accurate Document</h3>
                                    <h3 className="land-main-color font-size-50 font-weight-bold mb-3">Data Extraction</h3>
                                    <h4 className="land-gray-100-color font-size-18 mb-5">Ellietrac breaks 95%+ accuracy across all types of lending document. </h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4} className="">
                                    <div className=" card_models">
                                        <img className="mt-5" src={model2} />
                                        <h3 className="land-gray-color font-size-22 font-weight-bold mb-3 mt-1">Document Classification</h3>
                                        <h4 className="elip-3 land-gray-100-color font-size-18 mb-2">Classify up to 40 different types of lending forms in a mortgage packet. In most cases, you want to process a document that contains multiple forms such as W-2, 1040, 1033, etc.</h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="  card_models">
                                        <img className="mt-5" src={model3} />
                                        <h3 className="land-gray-color font-size-22 font-weight-bold mb-3 mt-1">US Driver License</h3>
                                        <h4 className="elip-3 land-gray-100-color font-size-18 mb-2">Extract numerous fields from US Driver License Card
                                        </h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className=" card_models">
                                        <img className="mt-5" src={model4} />
                                        <h3 className="land-gray-color font-size-22 font-weight-bold mb-3 mt-1">Bank Statement</h3>
                                        <h4 className="elip-3 land-gray-100-color font-size-18 mb-2">Extract from bank statements including name, account, transactions, etc.
                                        </h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className=" card_models">
                                        <img className="mt-5" src={model5} />
                                        <h3 className="land-gray-color font-size-22 font-weight-bold mb-3 mt-1">Paystub</h3>
                                        <h4 className="elip-3 land-gray-100-color font-size-18 mb-2">Extract from Form 1120S, name, address, assets, etc.
                                        </h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className="  card_models">
                                        <img className="mt-5" src={model6} />
                                        <h3 className="land-gray-color font-size-22 font-weight-bold mb-3 mt-1">Mortgage Statement</h3>
                                        <h4 className="elip-3 land-gray-100-color font-size-18 mb-2">Extract from Form 1120, partnership name, address, assets, etc.
                                        </h4>
                                    </div>
                                </Col>
                                <Col xl={4} className="">
                                    <div className=" card_models">
                                        <img className="mt-5" src={model1} />
                                        <h3 className="land-gray-color font-size-22 font-weight-bold mb-3 mt-1">Custom</h3>
                                        <h4 className="elip-3 land-gray-100-color font-size-18 mb-2">Build custom model
                                        </h4>
                                    </div>
                                </Col>

                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <div className="d-flex justify-content-center">
                                        <Button onClick={() => bookdemo(this.props.history)} className="btn bluebtn rightibtn bookbtn"><span className="d-flex align-items-center">Book Demo <i className="ri-arrow-right-line"></i></span></Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </section>

                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return {
        ...state.Layout
    };
}

export default withRouter(connect(mapStatetoProps, { })(Product));