
import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    getLoggedInUser,
    private_post,
    private_put,
    back_to_dashboard,
    alert3,
    open_loading,
    close_loading
} from '../../../helpers/fackBackend_Helper';
import {OPERATOR_URL} from "../../../helpers/url_helper";
import {countries} from "../../../common/data/country";

class ADCreateOperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            firstname: "",
            lastname: "",
            address: "",
            company: "",
            country: "US",
            phone: "",
            password: "",
            confirm_password: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleCancel() {
        this.props.history.push('/admin/operator');
    }
    handleChange(e) {
        this.setState({ country: e.target.value });
    }
    async handleSubmit(event, values) {
        try{
            values.country = this.state.country;
            values.username = values.email;
            var url = OPERATOR_URL;
            if (values.password !== values.confirm_password) {
                alert3('Password does not match', 'warning');
                return;
            }
            delete values.confirm_password;
            open_loading();
            const resp = await private_post(url, values);
            if (resp || resp === '') {
                alert3('Success', 'success');
                this.props.history.push('/admin/operator');
            }
        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    componentDidMount() {

    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">Administrator Details</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                    <Row>
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="firstname">First Name *</Label>
                                            <AvField
                                            name="firstname"
                                            type="text"
                                            errorMessage="Enter First Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="firstname"
                                            value={this.state.firstname}
                                            />
                                        </div>
                                        </Col>
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="lastname">Last Name *</Label>
                                            <AvField
                                            name="lastname"
                                            type="text"
                                            errorMessage="Enter Last name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="lastname"
                                            value={this.state.lastname}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="email">Email *</Label>
                                            <AvField
                                            name="email"
                                            type="email"
                                            errorMessage="Enter your email"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="email"
                                            value={this.state.email}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="password">Password *</Label>
                                            <AvField
                                            name="password"
                                            type="password"
                                            errorMessage="Enter password"
                                            className="form-control"
                                            validate={{ required: { value: true } , minLength: { value: 8, errorMessage: "Min 8 chars." }}}
                                            id="password"
                                            value={this.state.password}
                                            />
                                        </div>
                                        </Col>
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="password">Confirm Password *</Label>
                                            <AvField
                                            name="confirm_password"
                                            type="password"
                                            errorMessage="Enter password"
                                            className="form-control"
                                            validate={{ required: { value: true },minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                            id="confirm_password"
                                            value={this.state.confirm_password}
                                            />
                                        </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="country">Country *</Label>
                                            <select className="form-control" name='country' id='country' value={this.state.country} onChange={this.handleChange}>
                                              {countries.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                            </select>
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="address">Address</Label>
                                            <AvField
                                            name="address"
                                            type="text"
                                            errorMessage="Enter your address"
                                            className="form-control"
                                            id="address"
                                            value={this.state.address}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="company">Compnay</Label>
                                            <AvField
                                            name="compnay"
                                            type="text"
                                            errorMessage="Enter your compnay"
                                            className="form-control"
                                            id="company"
                                            value={this.state.company}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="phone">Phone No.</Label>
                                            <AvField
                                            name="phone"
                                            type="tel"
                                            errorMessage="Enter your phone"
                                            className="form-control"
                                            id="phone"
                                            value={this.state.phone}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Create Administrator</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADCreateOperator