import React, { Component } from "react";
import {Row, Col, Alert, Button, Container, CardBody, Card, Spinner} from "reactstrap";
// Redux
import { AvForm } from "availity-reactstrap-validation";
import { API_URL } from "../../helpers/api_helper";
import {
    public_post,
    private_post,
    timeout,
    alert3,
    getLoggedInUser,
    open_loading,
    close_loading, private_get
} from "../../helpers/fackBackend_Helper";
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";
import emailicon from "../../assets/images/email/email-icon-102.jpg";
import {ME_URL} from "../../helpers/url_helper";

class EmailVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            is_email_verified: false,
            show_sec: false,
            count: 5,
            is_email_verifing_timout: false
        };
        this.handleResendemail = this.handleResendemail.bind(this);
    }

    count_sec() {
        var cur = this.state.count - 1;
        this.setState({
            count:cur
        });
    }

    async handleResendemail(e) {
        e.preventDefault();
        var values = {};
        var user = getLoggedInUser();
        values.email = user.email;
        var url = API_URL + '/auth/email_verify/resend/';
        try{
            const resp = await public_post(url, values);
            if (resp){
                alert3("Verification email has been sent to your email address", 'success');
            }
        }
        catch (err) {
            var e = err;
            if (err === 128) {
                e = 'Failed to send verification email. Please resend.';
            }
            alert3(e, 'error');
        }
    }

    async check_user_email_verified_status() {
        var user = getLoggedInUser();
        const url = ME_URL + user.id + "/";
        const user_info = await private_get(url);
        this.setState({
            is_email_verified: user_info.is_email_verified
        });
        if (user_info.is_email_verified) {
            clearInterval(this.intervalId);
            this.setState({
               show_sec: true
            });
            this.countintervalId = setInterval(() => {
                    this.count_sec();
                }, 1000);
            localStorage.setItem("is_email_verified", true);
            await timeout(5000);
            this.props.history.push('/welcome');
        }
    }
    async componentDidMount() {
        var user = getLoggedInUser();
        this.setState({
            email: user.email
        });
        this.intervalId = setInterval(() => {
            this.check_user_email_verified_status();
        }, 5000);
        await timeout(1800 * 1000);
        this.setState({
           is_email_verifing_timout: true
        });
        clearInterval(this.countintervalId);


    }
    componentWillUnmount(){
        clearInterval(this.intervalId);
        clearInterval(this.countintervalId);
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={4}>
                                <div className="p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={10}>
                                                <Card>
                                                <CardBody>
                                                    {!this.state.is_email_verifing_timout && this.state.is_email_verified &&
                                                        <div className="">
                                                            <div className="text-center">
                                                                <img src={emailicon} alt="" width="120" />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-size-28 mt-5 font-w-600">Your email is verified</h4>
                                                                <div className="mt-4">
                                                                    <i className="mdi mdi-check-circle purple font-size-30"></i>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <span>This link will be redirected to the next registration process within number of seconds </span>
                                                                </div>
                                                                {this.state.show_sec &&
                                                                    <h4 className="mt-4 font-size-30 font-weight-bold">{this.state.count}s</h4>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {!this.state.is_email_verifing_timout && !this.state.is_email_verified &&
                                                        <div className="">
                                                            <div className="text-center">
                                                                <img src={emailicon} alt="" width="120" />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-size-28 mt-5 font-w-600">Verify your email</h4>
                                                                <Spinner className="purple mt-4" />
                                                                    <p className="mt-4">Verification email has been sent to
                                                                        {this.state.email !== "" ? (
                                                                            <span className="font-weight-bold"> {this.state.email}</span>
                                                                        ):
                                                                            <span> your email address</span>
                                                                        }.
                                                                        Please check your email inbox. </p>
                                                            </div>

                                                            <div className="p-2 mt-4">
                                                                <AvForm className="form-horizontal">
                                                                    <div className="mt-4 text-center">
                                                                        <div>If you didn't receive an email or verification link is already expired, please <Link to="#" onClick={ (e) => this.handleResendemail(e)} className=""> Resend </Link></div>
                                                                    </div>
                                                                </AvForm>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.is_email_verifing_timout &&
                                                        <div className="">
                                                            <div className="text-center">
                                                                <img src={emailicon} alt="" width="120" />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-size-28 mt-5 font-w-600">Email verification timeout</h4>
                                                                <div className="mt-4">
                                                                    <i className="mdi mdi-alert purple font-size-30"></i>
                                                                </div>
                                                                <div className="mt-4 mb-3">
                                                                    <span>You maybe did not check the verification email within expiry time. please <Link to="#" onClick={ (e) => this.handleResendemail(e)} className=""> Resend </Link></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                            </CardBody></Card>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, { })(withRouter(EmailVerify));
