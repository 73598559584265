import React, { Component } from "react";
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import {connect} from "react-redux";
import {setmodeltourobj} from "../../store/layout/actions";
import { withRouter } from 'react-router-dom';

const steps = [
    {
        element: '#ux_model_li',
        intro: "Please move to the model list page!",
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#ux_new_model_button',
        intro: "Please click the \"New Model\" button!",
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#ux_model_create_form',
        intro: "Please enter model name, description, and select the model type, and submit!",
        title: "Getting started",
        position: 'top',
        tooltipClass: "ux_model_create_form_tooltip",
    },
    {
        element: '#ux_model_detail_view',
        intro: "Please move to the model detail page!",
        title: "Getting started",
        position: 'right',
    },
    {
        element: '#ux_copy_model_url',
        intro: "Please copy the model API endpoint!",
        title: "Getting started",
        position: 'right',
    },

];
const options = {
    exitOnOverlayClick: true,
    exitOnEsc: false, // switch to false in production
    overlayOpacity: 0.5,
    showBullets: false,
    showButtons: false,
    showStepNumbers: true,
    showProgress: true,
    hidePrev: true,
    helperElementPadding:5,
    disableInteraction: false,
    scrollToElement: true,
    scrollTo: "element",
    tooltipClass: "modelglobaltooltipClass",
    highlightClass: "modelglobalhighlightClass"
};
class ModelTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*stepenable: this.props.enable_tour,
            initialStep: this.props.next_step_index*/
            stepenable: false,
            initialStep: 0
            /*stepenable: true,
            initialStep: 1*/
        };
    }
    onExit() {
        document.getElementById('modeltourstate').value = "";
    }
    onBeforeChange() {
    }
    componentDidMount() {
        this.props.setmodeltourobj(this.steps);
    }
    render() {
        return (
            <React.Fragment>
                <Steps
                    enabled={this.state.stepenable}
                    steps={steps}
                    initialStep={this.state.initialStep}
                    onExit={this.onExit.bind(this)}
                    onBeforeChange ={this.onBeforeChange.bind(this)}
                    options={options}
                    ref={steps => (this.steps = steps)}
                />
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
}
export default connect(mapStatetoProps, { setmodeltourobj })(withRouter(ModelTour));
// export default Tour;
