import React, { Component } from 'react';

import {Row, Col, Button, Alert, Container, Label, CardBody, Card} from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import brain from "../../assets/images/land/brain.svg";
import feature1 from "../../assets/images/land/feature1.svg";
import feature2 from "../../assets/images/land/feature2.svg";
import feature3 from "../../assets/images/land/feature3.svg";
import feature4 from "../../assets/images/land/feature4.svg";
import feature5 from "../../assets/images/land/feature5.svg";
import func1 from "../../assets/images/land-png/func1.png";
import func2 from "../../assets/images/land-png/func2.png";
import func3 from "../../assets/images/land-png/func3.png";
import func4 from "../../assets/images/land-png/func4.png";
import func5 from "../../assets/images/land-png/func5.png";
import feedback from "../../assets/images/land/feedback.svg";
import dot from "../../assets/images/land/dot-grid.svg";
import leftshape from "../../assets/images/land/shape-left.svg";
import rightshape from "../../assets/images/land/shape-right.svg";
import leftarrow from "../../assets/images/land/left-arrow.svg";
import rightarrow from "../../assets/images/land/right-arrow.svg";

import Slidewithindicator from "./testmonial";
import MobileSlidewithindicator from "./testmonial-mb";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = { name: "", company: "", phone: "", email: "", feedback: "", pages: "" };
        this.testmonial = React.createRef();
        this.testmonial_mb = React.createRef();
        this.feedback = React.createRef();
    }

    componentDidMount() {
        if (document.getElementById('bookdemoclicked').value === "true"){
            document.getElementById('bookdemoclicked').value = "";
            window.location.href='#feedback';
            // this.feedback.current.scrollIntoView();
            document.getElementById("name").focus();
        }else {
            window.scrollTo(0, 0);
        }

    }

    componentWillUnmount() {
    }

    handleChange(){

    }

    handleSubmit(){

    }

    gonext() {
        this.testmonial.current.next();
    }
    goprev() {
        this.testmonial.current.previous();
    }
    gonext_mb() {
        this.testmonial_mb.current.next();
    }
    goprev_mb() {
        this.testmonial_mb.current.previous();
    }
    gotocontactform() {
        document.getElementById('bookdemoclicked').value = "";
        window.location.href='#feedback';
        document.getElementById("name").focus();
    }
    render() {
        return (
            <React.Fragment>
                <div className="main-content-land">
                    <section id="intro" className="">
                        <div className="container">
                            <Row className="align-items-center">
                                <Col xl={5} className="mb-xl-3 mb-5 mobile_col">
                                    <div className="pr-xl-4">
                                        <img className="dot-wave" src={dot} />
                                        <h4 className="land-main-color font-size-16 font-weight-bold mb-3 stick">ACCURAY, SPEED, RELIABILITY, PRIVACY ALL-IN-ONE</h4>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">AI Mortgage And Bank Document <span className="land-main-color">Processing</span> Platform</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">ELLIETRAC is a platform that accelerate your lending business by automatically processing mortgage and bank documents with AI.</h4>
                                        <Button onClick={this.gotocontactform.bind(this)} className="btn bluebtn rightibtn bookbtn"><span className="d-flex align-items-center">Book Demo <i className="ri-arrow-right-line"></i></span></Button>
                                    </div>
                                </Col>
                                <Col xl={7} className="mb-xl-3 mb-5 mobile_col">
                                    <div className="pl-6">
                                        <img className="w-100" src={brain} />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </section>

                    <section id="func" className="">
                        <div className="container">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-50 font-weight-bold mb-3">What Does <span className="land-main-color">EllieTrac Do?</span></h3>
                                    <h4 className="land-gray-100-color font-size-20 mb-5">EllieTrac as an AI Employee, will be your fastest and Smartest employee in your Lending Business </h4>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col-xl-20 mobile_col f_wave"><img className="w-100" src={func1} /></div>
                                <div className="col-xl-20 mobile_col f_wave_odd"><img className="w-100 odd" src={func2} /></div>
                                <div className="col-xl-20 mobile_col f_wave"><img className="w-100" src={func3} /></div>
                                <div className="col-xl-20 mobile_col f_wave_odd"><img className="w-100 odd" src={func4} /></div>
                                <div className="col-xl-20 mobile_col f_wave"><img className="w-100" src={func5} /></div>
                            </Row>
                        </div>
                    </section>

                    <section id="feature">
                        <div className="container feature-container-desktop">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-50 font-weight-bold mb-5">EllieTrac <span className="land-main-color">Features</span></h3>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-5 ">
                                <Col xl={6} className="mb-5 pr-6 ">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-0">Trailblazer <span className="land-gray-color">In</span></h3>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Lending Document Processing</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">EllieTrac presents the world's most accurate DocAI to accelerate your loan processes. Change the loan processing experience for the better! Both borrowers and lenders are looking for ways to digitize, speed-up and improve how loans are processed currently.<br /> <br/> EllieTrac can reduce the time to close a reverse mortgage loan by up to 18 days at less than 10% cost of other AI platforms.</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature1} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-5 ">
                                <Col xl={6} className="mb-5 pr-6">
                                    <div>
                                        <img className="w-100" src={feature2} />
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-0">Capture Clean & Accurate</h3>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Data in Seconds</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">Capture all data your business needs and return the cleaned and accurate data that is good enough for your business</h4>
                                    </div>
                                </Col>

                            </Row>
                            <Row className="align-items-center mb-5 ">

                                <Col xl={6} className="mb-5 pr-6 ">
                                    <div>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Your Business Deserves A Platform That <span className="land-main-color">Learns & Gets</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">Capture all data your business needs and return the cleaned and accurate data that is good enough for your business</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature3} />
                                    </div>
                                </Col>

                            </Row>
                            <Row className="align-items-center mb-5 ">
                                <Col xl={6} className="mb-5 pr-6 ">
                                    <div>
                                        <img className="w-100" src={feature4} />
                                    </div>
                                </Col>

                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-3">EllieTrac <span className="land-gray-color">Prioritizes Your Data Privacy</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">Ellietrac takes your data privacy so seriously. We are working on implementing military grade encryption via a quantum computer. Our encryption will be post quantum resistant. <br/><br/>We feel this is essential to all businesses as a matter of national security. I.e. In October 2019, Google’s 54-qubit quantum processor ‘Sycamore’ completed a task in 200 seconds, the equivalent of which can only be computed in 10,000 years using a state-of-the-art supercomputer.</h4>
                                    </div>
                                </Col>


                            </Row>
                            <Row className="align-items-center ">


                                <Col xl={6} className="mb-5 pr-6">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-3">Easy-To-Integrate <span className="land-gray-color">API With Your Workflow</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">With a well defined API interface, Ellietrac can be easily integrated into your existing workflow.</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature5} />
                                    </div>
                                </Col>


                            </Row>
                        </div>
                        <img className="leftshape1" src={leftshape} />
                        <img className="rightshape1" src={rightshape} />
                        <img className="leftshape2" src={leftshape} />
                        <img className="rightshape2" src={rightshape} />
                        <img className="leftshape3" src={leftshape} />

                        <div className="container feature-container-mobile">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-50 font-weight-bold mb-5">EllieTrac <span className="land-main-color">Features</span></h3>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-5 mobile_col">
                                <Col xl={6} className="mb-5 pr-6 ">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-0">Trailblazer <span className="land-gray-color">In</span></h3>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Lending Document Processing</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">EllieTrac presents the world's most accurate DocAI to accelerate your loan processes. Change the loan processing experience for the better! Both borrowers and lenders are looking for ways to digitize, speed-up and improve how loans are processed currently.<br /> <br/> EllieTrac can reduce the time to close a reverse mortgage loan by up to 18 days at less than 10% cost of other AI platforms.</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature1} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-5 mobile_col">

                                <Col xl={6} className="mb-5 pr-6">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-0">Capture Clean & Accurate</h3>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Data in Seconds</h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">Capture all data your business needs and return the cleaned and accurate data that is good enough for your business</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature2} />
                                    </div>
                                </Col>

                            </Row>
                            <Row className="align-items-center mb-5 mobile_col">

                                <Col xl={6} className="mb-5 pr-6 ">
                                    <div>
                                        <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Your Business Deserves A Platform That <span className="land-main-color">Learns & Gets</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">Capture all data your business needs and return the cleaned and accurate data that is good enough for your business</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature3} />
                                    </div>
                                </Col>

                            </Row>
                            <Row className="align-items-center mb-5 mobile_col">


                                <Col xl={6} className="mb-5 pr-6">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-3">EllieTrac <span className="land-gray-color">Prioritizes Your Data Privacy</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">Ellietrac takes your data privacy so seriously. We are working on implementing military grade encryption via a quantum computer. Our encryption will be post quantum resistant. <br/><br/>We feel this is essential to all businesses as a matter of national security. I.e. In October 2019, Google’s 54-qubit quantum processor ‘Sycamore’ completed a task in 200 seconds, the equivalent of which can only be computed in 10,000 years using a state-of-the-art supercomputer.</h4>
                                    </div>
                                </Col>

                                <Col xl={6} className="mb-5 pl-6 ">
                                    <div>
                                        <img className="w-100" src={feature4} />
                                    </div>
                                </Col>


                            </Row>

                            <Row className="align-items-center mobile_col">


                                <Col xl={6} className="mb-5 pr-6">
                                    <div>
                                        <h3 className="land-main-color font-size-38 font-weight-bold mb-3">Easy-To-Integrate <span className="land-gray-color">API With Your Workflow</span></h3>
                                        <h4 className="land-gray-100-color font-size-18 mb-4">With a well defined API interface, Ellietrac can be easily integrated into your existing workflow.</h4>
                                    </div>
                                </Col>
                                <Col xl={6} className="mb-5 pl-6">
                                    <div>
                                        <img className="w-100" src={feature5} />
                                    </div>
                                </Col>


                            </Row>

                        </div>
                    </section>
                    <section id="ready">
                        <div className="container">
                            <Row>
                                <Col className="">
                                    <div className="ready-bg d-flex align-items-center">
                                        <div className="px-5">
                                            <h3 className="white-color font-size-38 font-weight-bold mb-3 mb-xl-4">Ready to go with Ellietrac?</h3>
                                            <Button onClick={() => {window.location.href = 'https://console.ellietrac.ai/register'}} className="btn whitebtn"><span>Get Started</span></Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section id="testmonial">
                        <div className="container">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="land-gray-color font-size-38 font-weight-bold mb-3">Our <span className="land-main-color">Testimonials</span></h3>
                                    <h4 className="land-gray-100-color font-size-20 mb-5">What our Customers Says</h4>
                                </Col>
                            </Row>

                            <Row className="tm-desktop">
                                <Col className="d-flex justify-content-center ">
                                    <img onClick={this.goprev.bind(this)} className="hand pr-3" src={leftarrow} />
                                    <div className="tm-wrapper ">
                                        <Slidewithindicator ref={this.testmonial} />
                                    </div>
                                    <img onClick={this.gonext.bind(this)} className="hand pl-3" src={rightarrow} />
                                </Col>
                            </Row>
                            <Row className="tm-mobile">
                                <Col className="d-flex justify-content-center ">
                                    <img onClick={this.goprev_mb.bind(this)} className="hand pr-3" src={leftarrow} />
                                    <div className="tm-wrapper">
                                        <MobileSlidewithindicator ref={this.testmonial_mb} />
                                    </div>
                                    <img onClick={this.gonext_mb.bind(this)} className="hand pl-3" src={rightarrow} />
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section id="feedback" ref={this.feedback}>
                        <div className="container">

                            <Row>
                                <Col xl={6} className="mobile_col mb-3">
                                    <h3 className="land-gray-color font-size-38 font-weight-bold mb-5">Accelerate Your Lending Business with <span className="land-main-color">EllieTrac</span></h3>
                                    <AvForm onSubmit={this.handleSubmit.bind(this)} className="">
                                    <Row>
                                        <Col xl={6}>
                                            <div className="mb-4">
                                                <AvField
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Full Name *"
                                                value={this.state.name}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={6}>
                                            <div className="mb-4">
                                                <AvField
                                                name="company"
                                                type="text"
                                                className="form-control"
                                                placeholder="Company Name *"
                                                value={this.state.company}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={6}>
                                            <div className="mb-4">
                                                <AvField
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Email *"
                                                value={this.state.email}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={6}>
                                            <div className="mb-4">
                                                <AvField
                                                name="phone"
                                                type="tel"
                                                className="form-control"
                                                placeholder="Phone Number *"
                                                value={this.state.phone}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div className="mb-4">
                                                <select className="form-control" name='pages' value={this.state.pages} onChange={this.handleChange.bind(this)}>
                                                    <option value="">Pages Processed Per Year*</option>
                                                    <option value="0 - 4,999">0 - 4,999</option>
                                                    <option value="5000 - 19,999">5000 - 19,999</option>
                                                    <option value="20,000-59,999">20,000-59,999</option>
                                                    <option value="60,000-119,999">60,000-119,999</option>
                                                    <option value="120,000-299,999">120,000-299,999</option>
                                                    <option value="300,000-599,999">300,000-599,999</option>
                                                    <option value="600,000-999,999">600,000-999,999</option>
                                                    <option value="1,000,000-1,999,999">1,000,000-1,999,999</option>
                                                    <option value="2,000,000-4,999,999">2,000,000-4,999,999</option>
                                                    <option value="5,000,000-9,999,999">5,000,000-9,999,999</option>
                                                    <option value="10,000,000-19,999,999">10,000,000-19,999,999</option>
                                                    <option value="20,000,000-59,999,999">20,000,000-59,999,999</option>
                                                    <option value="60,000,000+">60,000,000+</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div className="mb-4">
                                                <AvField
                                                name="feedback"
                                                type="textarea"
                                                height="400px"
                                                className="form-control"
                                                placeholder="How Can We Help You?"
                                                value={this.state.feedback}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Button className="btn bluebtn"><span>Submit</span></Button>
                                    </AvForm>

                                </Col>
                                <Col xl={6} className="mobile_col mb-3">
                                    <div>
                                        <img className="w-100" src={feedback} />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </section>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return {
        ...state.Layout
    };
}

export default withRouter(connect(mapStatetoProps, { })(Home));