import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Container,
    Card,
    CardBody
} from "reactstrap";
import {
    private_get,
    private_del,
    private_put,
    alert3,
    open_loading, close_loading, capitalize
} from '../../helpers/fackBackend_Helper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../Tables/datatables.scss";
import {TESTIMONIAL_URL} from "../../helpers/url_helper";

class ADTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 10,
      tableData: [],
      row_id: null

    };
    this.linkFollow = this.linkFollow.bind(this);

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
  }


  cancel_del() {
      document.getElementById('del_confirm_modal').style.display = 'none';
  }
  async confirm_del() {
      document.getElementById('del_confirm_modal').style.display = 'none';
      const url = TESTIMONIAL_URL + this.state.row_id + "/";
      try{
          open_loading();
          const resp = await private_del(url);
          if (resp || resp === '') {
              alert3("Success", 'success');
              this.loadTable();
          }
      } catch (err) {
          alert3(err, 'error');
      }
      close_loading();
  }
  handlecreate() {
    this.props.history.push("/admin/testimonial-create");
  }
  delete(cell, row, row_index) {
      this.setState({row_id: row.id});
      document.getElementById('del_confirm_modal').style.display = 'block';
  }
  edit(cell, row, row_index) {
      this.props.history.push("/admin/testimonial-edit/" + row.id);
  }

  async loadTable() {
    const resp = await private_get(TESTIMONIAL_URL);
    const data = [];
    for (var i=0; i < resp.length; i++) {
      var param = {};
      param = {...resp[i]};
      data.push(param);
    }
    this.setState({tableData: data});
  }

  componentDidMount() {
    this.loadTable();
  }
  linkFollow (cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <a title="Edit the testimonial" onClick={() => {this.edit(cell, row, rowIndex)}} className="me-1 actionbtn purple"><i className="ri-pencil-fill"></i></a>
        <a title="Delete the testimonial" onClick={() => {this.delete(cell, row, rowIndex)}} className="actionbtn red"><i className="ri-delete-bin-fill"></i></a>
      </div>
    )
  }


  render() {

    const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true
    },
    {
      dataField: 'name',
      text: 'Name',
      style: {width: "15%"},
      headerStyle: {width: "15%"},
      sort: true
    }, {
      dataField: 'content',
      text: 'Content',
      style: {width: "45%"},
      classes: 'testimonial-content-col',
      headerStyle: {width: "45%"},
      sort: true
    }, {
      dataField: 'role',
      text: 'Role',
      style: {width: "15%"},
      headerStyle: {width: "15%"},
      sort: true
    }, {
      dataField: 'rate',
      text: 'Rate',
      style: {width: "15%"},
      headerStyle: {width: "15%"},
      sort: true,
    }, {
      dataField: 'action',
      text: 'Action',
      style: {width: "10%"},
      headerStyle: {width: "10%"},
      formatter: this.linkFollow
    }
    ];

    const defaultSorted = [{
      dataField: 'name',
      order: 'asc'
    }];
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
      </span>
    );
    const pageOptions = {
        alwaysShowAllBtns: true,
        prePageText: '<',
        nextPageText: '>',
        withFirstAndLast : true,
        firstPageText: '<<',
        lastPageText: '>>',
        showTotal: true,
        disablePageTitle: true,
        sizePerPage: 20,
        paginationTotalRenderer: customTotal,
        totalSize: this.state.tableData.length,
        sizePerPageList: [{
            text: '5', value: 5
          }, {
            text: '10', value: 10
          }, {
            text: '20', value: 20
          }, {
            text: 'All', value: this.state.tableData.length
          }]
    };
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
              <Row>
                <Col lg={12}>
                      <Card>
                        <CardBody>
                            <Row>
                              <Col lg={12}>
                                  <div className="">
                                      <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField='id'
                                  columns={columns}
                                  data={this.state.tableData}
                                >
                                  {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField='id'
                                      columns={columns}
                                      data={this.state.tableData}
                                      search
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>

                                          <Row>
                                            <Col>
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="ri-search-line appsearch" />
                                                </div>
                                              </div>
                                                <Button onClick={this.handlecreate.bind(this)} className="float-right" color="primary" type="submit">New Testimonial</Button>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col>
                                              <div className="custom-table">
                                                <BootstrapTable
                                                  noDataIndication="Table is Empty"
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  classes={
                                                    "table align-middle table-nowrap"
                                                  }
                                                  headerWrapperClasses={"thead-light"}
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />

                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )
                                      }
                                    </ToolkitProvider>
                                  )
                                  }</PaginationProvider>
                                  </div>
                              </Col>
                            </Row>
                        </CardBody></Card>
                </Col>
              </Row>
              <div className="tbindex" id="del_confirm_modal">
                    <div className="">
                        <div className="modal fade show d-block" role="dialog">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="warning2rect">
                                                <span className="wn2ic1"></span>
                                                <span className="wn2ic2"></span>
                                            </div>
                                            <span className="font-size-16">Are you sure?</span>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.confirm_del.bind(this)} type="button" className="btn btn-primary">OK</button>
                                        <button onClick={this.cancel_del.bind(this)} type="button" className="btn btn-secondary">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ADTestimonial