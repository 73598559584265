import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {
    back_to_dashboard,
    private_get,
    private_post,
    private_put,
    alert3,
    open_loading,
    close_loading,
    goNextStep,
    confirmNextStep,
    getTourState,
    putTourState,
    getLoggedInUser,
    gettourpercent,
    private_del,
    getTourStateValue, confirmInitialStep, timeout
} from '../../helpers/fackBackend_Helper';
import copy from "copy-to-clipboard";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {settourpercent, setdocument, settourstate} from "../../store/layout/actions";
import {ME_URL, MY_MODEL_URL, MY_PROJECT_URL, GROUP_URL} from "../../helpers/url_helper";
import Markdown from 'markdown-to-jsx';
import "github-markdown-css/github-markdown.css";
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import classnames from "classnames";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


class ProjectDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Project", link: "/"},
                {title: "Detail", link: "#"},
            ],
            name: "",
            project_id: "",
            project_desc: "",
            project_uuid: "",
            project_secret: "",
            is_active: false,
            keyclock_pwd_modal: false,
            secret_pwd_modal: false,
            password: "",
            access_token_expiry_unit: "minute",
            access_token_expiry_count: "",
            refresh_token_expiry_unit: "minute",
            refresh_token_expiry_count: "",
            access_token: "",
            refresh_token: "",
            access_token_pwdmodal_backdrop:true,
            access_token_pwdmodal_fade:true,
            menu: false,
            doc_state: 1,
            document: "",
            currnet_lang_code: "",
            langtext: "Python",
            activeTabJustify: "1",
            sizePerPage: 10,
            tableData: [],
            pi_tableData: [],
            invite_modal: false,
            group: [],
            start_onboard_modal: false,
            role: "",
            project_secret_copied: false,
            access_token_copied: false,
            refresh_token_copied: false,
            doc_copied: false

        };
        this.default_access_token_expiry_unit = "minute";
        this.default_access_token_expiry_count = "";
        this.default_refresh_token_expiry_unit = "day";
        this.default_refresh_token_expiry_count = "";
        this.width_portion = {'title': '4', 'content': '8'};
        this.date_units = [{value: "day", name: "Days"}, {value: "hour", name: "Hours"}, {value: "minute", name: "Minutes"}];
        this.handleswitchingstatus = this.handleswitchingstatus.bind(this);
        this.handleRegenerateSecret = this.handleRegenerateSecret.bind(this);
        this.handleAccesstokenExpiry = this.handleAccesstokenExpiry.bind(this);
        this.handleRefreshtokenExpiry = this.handleRefreshtokenExpiry.bind(this);
        this.handleGenerateOuthToken = this.handleGenerateOuthToken.bind(this);
        this.tog_keyclock_pwd_modal = this.tog_keyclock_pwd_modal.bind(this);
        this.tog_secret_pwd_modal = this.tog_secret_pwd_modal.bind(this);
        this.change_access_token_expiry_unit = this.change_access_token_expiry_unit.bind(this);
        this.change_refresh_token_expiry_unit = this.change_refresh_token_expiry_unit.bind(this);
        this.enableAccesstokenExpiry = this.enableAccesstokenExpiry.bind(this);
        this.cancelAccesstokenExpiry = this.cancelAccesstokenExpiry.bind(this);
        this.change_access_token_expiry_count = this.change_access_token_expiry_count.bind(this);
        this.change_refresh_token_expiry_count = this.change_refresh_token_expiry_count.bind(this);
        this.enableRefreshtokenExpiry = this.enableRefreshtokenExpiry.bind(this);
        this.cancelRefreshtokenExpiry = this.cancelRefreshtokenExpiry.bind(this);
        this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
        this.deleteuser = this.deleteuser.bind(this);
        this.assignrole = this.assignrole.bind(this);
        this.enableassignrole = this.enableassignrole.bind(this);
        this.cancelassignrole = this.cancelassignrole.bind(this);
        this.cancelinvitation = this.cancelinvitation.bind(this);
        this.updaterole = this.updaterole.bind(this);
        this.markdownref = React.createRef();
    }
    tog_start_onboard_modal() {
        this.setState(prevState => ({
          start_onboard_modal: !prevState.start_onboard_modal
        }));
        this.removeBodyCss();
    }
    async start_onboard() {
        this.setState({ start_onboard_modal: false });        
        await timeout(1000);
        confirmInitialStep(6);
        goNextStep(this.props.tourtomodeltourobj, 6);
    }
    skip_onboard() {
        this.setState({ start_onboard_modal: false });
    }

    cancel_del_user() {
        document.getElementById('del_confirm_modal').style.display = 'none';
    }
    async confirm_del_user() {
        document.getElementById('del_confirm_modal').style.display = 'none';
        const project_id = this.props.match.params.id;
        const user_id = this.state.row_id;
        const url = MY_PROJECT_URL + project_id + "/members?user=" + user_id;
        try{
            open_loading();
            const resp = await private_del(url);
            if (resp || resp === ''){
                this.loadTable();
                alert3('A member has been removed successfully.', 'success');
            }
        }
        catch(e) {
            alert3(e, 'error');
        }
        close_loading();
    }
    toggle_invite_modal() {
        this.setState(prevState => ({
          invite_modal: !prevState.invite_modal
        }));
        this.removeBodyCss();
    }
    handleChangeGroup(e){
        this.setState({role: e.target.value});
    }
    async enableassignrole(cell, row, rowIndex) {
        document.getElementById('en_ass_' + row.id).style.display = 'none';
        document.getElementById('cn_ass_' + row.id).style.display = 'inline-block';
        document.getElementById('ch_ass_' + row.id).style.display = 'inline-block';
        document.getElementById('role_t_' + row.id).style.display = 'none';
        document.getElementById('role_s_' + row.id).style.display = 'inline-block';

    }
    cancelassignrole(cell, row, rowIndex) {
        document.getElementById('en_ass_' + row.id).style.display = 'inline-block';
        document.getElementById('cn_ass_' + row.id).style.display = 'none';
        document.getElementById('ch_ass_' + row.id).style.display = 'none';
        document.getElementById('role_t_' + row.id).style.display = 'inline-block';
        document.getElementById('role_s_' + row.id).style.display = 'none';
    }


    async assignrole(cell, row, rowIndex) {
        const project_id = this.props.match.params.id;
        const url = MY_PROJECT_URL + project_id + "/members";
        var values = {};
        values.user = row.id;
        values.role = document.getElementById('role_s_' + row.id).value;
        try{
            open_loading();
            const resp = await private_put(url, values);
            if (resp || resp === ''){
                this.cancelassignrole(cell, row, rowIndex);
                this.loadTable();
                alert3("Member's role has been changed successfully.", 'success');
            }
        }
        catch(e) {
            alert3(e, 'error');
        }
        close_loading();

    }
    deleteuser(cell, row, rowIndex) {
        document.getElementById('del_confirm_modal').style.display = 'block';
        this.setState({row_id: row.id});
    }

    cancelinvitation(cell, row, rowIndex) {
        document.getElementById('pi_del_confirm_modal').style.display = 'block';
        this.setState({row_id: row.id});
    }
    async confirm_cancel_pi() {
        document.getElementById('pi_del_confirm_modal').style.display = 'none';
        const project_id = this.props.match.params.id;
        const pi_id = this.state.row_id;
        const data = {data: {id: pi_id}};
        const url = MY_PROJECT_URL + project_id + "/invitations";
        try{
            open_loading();
            const resp = await private_del(url, data);
            if (resp || resp === ''){
                this.loadPendingInvitation();
                alert3('An invitation has been cancelled successfully.', 'success');
            }
        }
        catch(e) {
            alert3(e, 'error');
        }
        close_loading();
    }
    cancel_cancel_pi_user() {
        document.getElementById('pi_del_confirm_modal').style.display = 'none';
    }
    async updaterole(cell, row, rowIndex) {
        const project_id = this.props.match.params.id;
        const url = MY_PROJECT_URL + project_id + "/invitations";
        var values = {};
        values.id = row.id;
        values.role = document.getElementById('role_s_' + row.id).value;
        try{
            open_loading();
            const resp = await private_put(url, values);
            if (resp || resp === ''){
                this.cancelassignrole(cell, row, rowIndex);
                this.loadPendingInvitation();
                alert3("The invited user's role has been changed successfully.", 'success');
            }
        }
        catch(e) {
            alert3(e, 'error');
        }
        close_loading();
    }
    async sendinvitationemail(e, values) {
        const project_id = this.props.match.params.id;
        values.user = values.email;
        values.role = this.state.role;
        const url = MY_PROJECT_URL + project_id + "/invitations";
        try{
            const resp = await private_post(url, values);
            if (resp || resp === ''){
                alert3('Email has been sent successfully.', 'success');
            }
        }
        catch(e) {
            alert3(e, 'error');
        }
        this.setState({invite_modal: false});

    }
    async toggleCustomJustified(tab) {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab
			});
		}
		if (tab === '2') {
		    try{
		        this.loadTable();
                this.loadPendingInvitation();
		    } catch(err) {
                alert3(err, 'error');
            }
        }
	}

	async loadPendingInvitation() {
        const project_id = this.props.match.params.id;
        const url = MY_PROJECT_URL + project_id + "/invitations";
        const resp = await private_get(url);
        const data = [];
        for (var i=0; i < resp.length; i++) {
          var param = {};
          param.id = resp[i][0];
          param.user = resp[i][1];
          param.role_id = resp[i][2];
          param.role = resp[i][3];
          data.push(param);
        }
        this.setState({pi_tableData: data});

    }
    async loadTable() {
        const project_id = this.props.match.params.id;
        const url = MY_PROJECT_URL + project_id + "/members";
        const resp = await private_get(url);
        const data = [];
        const me = getLoggedInUser();
        for (var i=0; i < resp.length; i++) {
          var param = {};
          param.id = resp[i][0];
          param.name = resp[i][1];
          param.role_id = resp[i][2];
          param.role = resp[i][3];
          data.push(param);
          if (resp[i][0] === me.id) {
              this.setState({my_role_id: resp[i][2]});
          }
        }

        if (this.state.group.length > 0) {
            this.setState({role: this.state.group[0].id});
        }
        this.setState({tableData: data});

    }

    async get_group() {
        var group = await private_get(GROUP_URL);
        var newgroup = [];
        for (var j = 0; j < group.length; j++) {
            if (group[j].name === 'project_owner'){
                continue;
            }
            if (group[j].name === 'project_manager'){
                group[j].rname = "Project Manager";
            }
            if (group[j].name === 'project_member'){
                group[j].rname = "Project Member";
            }
            newgroup.push(group[j]);
        }
        newgroup.sort(function(a, b){return a.id - b.id});
        this.setState({group: newgroup});
    }
    rolepiFollow (cell, row, rowIndex, formatExtraData) {
        const role_t_id = "role_t_" + row.id;
        const role_s_id = "role_s_" + row.id;
        var role_rname = '';
        if (row.role === 'project_owner'){
            role_rname = "Project Owner";
        }
        if (row.role === 'project_manager'){
            role_rname = "Project Manager";
        }
        if (row.role === 'project_member'){
            role_rname = "Project Member";
        }
        return (
            <div>
                <div id={role_t_id}>{role_rname}</div>
                <select id={role_s_id} className="form-control d-n-nimp s-in-td">
                    {this.state.group.map(o =>
                        <option value={o.id} key={o.id}>{o.rname}</option>
                    )}
                </select>
            </div>
        )
    }
    roleFollow (cell, row, rowIndex, formatExtraData) {
        const role_t_id = "role_t_" + row.id;
        const role_s_id = "role_s_" + row.id;
        var role_rname = '';
        if (row.role === 'project_owner'){
            role_rname = "Project Owner";
        }
        if (row.role === 'project_manager'){
            role_rname = "Project Manager";
        }
        if (row.role === 'project_member'){
            role_rname = "Project Member";
        }
        return (
            <div>
                <div id={role_t_id}>{role_rname}</div>
                <select id={role_s_id} className="form-control d-n-nimp s-in-td">
                    {this.state.group.map(o =>
                        <option value={o.id} key={o.id}>{o.rname}</option>
                    )}
                </select>
            </div>
        )
    }
    linkpiFollow(cell, row, rowIndex, formatExtraData) {
        const en_a_id = "en_ass_" + row.id;
        const cn_a_id = "cn_ass_" + row.id;
        const ch_a_id = "ch_ass_" + row.id;
        return (
            <div>
              <a id={en_a_id} title="Enable to change the invited user's role" onClick={() => {this.enableassignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple"><i className="ri-pencil-fill"></i></a>
              <a id={ch_a_id} title="Change the invited user's role" onClick={() => {this.updaterole(cell, row, rowIndex)}} className="me-2 actionbtn purple d-n-nimp"><i className=" ri-check-line"></i></a>
              <a id={cn_a_id} title="Cancel to change the invited user's role" onClick={() => {this.cancelassignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple d-n-nimp"><i className=" ri-delete-back-2-line"></i></a>
              <a title="Cancel the invitation" onClick={() => {this.cancelinvitation(cell, row, rowIndex)}} className="actionbtn red" ><i className="ri-delete-bin-6-line"></i></a>
            </div>
        )
    }

    linkFollow (cell, row, rowIndex, formatExtraData) {
        const me = getLoggedInUser();
        const en_a_id = "en_ass_" + row.id;
        const cn_a_id = "cn_ass_" + row.id;
        const ch_a_id = "ch_ass_" + row.id;
        if (this.state.my_role_id === 1) {
            if (row.id === me.id) return;
            return (
                <div>
                  <a id={en_a_id} title="Enable to change the member's role" onClick={() => {this.enableassignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple"><i className="ri-pencil-fill"></i></a>
                  <a id={ch_a_id} title="Change the member's role" onClick={() => {this.assignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple d-n-nimp"><i className=" ri-check-line"></i></a>
                  <a id={cn_a_id} title="Cancel to change the member's role" onClick={() => {this.cancelassignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple d-n-nimp"><i className=" ri-delete-back-2-line"></i></a>
                  <a title="Remove the member" onClick={() => {this.deleteuser(cell, row, rowIndex)}} className="actionbtn red" ><i className="ri-delete-bin-6-line"></i></a>
                </div>
            )
        }
        if (this.state.my_role_id === 2) {
            if (row.role_id === 1) return;
            if (row.id === me.id) {
                return (
                    <div>
                      <a title="Leave the project" onClick={() => {this.deleteuser(cell, row, rowIndex)}} className="actionbtn red" ><i className="ri-delete-bin-6-line"></i></a>
                    </div>
                )
            }
            return (
                <div>
                  <a id={en_a_id} title="Enable to change the member's role" onClick={() => {this.enableassignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple"><i className="ri-pencil-fill"></i></a>
                  <a id={ch_a_id} title="Change the member's role" onClick={() => {this.assignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple d-n-nimp"><i className=" ri-check-line"></i></a>
                  <a id={cn_a_id} title="Cancel to change the member's role" onClick={() => {this.cancelassignrole(cell, row, rowIndex)}} className="me-2 actionbtn purple d-n-nimp"><i className=" ri-delete-back-2-line"></i></a>
                  <a title="Remove the member" onClick={() => {this.deleteuser(cell, row, rowIndex)}} className="actionbtn red" ><i className="ri-delete-bin-6-line"></i></a>
                </div>
            )
        }
        if (this.state.my_role_id === 3) {
            if (row.role_id === 1) return;
            if (row.role_id === 2) return;
            if (row.id === me.id) {
                return (
                    <div>
                      <a title="Leave the project" onClick={() => {this.deleteuser(cell, row, rowIndex)}} className="actionbtn red" ><i className="ri-delete-bin-6-line"></i></a>
                    </div>
                )
            }
            if (row.role_id === 3) return;
        }

  }
	handleinvitecustomer() {
        this.toggle_invite_modal();
    }
    async select_lang(lib) {
        const project_id = this.props.match.params.id;
        var url = MY_PROJECT_URL + project_id + "/helper?type=code";
        if (lib === 'python') {
            if (this.state.langtext === "Python") return;
            this.setState({langtext: "Python"});
            url += "&lang=py";
        }
        if (lib === 'php') {
            if (this.state.langtext === "PHP") return;
            this.setState({langtext: "PHP"});
            url += "&lang=php";
        }
        if (lib === 'nodejs') {
            if (this.state.langtext === "Node.js") return;
            this.setState({langtext: "Node.js"});
            url += "&lang=js";
        }

        var hresp = await private_get(url);
        this.setState({document: hresp.doc});
        var currnet_lang_code = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;

        this.markdownref.current.querySelectorAll('pre code').forEach((block) => {
          hljs.highlightElement(block);
        });

        var code_text = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;
        this.setState({currnet_lang_code: currnet_lang_code});
        const lines = code_text.split(/\n/);

        var html = "";
        for(var k = 0; k < lines.length; k++) {
            var line = '<span class="line-number">' + (k + 1) + '</span>'  + lines[k] + '\n';
            html += line;
        }
        this.markdownref.current.querySelectorAll('pre code')[0].innerHTML = html;
    }
    async toggletab(i) {
        if (this.state.doc_state === 1 && i === 1) return;
        if (this.state.doc_state === 2 && i === 2) return;
        var type = "about";
        if (i === 2) {
            type = "code";
            if (this.state.langtext === 'Python') {
                type += "&lang=py";
            }
            if (this.state.langtext === 'PHP') {
                type += "&lang=php";
            }
            if (this.state.langtext === 'Node.js') {
                type += "&lang=js";
            }
        }
        const project_id = this.props.match.params.id;
        const url = MY_PROJECT_URL + project_id + "/helper?type=" + type;
        var hresp = await private_get(url);

        this.setState({doc_state: i});
        this.setState({document: hresp.doc});
        var currnet_lang_code = "";
        if (i === 2){
            currnet_lang_code = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;
        }
        this.markdownref.current.querySelectorAll('pre code').forEach((block) => {
          hljs.highlightElement(block);
        });
        if (i === 1) return;

        var code_text = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;
        this.setState({currnet_lang_code: currnet_lang_code});
        const lines = code_text.split(/\n/);

        var html = "";
        for(var k = 0; k < lines.length; k++) {
            var line = '<span class="line-number">' + (k + 1) + '</span>'  + lines[k] + '\n';
            html += line;
        }
        this.markdownref.current.querySelectorAll('pre code')[0].innerHTML = html;
    }
    async copycodeUrl() {
        copy(this.state.currnet_lang_code);
        this.setState({doc_copied: true});
        await timeout(3000);
        this.setState({doc_copied: false});
    }
    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    async copytext() {
        if (this.state.project_secret_copied) {
            return;
        }
        var copyText = document.getElementById("project_secret");
        copy(copyText.value);
        this.setState({project_secret_copied: true});
        await timeout(3000);
        this.setState({project_secret_copied: false});
    }
    async copy_access_token() {
        if (this.state.access_token === '') {
            this.tog_keyclock_pwd_modal();
        } else {
            if (this.state.access_token_copied) {
                return;
            }
            var copyText = document.getElementById("access_token");
            copy(copyText.value);
            confirmNextStep(3);
            goNextStep(this.props.projecttourobj, 3);
            this.setState({access_token_copied: true});
            await timeout(3000);
            this.setState({access_token_copied: false});
        }
    }
    async copy_refresh_token() {
        if (this.state.refresh_token === '') {
            this.tog_keyclock_pwd_modal();
        } else {
            if (this.state.refresh_token_copied) {
                return;
            }
            var copyText = document.getElementById("refresh_token");
            copy(copyText.value);
            const state = confirmNextStep(3);
            if (state) {
                this.props.projecttourobj.introJs.exit();

                /* read my tour state from database and update */
                const user = getLoggedInUser();
                const url = ME_URL + user.id + '/';
                const user_info = await private_get(url);
                var tour = user_info.tour;
                var values = {};
                if (tour !== '') {
                    values = tour;
                }
                values.step2 = 1;
                var param = {
                    tour: values
                };
                const resp = await private_put(url, param);
                if (resp) {
                    this.props.settourpercent(gettourpercent(values));
                    var _tour_state = getTourStateValue(values);
                    this.props.settourstate(_tour_state);
                }

                this.tog_start_onboard_modal();
            }
            this.setState({refresh_token_copied: true});
            await timeout(3000);
            this.setState({refresh_token_copied: false});
        }
    }
    cancelRefreshtokenExpiry() {
        document.getElementById("cancelRefreshtokenExpirybtn").style.display = 'none';
        document.getElementById("enableRefreshtokenExpirybtn").style.display = 'inline-block';
        document.getElementById("handleRefreshtokenExpirybtn").style.display = 'none';
        document.getElementById('refresh_token_expiry_unit').setAttribute("disabled","disabled");
        document.getElementById('refresh_token_expiry_count').setAttribute("disabled","disabled");
        this.setState({refresh_token_expiry_count: this.default_refresh_token_expiry_count});
        this.setState({refresh_token_expiry_unit: this.default_refresh_token_expiry_unit});
    }
    enableRefreshtokenExpiry() {
        document.getElementById("cancelRefreshtokenExpirybtn").style.display = 'inline-block';
        document.getElementById("enableRefreshtokenExpirybtn").style.display = 'none';
        document.getElementById("handleRefreshtokenExpirybtn").style.display = 'inline-block';
        document.getElementById('refresh_token_expiry_unit').removeAttribute("disabled");
        document.getElementById('refresh_token_expiry_count').removeAttribute("disabled");
    }
    cancelAccesstokenExpiry(){
        document.getElementById("cancelAccesstokenExpirybtn").style.display = 'none';
        document.getElementById("enableAccesstokenExpirybtn").style.display = 'inline-block';
        document.getElementById("handleAccesstokenExpirybtn").style.display = 'none';
        document.getElementById('access_token_expiry_unit').setAttribute("disabled","disabled");
        document.getElementById('access_token_expiry_count').setAttribute("disabled","disabled");
        this.setState({access_token_expiry_count: this.default_access_token_expiry_count});
        this.setState({access_token_expiry_unit: this.default_access_token_expiry_unit});
    }

    enableAccesstokenExpiry() {
        document.getElementById("cancelAccesstokenExpirybtn").style.display = 'inline-block';
        document.getElementById("enableAccesstokenExpirybtn").style.display = 'none';
        document.getElementById("handleAccesstokenExpirybtn").style.display = 'inline-block';
        document.getElementById('access_token_expiry_unit').removeAttribute("disabled");
        document.getElementById('access_token_expiry_count').removeAttribute("disabled");
    }

    change_refresh_token_expiry_unit(e) {
        this.setState({refresh_token_expiry_unit: e.target.value});
    }
    change_access_token_expiry_unit(e) {
        this.setState({access_token_expiry_unit: e.target.value});
    }
    change_access_token_expiry_count(e) {
        this.setState({access_token_expiry_count: e.target.value});
    }
    change_refresh_token_expiry_count(e) {
        this.setState({refresh_token_expiry_count: e.target.value});
    }
    movetodash(){
        back_to_dashboard(this.props.history);
    }
    toggleRegenerateTokenModal(){

        const ux_state = confirmNextStep(3);
        if (ux_state){
            const state = getTourState(3);
            this.props.projecttourobj.introJs.exit();
            putTourState(state, 3);
        }
        this.tog_keyclock_pwd_modal();
    }
    onexit_keyclock_pwd_modal() {
        const state = getTourState(3);
        if (state) {
            this.props.projecttourobj.introJs.exit();
        }
    }
    tog_keyclock_pwd_modal() {
        this.setState(prevState => ({
          keyclock_pwd_modal: !prevState.keyclock_pwd_modal
        }));
        this.removeBodyCss();
    }
    tog_secret_pwd_modal() {
        this.setState(prevState => ({
          secret_pwd_modal: !prevState.secret_pwd_modal
        }));
        this.removeBodyCss();
    }
    removeBodyCss(){
        document.body.classList.add("no_padding");
    }
    async handleGenerateOuthToken(event, values){
        const url = MY_PROJECT_URL + this.props.match.params.id + "/tokens/";
        const data = {
            password: values.password
        };
        try{
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                alert3("Success", 'success');
                this.setState({
                    access_token: resp.access_token,
                    refresh_token: resp.refresh_token
                });


                close_loading();
                const state = getTourState(3);
                this.setState({ keyclock_pwd_modal: false });
                this.submitouthtokenuxId = setInterval(() => {
                    if (document.getElementById('ux_copy_access_token_button') !== null) {
                        putTourState(state, 3);
                        goNextStep(this.props.projecttourobj, 3);
                        clearInterval(this.submitouthtokenuxId);
                    }
                }, 500);

            }

        } catch (err) {
            alert3(err, 'error');
            this.setState({ keyclock_pwd_modal: false });
            close_loading();
        }
    }
    dateunittosecond(unit) {
        if (unit === 'day') {
            return 86400;
        } else if (unit === 'hour') {
            return 3600;
        } else if (unit === 'minute') {
            return 60;
        } else {
            return 0;
        }
    }
    async handleRefreshtokenExpiry() {
        const url = MY_PROJECT_URL + this.props.match.params.id + "/refresh_token_lifespan/";
        if (this.state.refresh_token_expiry_count === '') {
            alert3('Expiry time interval can not be blank', 'warning');
            return;
        }
        const refresh_token_expiry_count = parseInt(this.state.refresh_token_expiry_count);
        if (refresh_token_expiry_count === 0) {
            alert3('Expiry time interval can not be zero', 'warning');
            return;
        }
        const lifespan = this.dateunittosecond(this.state.refresh_token_expiry_unit) * refresh_token_expiry_count;
        const data = {
            lifespan: lifespan.toString()
        };
        try{
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                console.log(resp);
                alert3("Success", 'success');
                const second = parseInt(resp.lifespan);
                const refresh_token_expiry_count = second / this.dateunittosecond(this.state.refresh_token_expiry_unit);
                this.default_refresh_token_expiry_count = refresh_token_expiry_count;
                this.default_refresh_token_expiry_unit = this.state.refresh_token_expiry_unit;
                this.cancelRefreshtokenExpiry();
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async handleAccesstokenExpiry() {
        const url = MY_PROJECT_URL + this.props.match.params.id + "/access_token_lifespan/";
        if (this.state.access_token_expiry_count === '') {
            alert3('Expiry time interval can not be blank', 'warning');
            return;
        }
        const access_token_expiry_count = parseInt(this.state.access_token_expiry_count);
        if (access_token_expiry_count === 0) {
            alert3('Expiry time interval can not be zero', 'warning');
            return;
        }
        const lifespan = this.dateunittosecond(this.state.access_token_expiry_unit) * access_token_expiry_count;
        const data = {
            lifespan: lifespan.toString()
        };
        try{
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                console.log(resp);
                alert3("Success", 'success');
                const second = parseInt(resp.lifespan);
                const access_token_expiry_count = second / this.dateunittosecond(this.state.access_token_expiry_unit);
                this.default_access_token_expiry_count = access_token_expiry_count;
                this.default_access_token_expiry_unit = this.state.access_token_expiry_unit;
                this.cancelAccesstokenExpiry();
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }

    async handleswitchingstatus() {
        const url = MY_PROJECT_URL + this.props.match.params.id + "/";
        const data = {
            is_active: !this.state.is_active
        };
        try{
            open_loading();
            const resp = await private_put(url, data);
            if (resp) {
                this.setState({
                    is_active: !this.state.is_active
                });
                alert3("Success", 'success');
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }

    async handleRegenerateSecret(e, values) {
        const url = MY_PROJECT_URL + this.props.match.params.id + "/secret/";
        try{
            const data = {
                password: values.password
            };
            open_loading();
            const resp = await private_post(url, data);
            if (resp) {
                this.setState({project_secret: resp.secret});
                alert3("Success", 'success');
            }
        } catch (err) {
            alert3(err, 'error');
        }
        this.setState({ secret_pwd_modal: false });
        close_loading();
    }

    async componentDidMount() {
        try {
            open_loading();
            this.props.setdocument("...");
            this.cancelAccesstokenExpiry();
            this.cancelRefreshtokenExpiry();
            const project_id = this.props.match.params.id;
            const url = MY_PROJECT_URL + project_id + "/";
            var resp = await private_get(url);
            if (resp) {
                this.setState({
                    name: resp.name,
                    project_id: resp.id,
                    project_desc: resp.desc,
                    project_uuid: resp.uname,
                    project_secret: resp.secret,
                    is_active: resp.is_active
                });
            }
            const access_token_lifespan_url = MY_PROJECT_URL + project_id + "/access_token_lifespan/";
            resp = await private_get(access_token_lifespan_url);
            if (resp) {
                if (resp.lifespan !== 0) {
                    var access_token_lifespan = this.lifespan_to_unit(resp.lifespan);
                    this.setState({
                        access_token_expiry_unit: access_token_lifespan.unit,
                        access_token_expiry_count: access_token_lifespan.count
                    });
                }
            }
            const refresh_token_lifespan_url = MY_PROJECT_URL + project_id + "/refresh_token_lifespan/";
            resp = await private_get(refresh_token_lifespan_url);
            if (resp) {
                if (resp.lifespan !== 0) {
                    var refresh_token_lifespan = this.lifespan_to_unit(resp.lifespan);
                    this.setState({
                        refresh_token_expiry_unit: refresh_token_lifespan.unit,
                        refresh_token_expiry_count: refresh_token_lifespan.count
                    });
                }
            }
            this.default_access_token_expiry_unit = this.state.access_token_expiry_unit;
            this.default_access_token_expiry_count = this.state.access_token_expiry_count;
            this.default_refresh_token_expiry_unit = this.state.refresh_token_expiry_unit;
            this.default_refresh_token_expiry_count = this.state.refresh_token_expiry_count;
            this.get_group();
            const hurl = MY_PROJECT_URL + project_id + "/helper?type=about";
            var hresp = await private_get(hurl);
            this.setState({document: hresp.doc});
            this.markdownref.current.querySelectorAll('pre code').forEach((block) => {
              hljs.highlightElement(block);
            });
            close_loading();
            goNextStep(this.props.projecttourobj, 3);

        }
        catch (e) {
            alert3(e, 'error');
            close_loading();
        }
    }

    lifespan_to_unit(seconds) {
        var res = seconds / 86400;
        if (res >= 1) {
            return {"unit": "day", "count": res};
        }
        res = seconds / 3600;
        if (res >= 1) {
            return {"unit": "hour", "count": res};
        }
        res = seconds / 60;
        return {"unit": "minute", "count": res};
    }
    render() {
        const columns = [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        },
        {
          dataField: 'name',
          text: 'Name',
            style: {width: "40%"},
          headerStyle: {width: "40%"},
          sort: true
        }, {
          dataField: 'role',
          text: 'Role',
                style: {width: "40%"},
          headerStyle: {width: "40%"},
          sort: true,
          formatter: this.roleFollow.bind(this)
        }, {
          dataField: 'action',
          text: 'Action',
                style: {width: "20%"},
          headerStyle: {width: "20%"},
          formatter: this.linkFollow.bind(this)
        },
        ];
        const pi_columns = [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        },
        {
          dataField: 'user',
          text: 'Name',
            style: {width: "40%"},
          headerStyle: {width: "40%"},
          sort: true
        }, {
          dataField: 'role',
          text: 'Role',
                style: {width: "40%"},
          headerStyle: {width: "40%"},
          sort: true,
          formatter: this.rolepiFollow.bind(this)
        }, {
          dataField: 'action',
          text: 'Action',
                style: {width: "20%"},
          headerStyle: {width: "20%"},
          formatter: this.linkpiFollow.bind(this)
        },
        ];
        const defaultSorted = [{
          dataField: 'name',
          order: 'asc'
        }];
        const customTotal = (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Showing { from } to { to } of { size } Results
          </span>
        );
        const { SearchBar } = Search;
        const pageOptions = {
            alwaysShowAllBtns: true,
            prePageText: '<',
            nextPageText: '>',
            withFirstAndLast : true,
            firstPageText: '<<',
            lastPageText: '>>',
            showTotal: true,
            disablePageTitle: true,
            sizePerPage: 20,
            paginationTotalRenderer: customTotal,
            totalSize: this.state.tableData.length,
            sizePerPageList: [{
                text: '5', value: 5
              }, {
                text: '10', value: 10
              }, {
                text: '20', value: 20
              }, {
                text: 'All', value: this.state.tableData.length
              }]
        };
        const pi_pageOptions = {
            alwaysShowAllBtns: true,
            prePageText: '<',
            nextPageText: '>',
            withFirstAndLast : true,
            firstPageText: '<<',
            lastPageText: '>>',
            showTotal: true,
            disablePageTitle: true,
            sizePerPage: 20,
            paginationTotalRenderer: customTotal,
            totalSize: this.state.pi_tableData.length,
            sizePerPageList: [{
                text: '5', value: 5
              }, {
                text: '10', value: 10
              }, {
                text: '20', value: 20
              }, {
                text: 'All', value: this.state.pi_tableData.length
              }]
        };
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row className="mb-5">
                        <Col>
                            <Button id="ux_projectdetail_to_dash" color="primary" type="button" onClick={this.movetodash.bind(this)}>Back to dashboard</Button>
                        </Col>
                    </Row>
                     <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: this.state.activeTabJustify === "1"
                                })}
                                onClick={() => {
                                    this.toggleCustomJustified("1");
                                }}
                            >
                                <span className="d-none d-sm-block">Project Details</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: this.state.activeTabJustify === "2"
                                })}
                                onClick={() => {
                                    this.toggleCustomJustified("2");
                                }}
                            >
                                <span className="d-none d-sm-block">Members</span>
                            </NavLink>
                        </NavItem>
                         

                    </Nav>
                    <TabContent activeTab={this.state.activeTabJustify}>
                        <TabPane tabId="1" className="p-3">
                            <Row className="mt-3">
                                <Col xl={7}>
                                    <h4 className="card-title-x">Project Information</h4>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs={this.width_portion.title}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Project Name</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        <span>: {this.state.name}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={this.width_portion.title}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Project Description</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        <span>: {this.state.project_desc}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>

                                    <h4 className="card-title-x">Credentials</h4>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs={this.width_portion.title}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Project ID</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        <span>: {this.state.project_uuid}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={this.width_portion.title} className="d-flex align-items-center">
                                                    <div className="mb-3">
                                                        <Label className="form-label">Project Secret</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        <InputGroup>
                                                          <input type="text" id="project_secret" className="form-control" value={this.state.project_secret} readOnly/>
                                                          <span className="input-group-append" onClick={this.copytext.bind(this)}>
                                                            <Button type="button" color="primary">
                                                                {!this.state.project_secret_copied &&
                                                                    <i className="ri-file-copy-line" />
                                                                }
                                                                {this.state.project_secret_copied &&
                                                                    <i className="ri-check-line" />
                                                                }
                                                            </Button>
                                                          </span>
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={this.width_portion.title}></Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        <Button onClick={this.tog_secret_pwd_modal} color="primary" type="button">Regenerate</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <h4 className="card-title-x">Oauth Tokens</h4>
                                    <Card>
                                        <CardBody>
                                            <Row className="align-items-center mb-3">
                                                <Col xs={this.width_portion.title}>
                                                    <div className="">
                                                        <Label className="form-label">Access Token Lifespan</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div>
                                                        <AvForm className="needs-validation d-inline-block">

                                                        <div className="d-inline-block">
                                                            <AvField
                                                            name="access_token_expiry_count"
                                                            type="number"
                                                            className="form-control expiry_item me-1"
                                                            id="access_token_expiry_count"
                                                            onChange={this.change_access_token_expiry_count}
                                                            value={this.state.access_token_expiry_count}
                                                        />
                                                        </div>
                                                            <select className="form-control expiry_item me-1" name='access_token_expiry_unit' id='access_token_expiry_unit' value={this.state.access_token_expiry_unit} onChange={this.change_access_token_expiry_unit}>
                                                          {this.date_units.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                                        </select>
                                                        </AvForm>
                                                        <Button id="enableAccesstokenExpirybtn" onClick={this.enableAccesstokenExpiry} color="primary" type="button">Edit</Button>
                                                        <Button className="me-1" id="handleAccesstokenExpirybtn" onClick={this.handleAccesstokenExpiry} color="primary" type="button">Set expiry</Button>
                                                        <Button id="cancelAccesstokenExpirybtn" onClick={this.cancelAccesstokenExpiry} color="primary" type="button">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="align-items-center mb-3">
                                                <Col xs={this.width_portion.title}>
                                                    <div className="">
                                                        <Label className="form-label">Refresh Token Lifespan</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div>
                                                        <AvForm className="needs-validation d-inline-block">

                                                        <div className="d-inline-block">
                                                            <AvField
                                                            name="refresh_token_expiry_count"
                                                            type="number"
                                                            onChange={this.change_refresh_token_expiry_count}
                                                            className="form-control expiry_item me-1"
                                                            id="refresh_token_expiry_count"
                                                            value={this.state.refresh_token_expiry_count}
                                                        />
                                                        </div>
                                                            <select className="form-control expiry_item me-1" name='refresh_token_expiry_unit' id='refresh_token_expiry_unit' value={this.state.refresh_token_expiry_unit} onChange={this.change_refresh_token_expiry_unit}>
                                                          {this.date_units.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                                        </select>
                                                        </AvForm>
                                                        <Button id="enableRefreshtokenExpirybtn" onClick={this.enableRefreshtokenExpiry} color="primary" type="button">Edit</Button>
                                                        <Button id="handleRefreshtokenExpirybtn" className="me-1" onClick={this.handleRefreshtokenExpiry} color="primary" type="button">Set Expiry</Button>
                                                        <Button id="cancelRefreshtokenExpirybtn" onClick={this.cancelRefreshtokenExpiry} color="primary" type="button">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={this.width_portion.title}>
                                                    <div className="mb-3">
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        <Button id="ux_regenerate_token" onClick={this.toggleRegenerateTokenModal.bind(this)} color="primary" type="button">Regenerate</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={this.width_portion.title}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Access Token</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        {this.state.access_token !== "" &&
                                                        <InputGroup>
                                                            <input type="text" id="access_token" className="form-control"
                                                                   value={this.state.access_token} readOnly/>
                                                            <span className="input-group-append"
                                                                  onClick={this.copy_access_token.bind(this)}>
                                                            <Button id="ux_copy_access_token_button" type="button" color="primary">
                                                                {!this.state.access_token_copied &&
                                                                    <i className="ri-file-copy-line"/>
                                                                }
                                                                {this.state.access_token_copied &&
                                                                    <i className="ri-check-line"/>
                                                                }                                                                
                                                            </Button>
                                                          </span>
                                                        </InputGroup>
                                                        }
                                                        {this.state.access_token === "" &&
                                                        <InputGroup>
                                                            <input type="password" className="form-control"
                                                                   value="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" readOnly/>
                                                            <span className="input-group-append"
                                                                  onClick={this.copy_access_token.bind(this)}>
                                                            <Button id="ux_copy_access_token_button" type="button" color="primary"><i
                                                                className="ri-file-copy-line"/>
                                                            </Button>
                                                          </span>
                                                        </InputGroup>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={this.width_portion.title}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="uuid">Refresh token</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={this.width_portion.content}>
                                                    <div className="mb-3">
                                                        {this.state.refresh_token !== "" &&
                                                        <InputGroup>
                                                            <input type="text" id="refresh_token" className="form-control"
                                                                   value={this.state.refresh_token} readOnly/>
                                                            <span className="input-group-append"
                                                                  onClick={this.copy_refresh_token.bind(this)}>
                                                            <Button id="ux_copy_refresh_token_button" type="button" color="primary">
                                                            {!this.state.refresh_token_copied &&
                                                                <i className="ri-file-copy-line"/>
                                                            }
                                                            {this.state.refresh_token_copied &&
                                                                <i className="ri-check-line"/>
                                                            }
                                                            </Button>
                                                          </span>
                                                        </InputGroup>
                                                        }
                                                        {this.state.refresh_token === "" &&
                                                        <InputGroup>
                                                            <input type="password" className="form-control"
                                                                   value="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" readOnly/>
                                                            <span className="input-group-append"
                                                                  onClick={this.copy_refresh_token.bind(this)}>
                                                            <Button type="button" color="primary"><i
                                                                className="ri-file-copy-line"/>
                                                            </Button>
                                                          </span>
                                                        </InputGroup>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                              <Col xl={5}>
                                  <h4 className="card-title-x">Documentation</h4>
                                  <Card className="project-doc">
                                      <CardBody>
                                          <div className="d-header d-flex justify-content-between">
                                              {this.state.doc_state === 1 &&
                                                  <div className="d-flex doc-tab">
                                                      <span onClick={this.toggletab.bind(this, 1)} className="hand active">About</span>
                                                      <span onClick={this.toggletab.bind(this, 2)} className="hand">Code</span>
                                                  </div>
                                              }
                                              {this.state.doc_state === 2 &&
                                                  <div className="d-flex doc-tab">
                                                      <span onClick={this.toggletab.bind(this, 1)} className="hand">About</span>
                                                      <span onClick={this.toggletab.bind(this, 2)} className="hand active">Code</span>
                                                  </div>
                                              }
                                              {this.state.doc_state === 2 &&
                                                  <div className="d-flex align-items-center" id="lang">
                                                      <Dropdown isOpen={this.state.menu} toggle={this.toggle.bind(this)}
                                                                className="d-inline-block user-dropdown me-2">
                                                          <DropdownToggle tag="button" className="btn selectlgbtn">
                                                              <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                                                              <span className="d-none d-xl-inline-block ms-1 text-transform">{this.state.langtext}</span>
                                                          </DropdownToggle>
                                                          <DropdownMenu className="dropdown-menu-end dropdown-doc">
                                                              <DropdownItem key={"python"}
                                                                            onClick={this.select_lang.bind(this, "python")}>Python</DropdownItem>
                                                              <DropdownItem key={"nodejs"}
                                                                            onClick={this.select_lang.bind(this, "nodejs")}>Node.js</DropdownItem>
                                                              <DropdownItem key={"php"}
                                                                            onClick={this.select_lang.bind(this, "php")}>PHP</DropdownItem>

                                                          </DropdownMenu>
                                                      </Dropdown>
                                                      <div className="d-flex">
                                                        {!this.state.doc_copied &&
                                                            <i onClick={this.copycodeUrl.bind(this)} className="hand ri-file-copy-line"></i>
                                                        }
                                                        {this.state.doc_copied &&
                                                            <i className="hand ri-check-line"></i>
                                                        }                                                        
                                                        </div>

                                                  </div>
                                              }
                                          </div>
                                          {this.state.doc_state === 1 &&
                                          <div className="d-content markdown-body" ref={this.markdownref}>
                                              <Markdown>{this.state.document}</Markdown>
                                          </div>
                                          }
                                          {this.state.doc_state === 2 &&
                                          <div className="d-content markdown-body code-markdown-body d-flex" ref={this.markdownref}>
                                              <Markdown>{this.state.document}</Markdown>
                                          </div>
                                          }
                                      </CardBody>
                                  </Card>
                              </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                            <Row className="mt-3">
                                
                                <Col xl={7}>
                                    <h4 className="card-title-x">Members</h4>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                  <Col>
                                                      <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={this.state.tableData}>
                                                          {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider keyField='id' columns={columns} data={this.state.tableData} search>
                                                              {toolkitProps => (
                                                                <React.Fragment>
                                                                  <Row>
                                                                    <Col>
                                                                      <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                          <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                          />
                                                                          <i className="ri-search-line appsearch" />
                                                                        </div>
                                                                      </div>
                                                                        <Button id="ux_add_new_project_button" onClick={this.handleinvitecustomer.bind(this)} className="float-right" color="primary" type="submit">Invite</Button>
                                                                    </Col>
                                                                  </Row>
                                                                  <Row>
                                                                    <Col>
                                                                      <div className="custom-table">
                                                                        <BootstrapTable
                                                                          noDataIndication="Table is Empty"
                                                                          keyField={"id"}
                                                                          responsive
                                                                          bordered={false}
                                                                          striped={false}
                                                                          defaultSorted={defaultSorted}
                                                                          classes={
                                                                            "table align-middle table-nowrap"
                                                                          }
                                                                          headerWrapperClasses={"thead-light"}
                                                                          {...toolkitProps.baseProps}
                                                                          {...paginationTableProps}
                                                                        />
                                                                      </div>
                                                                    </Col>
                                                                  </Row>
                                                                </React.Fragment>
                                                              )
                                                              }
                                                            </ToolkitProvider>
                                                          )
                                                          }</PaginationProvider>
                                                  </Col>
                                                </Row>
                                        </CardBody>
                                    </Card>
                                    <h4 className="card-title-x">Pending Invitation</h4>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                  <Col>
                                                      <PaginationProvider pagination={paginationFactory(pi_pageOptions)} keyField='id' columns={pi_columns} data={this.state.pi_tableData}>
                                                          {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider keyField='id' columns={pi_columns} data={this.state.pi_tableData} search>
                                                              {toolkitProps => (
                                                                <React.Fragment>
                                                                  <Row>
                                                                    <Col>
                                                                      <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                          <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                          />
                                                                          <i className="ri-search-line appsearch" />
                                                                        </div>
                                                                      </div>
                                                                    </Col>
                                                                  </Row>
                                                                  <Row>
                                                                    <Col>
                                                                      <div className="custom-table">
                                                                        <BootstrapTable
                                                                          noDataIndication="Table is Empty"
                                                                          keyField={"id"}
                                                                          responsive
                                                                          bordered={false}
                                                                          striped={false}
                                                                          defaultSorted={defaultSorted}
                                                                          classes={
                                                                            "table align-middle table-nowrap"
                                                                          }
                                                                          headerWrapperClasses={"thead-light"}
                                                                          {...toolkitProps.baseProps}
                                                                          {...paginationTableProps}
                                                                        />
                                                                      </div>
                                                                    </Col>
                                                                  </Row>
                                                                </React.Fragment>
                                                              )
                                                              }
                                                            </ToolkitProvider>
                                                          )
                                                          }</PaginationProvider>
                                                  </Col>
                                                </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        
                    </TabContent>

                    <Row><Col sm={6}>
                    <Modal
                          size="sm"
                          isOpen={this.state.keyclock_pwd_modal}
                          toggle={this.tog_keyclock_pwd_modal}
                          onClosed={this.onexit_keyclock_pwd_modal.bind(this)}
                          centered={true}
                          fade={this.state.access_token_pwdmodal_fade}
                          backdrop={this.state.access_token_pwdmodal_backdrop}
                          id="ux_regeneratetoken_modal"
                        >
                          <ModalHeader toggle={() => this.setState({ keyclock_pwd_modal: false })}>
                              Input your password
                          </ModalHeader>
                        <AvForm className="needs-validation" onValidSubmit={this.handleGenerateOuthToken}>
                        <ModalBody>

                              <div className="mb-3">
                                <Label className="form-label" htmlFor="password">Password</Label>
                                <AvField
                                name="password"
                                type="password"
                                errorMessage="Enter password"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                value={this.state.password}
                                />
                            </div>

                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="primary" className="waves-effect waves-light">Submit</Button>
                          </ModalFooter>
                            </AvForm>
                        </Modal>
                    </Col>
                    </Row>
                    <Row><Col sm={6}>
                    <Modal
                          size="sm"
                          isOpen={this.state.secret_pwd_modal}
                          toggle={this.tog_secret_pwd_modal}
                          centered={true}
                        >
                          <ModalHeader toggle={() => this.setState({ secret_pwd_modal: false })}>
                              Input your password
                          </ModalHeader>
                        <AvForm className="needs-validation" onValidSubmit={this.handleRegenerateSecret}>
                        <ModalBody>

                              <div className="mb-3">
                                <Label className="form-label" htmlFor="password">Password</Label>
                                <AvField
                                name="password"
                                type="password"
                                errorMessage="Enter password"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                value={this.state.password}
                                />
                            </div>

                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="primary" className="waves-effect waves-light">Submit</Button>
                          </ModalFooter>
                            </AvForm>
                        </Modal>
                    </Col>
                    </Row>
                    <Row><Col sm={6}>
                    <Modal
                          size="sm"
                          isOpen={this.state.invite_modal}
                          toggle={this.toggle_invite_modal.bind(this)}
                          centered={true}
                        >
                          <ModalHeader toggle={() => this.setState({ invite_modal: false })}>
                              Input the email address of the person you want to invite
                          </ModalHeader>
                        <AvForm className="needs-validation" onValidSubmit={this.sendinvitationemail.bind(this)}>
                        <ModalBody>

                              <div className="mb-3">
                                <Label className="form-label" htmlFor="password">Email</Label>
                                <AvField
                                name="email"
                                type="email"
                                errorMessage="Enter the email of the person you wanna invite"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label" htmlFor="country">Role</Label>
                                <select className="form-control" name='role' value={this.state.role} onChange={this.handleChangeGroup.bind(this)}>
                                  {this.state.group.map(o => <option key={o.id} value={o.id}>{o.rname}</option>)}
                                </select>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="primary" className="waves-effect waves-light">Send an Invitation Email</Button>
                          </ModalFooter>
                            </AvForm>
                        </Modal>
                    </Col>
                    </Row>
                    <div className="tbindex" id="del_confirm_modal">
                        <div className="">
                            <div className="modal fade show d-block" role="dialog">
                                <div className="modal-dialog modal-sm" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="text-center">
                                                <div>
                                                    <i className="yellow ri-alert-fill font-size-80"></i>
                                                </div>
                                                <div className="mb-4">
                                                <span className="font-size-16">Are you sure you want to remove this member from project <span className="font-weight-bold">"{this.state.name}"</span>?</span>
                                                </div>
                                                <div className="red font-weight-bold">
                                                <span>Please be certain.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.confirm_del_user.bind(this)} type="button" className="btn btn-primary">OK</button>
                                            <button onClick={this.cancel_del_user.bind(this)} type="button" className="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"></div>
                        </div>
                    </div>

                    <div className="tbindex" id="pi_del_confirm_modal">
                        <div className="">
                            <div className="modal fade show d-block" role="dialog">
                                <div className="modal-dialog modal-sm" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="text-center">
                                                <div>
                                                    <i className="yellow ri-alert-fill font-size-80"></i>
                                                </div>
                                                <div className="mb-4">
                                                <span className="font-size-16">Are you sure you want to cancel this invitation?</span>
                                                </div>
                                                <div className="red font-weight-bold">
                                                <span>Please be certain.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.confirm_cancel_pi.bind(this)} type="button" className="btn btn-primary">OK</button>
                                            <button onClick={this.cancel_cancel_pi_user.bind(this)} type="button" className="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"></div>
                        </div>
                    </div>

                    <Row>
                            <Col>
                                <Modal
                                  size="sm"
                                  isOpen={this.state.start_onboard_modal}
                                  toggle={this.tog_start_onboard_modal.bind(this)}
                                  centered={true}
                                >
                                  <ModalHeader toggle={() => this.setState({ start_onboard_modal: false })}>
                                      Congratulations!
                                  </ModalHeader>
                                <AvForm className="needs-validation">
                                <ModalBody>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                            You have successfully completed the second tour.<br/>
                                            You can continue the next tour where you could learn how to create model and get model API endpoint.</Label>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button onClick={this.skip_onboard.bind(this)} type="button" color="primary" className="waves-effect waves-light">Skip</Button>
                                      <Button onClick={this.start_onboard.bind(this)} type="button" color="primary" className="waves-effect waves-light">Ok</Button>
                                  </ModalFooter>
                                    </AvForm>
                                </Modal>
                            </Col>
                        </Row>

                  </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {settourpercent,settourstate, setdocument})(withRouter(ProjectDetailView));
