import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Header from "./header";
import {connect} from "react-redux";
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth
  } from "../../store/actions";

class EmailVerifyLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        };
        this.capitalizeFirstLetter.bind(this);
    }

    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
      };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
          if(this.props.isPreloader === true)
            {
              document.getElementById('preloader').style.display = "block";
              document.getElementById('status').style.display = "block";
    
              setTimeout(function(){ 
    
              document.getElementById('preloader').style.display = "none";
              document.getElementById('status').style.display = "none";
    
              }, 2500);
            }
            else
            {
              document.getElementById('preloader').style.display = "none";
              document.getElementById('status').style.display = "none";
            }
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        
        let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
        currentage = currentage.replaceAll("-" , " ");

        document.title =
          currentage + " | Ellietrac AI Document";

        if (this.props.leftSideBarTheme) {
            this.props.changeSidebarTheme(this.props.leftSideBarTheme);
        }
    
        if (this.props.layoutWidth) {
            this.props.changeLayoutWidth(this.props.layoutWidth);
        }
    
        if (this.props.leftSideBarType) {
            this.props.changeSidebarType(this.props.leftSideBarType);
        }
        if (this.props.topbarTheme) {
            this.props.changeTopbarTheme(this.props.topbarTheme);
        }
    
    }
    render() {
        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner">
                            <i className="ri-loader-line spin-icon"></i>
                        </div>
                    </div>
                </div>
                <div id="layout-wrapper">
                    <Header />
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return {
      ...state.Layout
    };
  };

export default connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth,
})(withRouter(EmailVerifyLayout));