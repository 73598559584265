import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import {Row, Col, Button, Alert, Container, Label, CardBody, Card} from "reactstrap";
import StarsRating from 'react-star-rate';
import {public_get} from "../../helpers/fackBackend_Helper";
import {API_URL} from "../../helpers/api_helper";
import {TESTIMONIAL_URL} from "../../helpers/url_helper";
// Carousel images

class MobileSlidewithindicator extends Component {

    constructor(props) {
        super(props);
        this.state = { activeIndex: 0, animating: false, items: [] };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.setState({animating: true});
    }

    onExited() {
        this.setState({animating: false});
    }

    next() {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.state.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    async componentDidMount() {
        const resp = await public_get(API_URL + TESTIMONIAL_URL);
        this.setState({items: resp});
    }

    render() {

        const slides = this.state.items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    slide={true}
                    key={item.id} >
                    <Row><Col className="p-3">
                    <Card className="feedback-card">
                        <CardBody>
                            <StarsRating disabled={true} value={item.rate}/>
                            <h4 className="elip-5 mb-4 font-size-18 land-gray-100-color">{item.content}</h4>
                            <h4 className="font-size-20 font-weight-bold land-gray-color">{ item.name}</h4>
                            <h4 className="font-size-18 land-gray-100-color">{ item.role}</h4>
                        </CardBody>
                    </Card>
                    </Col></Row>

                </CarouselItem>
            );
        });

        return (
            <React.Fragment>
                <Carousel
                    activeIndex={this.state.activeIndex}
                    next={this.next}
                    previous={this.previous} >
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
            </React.Fragment>
        );
    }
}

export default MobileSlidewithindicator;