import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";
import {alert3, confirmNextStep, getCurrentProject} from "../../helpers/fackBackend_Helper";


class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project_selected: "Project",
            project_list_open: false
        };
        this.projects = [];
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handlemenu = this.handlemenu.bind(this);
    }
    toggleMenu() {
        // this.props.sidebartoggleMenuCallback();
    }
    handlemenu(e, href) {
        e.preventDefault();
        if (href === "/billing"){
            confirmNextStep(2);
            this.props.history.push(href);
        } else if (href === "/project"){
            confirmNextStep(3);
            this.props.history.push(href);
        }
        else {
            this.props.history.push(href);
        }
    }
    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }

        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title li-settings li-menu-title">
                                <span className="ms-1 ">Settings</span>
                        </li>
                        <li>
                            <Link to="/profile" className=" waves-effect">
                                <i className="ri-user-2-fill"></i>
                                <span className="ms-1">{this.props.t('My Account')}</span>
                            </Link>
                        </li>
                        <li id="ux_billing_li">
                            <Link to="/billing" className=" waves-effect" onClick={ (e) => this.handlemenu(e, "/billing") }>
                                <i className="ri-bank-fill"></i>
                                <span className="ms-1">{this.props.t('Billing')}</span>
                            </Link>
                        </li>
                        <li id="ux_project_li">
                            <Link to="/project" className=" waves-effect" onClick={ (e) => this.handlemenu(e, "/project") }>
                                <i className="ri-stack-fill"></i>
                                <span className="ms-1">{this.props.t('Projects')}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
})(withNamespaces()(SidebarContent)));

