
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {recoverpwd, recoverpwdError} from '../../store/actions';
import {alert3} from "../../helpers/fackBackend_Helper";

// import images

class Recover extends Component {
    constructor(props) {
        super(props);
        this.state = {password: '', confirm_password: ''};
        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        values.token = this.props.match.params.token;
        if (values.password !== values.confirm_password) {
            this.props.recoverpwdError("Password doesn't match");return;
        }
        delete values.confirm_password;
        this.props.recoverpwd(values, this.props.history);
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={4}>
                                <div className="profile-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <h4 className="font-size-28 mt-4 font-w-600">Recover Password</h4>
                                                        <p className="text-muted mt-4">Reset your password </p>
                                                    </div>

                                                    <div className="p-2 mt-5">
                                                        {this.props.recoverError && this.props.recoverError ?
                                                            <Alert color="danger" className="mb-4">{this.props.recoverError}</Alert> : null}
                                                        {
                                                            this.props.message ?
                                                                <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                        }
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password <span className="span-red">*</span></Label>
                                                                <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password" validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }} />
                                                            </div>
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="confirm_password">Confirm Password <span className="span-red">*</span></Label>
                                                                <AvField name="confirm_password" value={this.state.confirm_password} type="password" className="form-control" id="confirm_password" placeholder="Enter password" validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }} />
                                                            </div>
                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Submit"}</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, recoverError, loading } = state.Recover;
    return { message, recoverError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { recoverpwd, recoverpwdError })(Recover)
);
