import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Container,
    Label,
    Card,
    CardBody,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form, Input, ModalHeader, ModalBody, ModalFooter, Modal
} from "reactstrap";
// Redux
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup  } from "availity-reactstrap-validation";
import {
    alert3,
    close_loading,
    getLoggedInUser, getTourState, putTourState,
    open_loading, parseEmail, parsefullname,
    private_put, timeout, confirmInitialStep, goNextStep,
} from "../../helpers/fackBackend_Helper";
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";
import {itscopes, companysizes} from "../../common/data/country";
import classnames from "classnames";
import logo from "../../assets/images/sm-logo.png";
import Tour from "../../components/Common/Tour";
import {settourobj} from "../../store/layout/actions";
import {ME_URL} from "../../helpers/url_helper";

class AdditionalInfo extends Component {
    constructor(props) {
        super(props);
        // handleValidSubmit
        this.state = {
            activeTab:1,
            itscope: "",
            companysize: "",
            start_onboard_modal: false,
        };
        this.tourref = React.createRef();
    }
    tog_start_onboard_modal() {
        this.setState(prevState => ({
          start_onboard_modal: !prevState.start_onboard_modal
        }));
        this.removeBodyCss();
    }
    start_onboard() {
        this.setState({ start_onboard_modal: false });
        confirmInitialStep(1);
        goNextStep(this.props.tourobj, 1);

    }
    skip_onboard() {
        this.props.history.push('/dashboard');
    }
    removeBodyCss(){
        document.body.classList.add("no_padding");
    }
    handleChangeitscope(e) {
        this.setState({ itscope: e.target.value });
    }
    handleChangecompanysize(e) {
        this.setState({ companysize: e.target.value });
    }
    skiptocompanytab() {
        this.setState({
            activeTab: 2
        });
    }
    skiptodocumenttypetab() {
        this.setState({
            activeTab: 3
        });
    }
    skip() {
        if (this.state.activeTab === 1) {
            this.skiptocompanytab();
        }
        if (this.state.activeTab === 2) {
            this.skiptodocumenttypetab();
        }
        if (this.state.activeTab === 3) {
            this.tog_start_onboard_modal();
        }
    }
    async update_profile(e, values) {
        if (localStorage.getItem("is_profile_completed") === 'false') {
            values.step = "profile";
        }
        values.firstname = parsefullname(values.fullname).firstname;
        values.lastname = parsefullname(values.fullname).lastname;
        delete values.fullname;
        try{
            const user = getLoggedInUser();
            var url = ME_URL + user.id + '/';

            const resp = await private_put(url, values);
            if (resp || resp === ''){
                alert3('Your profile has been updated successfully.', 'success');
                localStorage.setItem("is_profile_completed", true);
                user.firstname = values.firstname;
                user.lastname = values.lastname;
                localStorage.setItem("authUser", JSON.stringify(user));
                // this.props.history.push("/survey");
                this.setState({
                    activeTab: 2
                });
            }
        }
        catch (err) {
            alert3(err, 'error');
        }

    }
    goto_profilestep() {
        this.setState({
            activeTab: 1
        });
    }
    update_companyinfo(ev, error, values) {
        this.setState({
            activeTab: 3
        });
    }
    goto_companystep() {
        this.setState({
            activeTab: 2
        });
    }
    async update_documenttype(e, error, values) {
        values.step = "survey";
        values.itscope = this.state.itscope;
        values.companysize = this.state.companysize;
        try{
            const user = getLoggedInUser();
            var url = ME_URL + user.id + '/';

            const resp = await private_put(url, values);
            if (resp || resp === ''){
                alert3('Thanks for letting us know about you.', 'success');
            }
        }
        catch (err) {
        }
        this.tog_start_onboard_modal();
        // this.props.history.push('/dashboard');
    }
    componentDidMount() {
        let username = "";
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            username = obj.firstname + " " + obj.lastname;
        }
        this.setState({
            fullname: username
        });
        /*if (getTourState(2)){
            goNextStep(this.props.paymenttourobj, 2);
        }
        if (getTourState(3)){
            goNextStep(this.props.projecttourobj, 3);
        }
        if (getTourState(4)){
            goNextStep(this.props.modeltourobj, 4);
        }*/
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content nontbl-pg">
                    <Container fluid>
                        <Row className="display-center-row addinto-step-row">
                            <Col lg={4} className="display-center">
                                <div className="text-center">
                                    <img width="90" src={logo} />
                                    <h4 className="font-size-26 mb-4 mt-3">Welcome To Ellietrac!</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="display-center-row">
                            <Col lg={4} className="display-center">
                                <Card>
                                    <CardBody className="p-30">
                                        <a onClick={this.skip.bind(this)} className="justify-content-end d-flex hand font-size-15">skip <i className="ri-arrow-right-line"></i></a>
                                        <h5 className="font-size-17 mt-2 mb-5">We are always trying to get close to you.</h5>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard mt-2">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} >
                                                        <span className="step-number">01</span>
                                                        <span className="step-title">Your Profile</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })}>
                                                        <span className="step-number">02</span>
                                                        <span className="step-title">Your Business</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 3 })}>
                                                        <span className="step-number">03</span>
                                                        <span className="step-title">You are Ready!</span>
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                            <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
                                                <TabPane tabId={1}>
                                                    <div className="mt-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.update_profile.bind(this)}>
                                                            <div className="mb-4">
                                                                <Label className="form-label mb-3" htmlFor="firstname">What is your full name? &nbsp;<span className="span-red"> *</span></Label>
                                                                <AvField name="fullname" type="text" value={this.state.fullname} className="form-control" id="fullname" placeholder="" validate={{ required: { value: true } }} />
                                                            </div>
                                                            <div className="mb-4">
                                                                <Label className="form-label mb-3" htmlFor="company">What is your company name?</Label>
                                                                <AvField name="company" type="text" className="form-control" id="company" placeholder="" />
                                                            </div>
                                                            <div className="mb-4">
                                                                <Button className="float-right" color="primary" type="submit">Next</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <div className="mt-5">
                                                        <AvForm className="form-horizontal" onSubmit={this.update_companyinfo.bind(this)}>
                                                            <div className="mb-4">
                                                                <Label className="form-label mb-3">Which of the following best describes your role? &nbsp;</Label>
                                                                <select className="form-control" name='itscope' value={this.state.itscope} onChange={this.handleChangeitscope.bind(this)}>
                                                                    {itscopes.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className="mb-4">
                                                                <Label className="form-label mb-3">How many employees does your company have? &nbsp;</Label>
                                                                <select className="form-control" name='companysize' value={this.state.companysize} onChange={this.handleChangecompanysize.bind(this)}>
                                                                    {companysizes.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className="mb-4">
                                                                <Button className="float-right" color="primary" type="submit">Next</Button>
                                                                <Button className="" color="primary" type="button" onClick={this.goto_profilestep.bind(this)}>Previous</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <div className="mt-5">
                                                        <AvForm className="form-horizontal" onSubmit={this.update_documenttype.bind(this)}>
                                                            <div className="mb-4">
                                                                <Label className="form-label mb-4">What kind of documents do you want to process? &nbsp;</Label>
                                                                <AvCheckboxGroup name="doctype">
                                                                <Row className="mb-3">
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Custom Forms" className="form-check-input" type="checkbox" value="customform"/>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Invoices" className="form-check-input" type="checkbox" value="invoice"/>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-3">
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Purchase Orders" className="form-check-input" type="checkbox" value="purchaseorder"/>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Bank Statements" className="form-check-input" type="checkbox" value="bankstate"/>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-3">
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Utility Bills" className="form-check-input" type="checkbox" value="utilitybill"/>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Pay Stubs" className="form-check-input" type="checkbox" value="paystub"/>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-3">
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Receipts" className="form-check-input" type="checkbox" value="receipt"/>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Driver's Licenses" className="form-check-input" type="checkbox" value="drivelicense"/>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-3">
                                                                    <Col lg={6}>
                                                                        <AvCheckbox label="Passports" className="form-check-input" type="checkbox" value="passport"/>
                                                                    </Col>
                                                                    <Col lg={6}>

                                                                    </Col>
                                                                </Row>
                                                                </AvCheckboxGroup>
                                                            </div>
                                                            <div className="mb-4">
                                                                <Button className="float-right" color="primary" type="submit">Let's get started!</Button>
                                                                <Button className="" color="primary" type="button" onClick={this.goto_companystep.bind(this)}>Previous</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>
                                                </TabPane>

                                            </TabContent>

                                        </div>

                                    </CardBody></Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Modal
                                  size="sm"
                                  isOpen={this.state.start_onboard_modal}
                                  toggle={this.tog_start_onboard_modal.bind(this)}
                                  centered={true}
                                  modalClassName={"welcomemodal"}
                                >
                                  <ModalHeader toggle={() => this.setState({ start_onboard_modal: false })}>
                                      Welcome onboard!
                                      <a onClick={this.skip_onboard.bind(this)} className="float-right d-flex hand font-size-15">skip <i className="ri-arrow-right-line"></i></a>
                                  </ModalHeader>
                                <AvForm className="needs-validation">
                                <ModalBody>
                                      <div className="mb-3">
                                        <Label className="form-label">You are now one step closer to onboarding experience. Please start onboarding to learn how to work on our platform.</Label>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button onClick={this.start_onboard.bind(this)} type="button" color="primary" className="waves-effect waves-light">Getting Started with Tour</Button>
                                  </ModalFooter>
                                    </AvForm>
                                </Modal>
                            </Col>
                        </Row>


                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {})(withRouter(AdditionalInfo));
