import React, { Component, useContext } from "react";
import { Row, Col, Alert, Card, CardBody,  Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {back_to_dashboard, private_post,private_get, alert3, open_loading, close_loading} from '../../../helpers/fackBackend_Helper';
import {PROJECT_URL, USER_URL} from "../../../helpers/url_helper";

class ADCreateProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Project", link: "/"},
                {title: "Create", link: "#"},
            ],
            project_id: "",
            project_desp: "",
            owner: "",
            users: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        var users = await private_get(USER_URL);
        this.setState({
           users: users,
           owner: users[0].id
        });

    }
    handleChange(e) {
        this.setState({
            owner: e.target.value
        });
    }
    handleCancel() {
        this.props.history.push("/admin/project");
    }
    async handleSubmit(event, values) {
        try{
            var url = PROJECT_URL;

            values.owner = this.state.owner;
            open_loading();
            const resp = await private_post(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/admin/project");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">New Project</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Project ID </Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter Projectt ID"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="project_id"
                                                value={this.state.project_id}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Project Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter Proect Description"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="project_desp"
                                                value={this.state.project_desp}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label">User</Label>
                                                <select className="form-control" name='owner' id='owner' value={this.state.owner} onChange={this.handleChange.bind(this)}>
                                                  {this.state.users.map(o => <option key={o.id} value={o.id}>{o.firstname} {o.lastname}</option>)}
                                                </select>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADCreateProject