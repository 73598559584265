import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';
import {Container, Row, Col, CardBody, Card} from "reactstrap";
import BarChart from "../AllCharts/apex/barchart";
import LineChart from "../AllCharts/chartjs/linechart";
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn";
import { del, get, post, put } from "../../helpers/api_helper";
import {MODEL_URL, PROJECT_URL, STATISTICS_PROJECT_URL, STATISTICS_MODEL_URL, STATISTICS_API_URL, STATISTICS_SUMMARY_URL} from "../../helpers/url_helper";
import {alert3, getTourState, goNextStep, private_get} from "../../helpers/fackBackend_Helper";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summary: {},
            project_cn: "",
            model_cn: "",
            success_requests: "",
            failed_requests: "",
            reports : [
                { icon : "ri-stack-line", title : "Number of Sales", value : "1452", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-store-2-line", title : "Sales Revenue", value : "$ 38452", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", title : "Average Price", value : "$ 15.4", rate : "2.4%", desc : "From previous period" },
            ],
            chart_req_per_proj_series: [],
            chart_req_per_proj_options : {},
            chart_model_per_type_series: [],
            chart_model_per_type_options : {},
            chart_req_per_type_series: [],
            chart_req_per_type_options : {},
            chart_success_fail_req_options : {},
            chart_success_fail_req_series: [],
            //twopaircolors: ['#9a8fea', '#e7e2fa'],
            //twopaircolors: ['#9a8fea', '#c8bdff'],
            //twopaircolors: ['#afa7ed', '#e7e2fa'],
            //twopaircolors: ['#859cff', '#a2aee2'],
            // twopaircolors: ['#859cff', '#d5daf0'],
            twopaircolors: ['#859cff', '#ff926b'],
            //twopaircolors: ['#859cff', '#afa7ed'],
            //twopaircolors: ['#ebf28f', '#afa7ed'],
            // twopaircolors: ['#99b4de', '#b1d4d5'],
            // twopaircolors: ['#859cff', '#b1d4d5'],
            //twopaircolors: ['#9a8fea', '#b1d4d5'],
            //twopaircolors: ['#ca86d4', '#b1d4d5'],
            // twopaircolors: ['#9a8fea', '#e7e2fa'],
        }
    }
    async componentDidMount() {
        var summary = await private_get(STATISTICS_SUMMARY_URL);
        this.setState({
            project_cn: summary.projects,
            model_cn: summary.models,
            success_requests: summary.success_requests,
            failed_requests: summary.failed_requests
        });
        this.setState({
            chart_success_fail_req_series: [this.state.success_requests, this.state.failed_requests],

            chart_success_fail_req_options:  {
                labels: ["Success", "Failed"],
                colors: this.state.twopaircolors,
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                chart:{
                    height: 280,
                },
            }
        });
        var res = await private_get(STATISTICS_PROJECT_URL + "?duration=year");
        this.setState({
            chart_req_per_proj_series: [{
                name: "Success",
                data: res["success_usage"]
            }, {
                name: "Fail",
                data: res["failed_usage"]
            }]
        })
        var chart_req_per_proj_cn = res['success_usage'].length;
        var chart_req_per_proj_pc = this.get_tc_percentage_by_count(chart_req_per_proj_cn);

        this.setState({
            chart_req_per_proj_options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: chart_req_per_proj_pc + '%',
                        endingShape: 'rounded',
                        borderRadius: 5
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                
                colors: this.state.twopaircolors,
                xaxis: {
                    categories: res["categories"],
                    labels: {
                        show: true,
                        offsetY: 3,
                        maxHeight: 80,
                    }
                },
                yaxis: {
                    title: {
                        text: '(Count)'
                    }
                },
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#78909C',
                  height: 6,
                  offsetX: 0,
                  offsetY: 0
              },
                grid: {
                    borderColor: '#f1f1f1',
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " times"
                        }
                    }
                }
            }
        })
        var res = await private_get(STATISTICS_MODEL_URL);

        this.setState({
            chart_model_per_type_series: [{
                name: "Model count",
                data: res["models"]
            }],
        })
        var chart_model_per_type_cn = res['models'].length;
        var chart_model_per_type_pc = this.get_percentage_by_count(chart_model_per_type_cn);

        this.setState({
            chart_model_per_type_options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: chart_model_per_type_pc + '%',
                        endingShape: 'rounded',
                        borderRadius: 5
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                 legend: {
                     show: true,
                     showForSingleSeries: true,
                 },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 1
                },

                colors: ['#859cff'],
                grid: {
                    borderColor: '#f1f1f1',
                },
                yaxis: {
                    title: {
                        text: '(Count)'
                    }
                },
                xaxis: {
                    categories: res["categories"],
                    labels: {
                        show: true,
                        offsetY: 3,
                        maxHeight: 80,
                    }
                },
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#78909C',
                  height: 6,
                  offsetX: 0,
                  offsetY: 0
              },
            },
        })
        var api = await private_get(STATISTICS_API_URL + "?duration=year");
        this.setState({
            chart_req_per_type_series: [{
                name: "Success",
                data: api["success_usage"]
            }, {
                name: "Fail",
                data: api["failed_usage"]
            }]
        });
        var chart_req_per_type_cn = api['success_usage'].length;
        var chart_req_per_type_pc = this.get_tc_percentage_by_count(chart_req_per_type_cn);
        this.setState({
            chart_req_per_type_options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: chart_req_per_type_pc + '%',
                        endingShape: 'rounded',
                        borderRadius: 5
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                
                colors: this.state.twopaircolors,
                xaxis: {
                    categories: res["categories"],
                    labels: {
                        show: true,
                        offsetY: 3,
                        maxHeight: 80,
                    }
                },
                yaxis: {
                    title: {
                        text: '(Count)'
                    }
                },
                grid: {
                    borderColor: '#f1f1f1',
                },
                fill: {
                    opacity: 1
                },
                axisBorder: {
                  show: true,
                  color: '#78909C',
                  height: 1,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  color: '#78909C',
                  height: 6,
                  offsetX: 0,
                  offsetY: 0
              },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " times"
                        }
                    }
                }
            }
        })

        if (getTourState(2)){
            goNextStep(this.props.paymenttourobj, 2);
        }
        if (getTourState(3)){
            goNextStep(this.props.projecttourobj, 3);
        }
        if (getTourState(4)){
            goNextStep(this.props.modeltourobj, 4);
        }
        const is_joined = localStorage.getItem('is_joined');
        if (is_joined === 'true') {
            const project_name = localStorage.getItem('project_name');
            alert3('You have joined the ' + project_name + ' project successfully', 'success');
            localStorage.removeItem("is_joined");
            localStorage.removeItem("project_name");
        }

    }

    get_percentage_by_count(cn) {
        var percent = 12 * (cn / 5)
        if (cn > 30) {
            percent = 12 * (30 / 5);
        }
        return percent.toFixed(2);
    }
    get_tc_percentage_by_count(cn) {
        var percent = 20 * (cn / 4);
        if (cn > 16) {
            percent = 20 * (16 / 4);
        }
        return percent.toFixed(2);
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content nontbl-pg">
                    <Container fluid className="dashboard">
                        <Row>
                            <Col md={3}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-20 mb-2 text-center">Total Projects</p>
                                                <h4 className="mb-0 font-size-50 text-center">{this.state.project_cn}</h4>
                                            </div>
                                            <div className="text-primary dash-icon">
                                                <i className="ri-stack-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>

                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-20 mb-2 text-center">Total Models</p>
                                                <h4 className="mb-0 font-size-50 text-center">{this.state.model_cn}</h4>
                                            </div>
                                            <div className="text-primary dash-icon">
                                                <i className="ri-vip-diamond-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>

                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-20 mb-2 text-center">Successful Documents</p>
                                                <h4 className="mb-0 font-size-50 text-center">{this.state.success_requests}</h4>
                                            </div>
                                            <div className="text-primary dash-icon">
                                                <i className="ri-information-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>

                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-20 mb-2 text-center">Failed Documents</p>
                                                <h4 className="mb-0 font-size-50 text-center">{this.state.failed_requests}</h4>
                                            </div>
                                            <div className="text-primary dash-icon">
                                                <i className="ri-alert-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">API Requests Per Project</p>
                                            </div>
                                        </div>
                                        <ReactApexChart options={this.state.chart_req_per_proj_options} series={this.state.chart_req_per_proj_series} type="bar" height={280} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">API Requests Per Model Type</p>
                                            </div>
                                        </div>
                                        <ReactApexChart options={this.state.chart_req_per_type_options} series={this.state.chart_req_per_type_series} type="bar" height={280} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Model Count Per Model Type</p>
                                            </div>
                                        </div>
                                        <ReactApexChart options={this.state.chart_model_per_type_options} series={this.state.chart_model_per_type_series} type="bar" height="280" />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Successful vs Failed Documents</p>
                                            </div>
                                        </div>
                                        <div className="piediv">
                                            <ReactApexChart options={this.state.chart_success_fail_req_options} series={this.state.chart_success_fail_req_series} type="donut" height="280" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {})(withRouter(Dashboard));
