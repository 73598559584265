import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody,  Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {
    private_del,
    private_post,
    private_get,
    getCurrentProject,
    alert3,
    open_loading, close_loading, goNextStep, confirmNextStep
} from '../../helpers/fackBackend_Helper';
import {MY_MODEL_URL, MY_PROJECT_URL, PROJECT_URL} from "../../helpers/url_helper";
import {APIS_URL} from "../../helpers/url_helper";
import {connect} from "react-redux";
import { withRouter, Link } from 'react-router-dom';

class CreateModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Model", link: "/"},
                {title: "Create", link: "#"},
            ],
            name: "",
            desc: "",
            apis: [],
            projects: [],
            selected_api: ""
        };
        this.handleprojectChange = this.handleprojectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onclick_model_card = this.onclick_model_card.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    onclick_model_card(api_id) {
        this.setState({
            selected_api: api_id
        });
    }
    handleprojectChange() {

    }
    handleCancel() {
        this.props.history.push("/model");
        const ux_state = confirmNextStep(4);
        if (ux_state) {
            this.props.modeltourobj.introJs.exit();
        }
    }
    async componentDidMount() {
        var projects = await private_get(MY_PROJECT_URL);
        var apis = await private_get(APIS_URL);
        this.setState({
            projects: projects,
            apis:apis
        });
        goNextStep(this.props.modeltourobj, 4);
    }

    async handleSubmit(event, values) {
        try{
            if (this.state.selected_api === '') {
                alert3("Select a model that you want", "warning");return;
            }
            values.api = this.state.selected_api;
            const cur_project = getCurrentProject();
            if (cur_project === null) {
                alert3('Project is not selected, retry after select a project', "warning");return;
            }
            values.project = cur_project.id;
            open_loading();
            const resp = await private_post(MY_MODEL_URL, values);
            if (resp) {
                alert3('Success', "success");
                confirmNextStep(4);
                this.props.history.push("/model");
            }
        } catch (err) {
            alert3(err, "error");
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid id="ux_model_create_form">
                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                  {/* Render Breadcrumb */}
                  <h4 className="card-title-x">New Model</h4>
                    <Row>
                        <Col lg={4} className="model-input-dv">

                            <Card>
                                <CardBody>
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">Model Name</Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter Model Name"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="name"
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Model Description</Label>
                                                <AvField
                                                name="desc"
                                                type="textarea"
                                                errorMessage="Enter Model Description"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="desc"
                                                value={this.state.desc}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="button-items">
                                                <Button color="primary" type="submit">Save</Button>
                                                <Button type="button" onClick={this.handleCancel} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col>
                            <Row>
                                {this.state.apis.map(o =>
                                    <div className="model-card-dv" key={o.id} id={o.id} onClick={() => this.onclick_model_card(o.id)}>
                                        <Card className="model-card">
                                            <CardBody>
                                                <Row className="mb-2">
                                                    <Col xs={12}>
                                                        <span className="font-size-16 fw-bold">{o.name}</span>
                                                        {this.state.selected_api == o.id ?
                                                            (
                                                                <i className="mdi mdi-marker-check model-check-i d-inline-block"></i>)
                                                            :
                                                            (
                                                            <i className="mdi mdi-marker-check model-check-i"></i>)
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <span className="elip-5">{o.desc}</span>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    </AvForm>
                  </Container>
                </div>
            </React.Fragment>
        )
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {})(withRouter(CreateModel));
