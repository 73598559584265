import React, { Component, useContext } from "react";
import {Row, Col, Alert, Card, CardBody, Button, Label, Container, InputGroup} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import {
    private_post,
    private_get,
    alert3,
    open_loading,
    close_loading,
    private_put
} from '../../helpers/fackBackend_Helper';
import {TESTIMONIAL_URL} from "../../helpers/url_helper";

class ADUpdateTestimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        const _id = this.props.match.params.id;
        console.log(this.props);
        const url = TESTIMONIAL_URL + _id + "/";
        var resp = await private_get(url);
        if (resp) {
            this.setState({
                name: resp.name,
                content: resp.content,
                rate: resp.rate,
                role: resp.role,
            });
        }
    }

    handleCancel() {
        this.props.history.push("/admin/testimonial");
    }
    async handleSubmit(event, values) {
        try{
            const url = TESTIMONIAL_URL + this.props.match.params.id + "/";
            open_loading();
            const resp = await private_put(url, values);
            if (resp) {
                alert3('Success', 'success');
                this.props.history.push("/admin/testimonial");
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">Edit Testimonial</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation" >
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">Name </Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter the name"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="content">Content</Label>
                                                <AvField
                                                name="content"
                                                type="textarea"
                                                errorMessage="Enter the content of testimonial"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.content}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="rate">Rate</Label>
                                                    <AvField
                                                    name="rate"
                                                    type="number"
                                                    step="0.01"
                                                    errorMessage="Enter the rate"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.rate}
                                                    />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="role">Role</Label>
                                                <AvField
                                                name="role"
                                                type="text"
                                                errorMessage="Enter the role"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.role}
                                                />
                                            </div>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button className="waves-effect me-1" color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Cancel</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADUpdateTestimonial