import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    getLoggedInUser,
    private_put,
    alert3,
    open_loading,
    close_loading,
    private_get,
    private_post
} from '../../../helpers/fackBackend_Helper';
import {PAYMENT_GATEWAY_URL} from "../../../helpers/url_helper";

class ADPaymentGateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            api_key: "",
            webhook_secret: "",
            id: null,
            has_gateway: false
        };
    }
    handleCancel() {
        this.props.history.goBack();
    }
    async handlesubmit(event, values) {
        try{
            open_loading();
            if (this.state.has_gateway) {
              var url = PAYMENT_GATEWAY_URL + this.state.id + "/";
              var resp_p = await private_put(url, values);
              if (resp_p) {
                alert3('Success', 'success');
              }
            } else {
              var resp = await private_post(PAYMENT_GATEWAY_URL, values);
              if (resp) {
                alert3('Success', 'success');
              }
            }
        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    async componentDidMount() {
      var resp = await private_get(PAYMENT_GATEWAY_URL);
      if (resp.length > 0){
        this.setState({
          name: resp[0].name,
          api_key: resp[0].api_key,
          webhook_secret: resp[0].webhook_secret,
          has_gateway: true,
          id: resp[0].id
        });
      }
    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row>
                        <Col xl={6}>
                            <h4 className="card-title-x">Stripe Gateway Settings</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handlesubmit.bind(this)} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">Name</Label>
                                                <AvField
                                                name="name"
                                                type="text"
                                                errorMessage="Enter the name of payment gateway"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.name}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="api_key">API Secret Key</Label>
                                                <AvField
                                                name="api_key"
                                                type="text"
                                                errorMessage="Enter the secret key"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.api_key}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="webhook_secret">Webhook Secret Key</Label>
                                                <AvField
                                                name="webhook_secret"
                                                type="text"
                                                errorMessage="Enter the webhook secret key"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.webhook_secret}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Back</Button>
                                                </div></Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADPaymentGateway