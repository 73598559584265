import React from "react";
import { Container } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>

                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
