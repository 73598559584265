import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Input,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup, DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from "reactstrap";
import {
    confirmNextStep,
    getLoggedInUser, gettourpercent, getTourStateValue,
    goNextStep,
    private_get,
    private_post,
    private_put, timeout
} from '../../helpers/fackBackend_Helper';
import {ME_URL, MY_MODEL_URL, MY_PROJECT_URL} from "../../helpers/url_helper";
import copy from "copy-to-clipboard";
import {connect} from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import {settourpercent, setdocument, settourstate} from "../../store/layout/actions";
import Markdown from 'markdown-to-jsx';
import "github-markdown-css/github-markdown.css";
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import {AvForm} from "availity-reactstrap-validation";


class ModelDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: "Model", link: "/"},
                {title: "Detail", link: "#"},
            ],
            name: "",
            id: "",
            desc: "",
            type_name: "",
            url: "",
            menu: false,
            doc_state: 1,
            document: "",
            currnet_lang_code: "",
            start_onboard_modal: false,
            langtext: "Python",
            api_endpoint_copied: false,
            doc_copied: false
        };
        this.width_portion = {'title': '4', 'content': '8'};
        this.handleCancel = this.handleCancel.bind(this);
        this.markdownref = React.createRef();

    }
    tog_start_onboard_modal() {
        this.setState(prevState => ({
          start_onboard_modal: !prevState.start_onboard_modal
        }));
        this.removeBodyCss();
    }
    removeBodyCss(){
        document.body.classList.add("no_padding");
    }
    skip_onboard() {
        this.setState({ start_onboard_modal: false });
    }
    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    async select_lang(lib) {
        const model_id = this.props.match.params.id;
        var url = MY_MODEL_URL + model_id + "/helper?type=code";
        if (lib === 'python') {
            if (this.state.langtext === "Python") return;
            this.setState({langtext: "Python"});
            url += "&lang=py";
        }
        if (lib === 'php') {
            if (this.state.langtext === "PHP") return;
            this.setState({langtext: "PHP"});
            url += "&lang=php";
        }
        if (lib === 'nodejs') {
            if (this.state.langtext === "Node.js") return;
            this.setState({langtext: "Node.js"});
            url += "&lang=js";
        }
        var hresp = await private_get(url);
        this.setState({document: hresp.doc});
        var currnet_lang_code = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;

        this.markdownref.current.querySelectorAll('pre code').forEach((block) => {
          hljs.highlightElement(block);
        });

        var code_text = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;
        this.setState({currnet_lang_code: currnet_lang_code});
        const lines = code_text.split(/\n/);

        var html = "";
        for(var k = 0; k < lines.length; k++) {
            var line = '<span class="line-number">' + (k + 1) + '</span>'  + lines[k] + '\n';
            html += line;
        }
        this.markdownref.current.querySelectorAll('pre code')[0].innerHTML = html;

    }
    async toggletab(i) {
        if (this.state.doc_state === 1 && i === 1) return;
        if (this.state.doc_state === 2 && i === 2) return;
        var type = "about";
        if (i === 2) {
            type = "code";
            if (this.state.langtext === 'Python') {
                type += "&lang=py";
            }
            if (this.state.langtext === 'PHP') {
                type += "&lang=php";
            }
            if (this.state.langtext === 'Node.js') {
                type += "&lang=js";
            }
        }
        const model_id = this.props.match.params.id;
        const url = MY_MODEL_URL + model_id + "/helper?type=" + type;

        var hresp = await private_get(url);
        this.setState({doc_state: i});
        this.setState({document: hresp.doc});

        var currnet_lang_code = '';
        if (i === 2) {
            currnet_lang_code = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;
        }

        this.markdownref.current.querySelectorAll('pre code').forEach((block) => {
          hljs.highlightElement(block);
        });

        if (i === 1) return;
        var code_text = this.markdownref.current.querySelectorAll('pre code')[0].innerHTML;
        this.setState({currnet_lang_code: currnet_lang_code});
        const lines = code_text.split(/\n/);

        var html = "";
        for(var k = 0; k < lines.length; k++) {
            var line = '<span class="line-number">' + (k + 1) + '</span>'  + lines[k] + '\n';
            html += line;
        }
        this.markdownref.current.querySelectorAll('pre code')[0].innerHTML = html;
    }
    async copycodeUrl() {
        copy(this.state.currnet_lang_code);
        this.setState({doc_copied: true});
        await timeout(3000);
        this.setState({doc_copied: false});
    }
    handleCancel() {
        this.props.history.push("/model");
        const tourstate = confirmNextStep(4);
        if(tourstate){
            this.props.modeltourobj.introJs.exit();
        }
    }
    async copymodelUrl() {
        var copyText = document.getElementById("modelurl");
        copy(copyText.value);
        const tourstate = confirmNextStep(4);
        if(tourstate){
            this.props.modeltourobj.introJs.exit();

            const user = getLoggedInUser();
            const url = ME_URL + user.id + '/';
            const user_info = await private_get(url);
            var tour = user_info.tour;
            var values = {};
            if (tour !== '') {
                values = tour;
            }
            values.step3 = 1;
            var param = {
                tour: values
            };
            const resp = await private_put(url, param);
            if (resp) {
                this.props.settourpercent(gettourpercent(values));
                var _tour_state = getTourStateValue(values);
                this.props.settourstate(_tour_state);
            }
            this.tog_start_onboard_modal();
        }
        this.setState({api_endpoint_copied: true});
        await timeout(3000);
        this.setState({api_endpoint_copied: false});
    }

    async componentDidMount() {
        const model_id = this.props.match.params.id;
        this.props.setdocument("...");
        const url = MY_MODEL_URL + model_id + "/";
        var resp = await private_get(url);
        if (resp) {
            this.setState({
                name: resp.name,
                id: resp.id,
                desc: resp.desc,
                type_name: resp.type_name,
                url: process.env.REACT_APP_TYK_URL + resp.url
            });
        }
        const hurl = MY_MODEL_URL + model_id + "/helper?type=about";
        var hresp = await private_get(hurl);
        this.setState({document: hresp.doc});
        this.markdownref.current.querySelectorAll('pre code').forEach((block) => {
          hljs.highlightElement(block);
        });
        goNextStep(this.props.modeltourobj, 4);
    }

    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                    <Row>
                        <Col lg={6}>
                            <h4 className="card-title-x">Model Information</h4>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 ">
                                                <Label className="form-label">Model Name</Label>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>: {this.state.name}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 ">
                                                <Label className="form-label">Model Description</Label>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>: {this.state.desc}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={this.width_portion.title}>
                                            <div className="mb-3 ">
                                                <Label className="form-label">Model Type</Label>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="mb-3">
                                                <span>: {this.state.type_name}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={this.width_portion.title} className="d-flex align-items-center">
                                            <div className="">
                                                <div><Label className="form-label">Model API endpoint</Label></div>
                                            </div>
                                        </Col>
                                        <Col xs={this.width_portion.content}>
                                            <div className="">
                                                <InputGroup>
                                                  <input type="text" id="modelurl" className="form-control" value={this.state.url} readOnly/>
                                                  <span className="input-group-append" onClick={this.copymodelUrl.bind(this)}>
                                                    <Button id="ux_copy_model_url" type="button" color="primary">
                                                        {!this.state.api_endpoint_copied &&
                                                            <i className="ri-file-copy-line" />
                                                        }
                                                        {this.state.api_endpoint_copied &&
                                                            <i className="ri-check-line" />
                                                        }
                                                        
                                                    </Button>
                                                  </span>
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <Button color="primary" onClick={this.handleCancel}>
                                Back
                            </Button>
                        </Col>
                        <Col lg={6}>
                            <h4 className="card-title-x">Documentation</h4>
                          <Card className="project-doc">
                              <CardBody>
                                  <div className="d-header d-flex justify-content-between">
                                      {this.state.doc_state === 1 &&
                                          <div className="d-flex doc-tab">
                                              <span onClick={this.toggletab.bind(this, 1)} className="hand active">About</span>
                                              <span onClick={this.toggletab.bind(this, 2)} className="hand">Code</span>
                                          </div>
                                      }
                                      {this.state.doc_state === 2 &&
                                          <div className="d-flex doc-tab">
                                              <span onClick={this.toggletab.bind(this, 1)} className="hand">About</span>
                                              <span onClick={this.toggletab.bind(this, 2)} className="hand active">Code</span>
                                          </div>
                                      }
                                      {this.state.doc_state === 2 &&
                                          <div className="d-flex align-items-center" id="lang">
                                              <Dropdown isOpen={this.state.menu} toggle={this.toggle.bind(this)}
                                                        className="d-inline-block user-dropdown me-2">
                                                  <DropdownToggle tag="button" className="btn selectlgbtn">
                                                      <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                                                      <span className="d-none d-xl-inline-block ms-1 text-transform">{this.state.langtext}</span>
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-menu-end dropdown-doc">
                                                      <DropdownItem key={"python"}
                                                                    onClick={this.select_lang.bind(this, "python")}>Python</DropdownItem>
                                                      <DropdownItem key={"nodejs"}
                                                                    onClick={this.select_lang.bind(this, "nodejs")}>Node.js</DropdownItem>
                                                      <DropdownItem key={"php"}
                                                                    onClick={this.select_lang.bind(this, "php")}>PHP</DropdownItem>

                                                  </DropdownMenu>
                                              </Dropdown>
                                              <div className="d-flex">
                                                {!this.state.doc_copied &&
                                                    <i className="hand ri-file-copy-line" onClick={this.copycodeUrl.bind(this)}></i>
                                                }
                                                {this.state.doc_copied &&
                                                    <i className="hand ri-check-line"></i>
                                                }
                                              </div>

                                          </div>
                                      }
                                  </div>
                                  {this.state.doc_state === 1 &&
                                  <div className="d-content markdown-body" ref={this.markdownref}>
                                      <Markdown>{this.state.document}</Markdown>
                                  </div>
                                  }
                                  {this.state.doc_state === 2 &&
                                  <div className="d-content markdown-body code-markdown-body d-flex" ref={this.markdownref}>
                                      <Markdown>{this.state.document}</Markdown>
                                  </div>
                                  }
                              </CardBody>
                          </Card>
                        </Col>
                    </Row>


                    <Row>
                            <Col>
                                <Modal
                                  size="sm"
                                  isOpen={this.state.start_onboard_modal}
                                  toggle={this.tog_start_onboard_modal.bind(this)}
                                  centered={true}
                                >
                                  <ModalHeader toggle={() => this.setState({ start_onboard_modal: false })}>
                                      Congratulations!
                                  </ModalHeader>
                                <AvForm className="needs-validation">
                                <ModalBody>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                            You have successfully completed the last tour.</Label>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button onClick={this.skip_onboard.bind(this)} type="button" color="primary" className="waves-effect waves-light">Ok</Button>
                                  </ModalFooter>
                                    </AvForm>
                                </Modal>
                            </Col>
                        </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {settourpercent,settourstate, setdocument})(withRouter(ModelDetailView));
