import React, { Component } from "react";
import {Row, Col, Alert, Button, Container, Card, CardBody, Spinner} from "reactstrap";
// Redux
import { AvForm } from "availity-reactstrap-validation";
import { API_URL } from "../../helpers/api_helper";
import { public_post, timeout, alert3 } from "../../helpers/fackBackend_Helper";
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";
import emailicon from "../../assets/images/email/email-icon-102.jpg";

class EmailVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_email_verified: false,
            is_email_verifing_failed: false,
            errormsg: ""
        };
    }

    async componentDidMount() {
        var values = {};
        values.token = this.props.match.params.token;
        var url = API_URL + '/auth/email_verify/confirm/';
        try{
            const resp = await public_post(url, values);
            if (resp){
                this.setState({
                    is_email_verified: true
                });
                await timeout(8000);
                window.close();
            }
        }
        catch (err) {
            alert3(err, 'error');
            this.setState({
                is_email_verifing_failed: true,
                errormsg: err
            });
        }
    }
    componentWillUnmount(){

    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <div className="preprocess-header">
                            <span className="text-logo">Ellietrac AI Document</span>
                        </div>
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={4}>
                                <div className="p-4 d-flex min-vh-100 align-items-center">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={10}>
                                                <Card>
                                                    <CardBody>
                                                        {!this.state.is_email_verifing_failed && !this.state.is_email_verified &&
                                                            <div className="">
                                                                <div className="text-center">
                                                                    <img src={emailicon} alt="" width="120" />
                                                                </div>
                                                                <div className="text-center">
                                                                    <h4 className="font-size-28 mt-5 font-w-600">Verifing your email</h4>

                                                                    <Spinner className="purple mt-4" />

                                                                    <div className="mt-4 mb-3">
                                                                        <span>Your email is being verified...</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }
                                                        {!this.state.is_email_verifing_failed && this.state.is_email_verified &&
                                                            <div className="">
                                                                <div className="text-center">
                                                                    <img src={emailicon} alt="" width="120" />
                                                                </div>
                                                                <div className="text-center">
                                                                    <h4 className="font-size-28 mt-5 font-w-600">Your email is verified</h4>
                                                                    <div className="mt-4">
                                                                        <i className="mdi mdi-check-circle purple font-size-30"></i>
                                                                    </div>
                                                                    <div className="mt-4 mb-3">
                                                                        <span>This browser window/tab will attempt to close automatically in number of seconds, or you may close manually. Please go to the browser tab where you are in the registration process.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.is_email_verifing_failed &&
                                                            <div className="">
                                                                <div className="text-center">
                                                                    <img src={emailicon} alt="" width="120" />
                                                                </div>
                                                                <div className="text-center">
                                                                    <h4 className="font-size-28 mt-5 font-w-600">Your email is not verified</h4>
                                                                    <div className="mt-4">
                                                                        <i className="mdi mdi-alert purple font-size-30"></i>
                                                                    </div>
                                                                    <div className="mt-4 mb-3">
                                                                        <span>{this.state.errormsg}</span><span> Please retry to sign in again and find resend link.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </CardBody>
                                                </Card>


                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, { })(withRouter(EmailVerification));
