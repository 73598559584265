import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    getLoggedInUser,
    private_post,
    private_put,
    back_to_dashboard,
    alert3,
    open_loading,
    close_loading, private_get
} from '../../../helpers/fackBackend_Helper';
import {ME_URL} from "../../../helpers/url_helper";

class ADProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirm_password: "",
        };
    }
    handleCancel() {
        this.props.history.goBack();
    }
    async handleresetpassword(event, values) {
        if (values.password !== values.confirm_password) {
            alert3("Password doesn't match", "warning");return;
        }
        delete values.confirm_password;

        try{
            open_loading();
            const profile = getLoggedInUser();
            const url = ME_URL + profile.id + "/";
            const resp = await private_put(url, values);
            if (resp) {
                alert3('Success', 'success');
            }
        }
        catch(err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    componentDidMount() {

    }
    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                  <Row>
                        <Col xl={6}>
                            <h4 className="card-title-x">Reset password</h4>
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleresetpassword.bind(this)} className="needs-validation" >
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">New Password</Label>
                                                <AvField
                                                name="password"
                                                type="password"
                                                errorMessage="Enter password"
                                                className="form-control"
                                                validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                                value={this.state.password}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm_password">Confirm Password</Label>
                                                <AvField
                                                name="confirm_password"
                                                type="password"
                                                errorMessage="Enter password"
                                                className="form-control"
                                                validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }}
                                                value={this.state.confirm_password}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="button-items">
                                                    <Button color="primary" type="submit">Save</Button>
                                                    <Button type="button" onClick={this.handleCancel.bind(this)} className="waves-effect me-1 btn btn-secondary">Back</Button>
                                                </div></Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ADProfile