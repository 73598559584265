import React, { Component } from "react";

import { connect } from "react-redux";
import {
    Button,
} from "reactstrap";

import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "./profile";
//Import Megamenu
// Redux Store
//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import menu from "../../assets/images/menu.png";
import { withRouter } from "react-router-dom";
//Import Social Profile Images

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box d-flex">
                                <span className="logo-lg emailverifylogo">
                                    <span className="text-logo">Ellietrac AI Document</span>
                                </span>
                            </div>
                        </div>

                        <div className="d-flex">
                            <ProfileMenu />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, { })(withRouter(Header));
