import React, { Component } from "react";
import { Row, Col, Button, Alert, Container, Label } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// action
import { registerUser, registerUserFailed, apiError } from '../../store/actions';

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import ReCaptchaV2 from "react-google-recaptcha";
// import images

import {API_URL, axiosApi} from "../../helpers/api_helper";
import {
    getLoggedInUser,
    isUserAuthenticated,
    parseEmail,
    private_get,
    public_get,
    public_post,
    timeout
} from "../../helpers/fackBackend_Helper";
import logo from "../../assets/images/logo-light.png";
import {INVITATION_URL, JOIN_URL} from "../../helpers/url_helper";

class Join extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            password: "",
            firstname: "",
            lastname: "",
            error_singup: "",
            error_singin: "",
            initialrender: true,
            normal_render: false,
            normal_error: ""

        };
        this.recaptchaRef = React.createRef();
    }
    signup_ajoin() {
        this.setState({
            initialrender: true,
            error_singup: ""
        });
    }
    signin_ajoin() {
        this.setState({
            initialrender: false,
            error_singin: ""
        });
    }
    async handleSubmitSignupaJoin(e, values) {
        const captchaToken = await this.recaptchaRef.current.getValue();
        if (!captchaToken) {
            this.setState({error_singup: "Error verifying reCAPTCHA, please try again."});
            this.recaptchaRef.current.reset();
            return;
        }
        this.recaptchaRef.current.reset();
        values.username = values.email;
        values.firstname = parseEmail(values.email);
        values.is_operator = false;
        try {
            const url = API_URL + "/auth/register/";
            const response = await public_post(url, values);
            axiosApi.defaults.headers['Authorization'] = "JWT " + response.access;
            localStorage.setItem("access_token", response.access);
            localStorage.setItem("refresh_token", response.refresh);
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("is_operator", 'false');
            localStorage.removeItem("current_project");
            localStorage.setItem("is_profile_completed", response.user.is_profile_completed);
            localStorage.setItem("is_email_verified", response.user.is_email_verified);

            const token = this.props.match.params.token;
            const join_url = JOIN_URL + "?token=" + token;
            await private_get(join_url);

            await timeout(1000);
            this.props.history.push('/email-verify');

        } catch(err) {
            this.setState({error_singup: err});
        }

    }
    async handleSubmitSigninaJoin(e, values) {
        try {
            const url = API_URL + "/auth/login/";
            await public_get(url);
            values.is_operator = false;
            const response = await public_post(url, values);
            axiosApi.defaults.headers['Authorization'] = "JWT " + response.access;
            localStorage.setItem("access_token", response.access);
            localStorage.setItem("refresh_token", response.refresh);
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("is_operator", 'false');
            localStorage.removeItem("current_project");
            localStorage.setItem("is_profile_completed", response.user.is_profile_completed);
            localStorage.setItem("is_email_verified", response.user.is_email_verified);

            const token = this.props.match.params.token;
            const join_url = JOIN_URL + "?token=" + token;
            await private_get(join_url);

            await timeout(1000);
            if (response.user.is_email_verified === false) {
                this.props.history.push('/email-verify');
            } else {
                this.props.history.push('/dashboard');
            }


        } catch(err) {
            this.setState({error_singin: err});
        }
    }

    async componentDidMount() {
        var token = this.props.match.params.token;
        var invitation_url = API_URL + INVITATION_URL + "?token=" + token;
        try {
            const resp = await public_get(invitation_url);
            const is_logged_in = isUserAuthenticated();
            if (!is_logged_in) {
                this.setState({inviter: resp['invitor'], project_name: resp['project']});
                this.setState({normal_render: true});
            } else{
                const join_url = JOIN_URL + "?token=" + token;
                await private_get(join_url);
                const me = getLoggedInUser();
                localStorage.setItem("is_joined", 'true');
                localStorage.setItem("project_name", resp['project']);
                if (me.is_email_verified === false) {
                    this.props.history.push('/email-verify');
                } else {
                    this.props.history.push('/dashboard');
                }
            }

        } catch (err) {
            this.setState({normal_render: false, normal_error: err});
        }
    }


    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <div className="pub-header">
                            <div>
                            </div>
                        </div>
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={5} className="logincol">
                                <div className="authentication-page-content p-4 d-flex align-items-center">
                                    <div className="w-100 c-pubpage-signup">
                                        {this.state.normal_render &&
                                        <Row className="justify-content-center">
                                            <Col>

                                                {this.state.initialrender &&
                                                <div className="loginform">
                                                    <div className="text-center mb-5 mt-5">
                                                        <img src={logo} />
                                                    </div>
                                                    <div className="text-center pl-2 pr-2">
                                                        <h4 className="text-left font-size-22 mt-4 mb-3">You have been invited to the <span className="purple">{this.state.project_name}</span> project</h4>
                                                    </div>

                                                    <div className="pl-2 pr-2 mb-4">
                                                        <h4 className="font-size-14">Invited by <span>{this.state.inviter}</span></h4>
                                                    </div>

                                                    <div className="clogintitle mt-3 mb-3 text-center">
                                                        <h4 className="font-size-20">Already have an account ? <a onClick={this.signin_ajoin.bind(this)} className="hand"> Sign in</a> </h4>
                                                    </div>

                                                    {this.state.error_singup && <Alert color="danger">{this.state.error_singup}</Alert>}


                                                    <div className="p-2 mt-2 mb-5">
                                                        <AvForm onValidSubmit={this.handleSubmitSignupaJoin.bind(this)} className="form-horizontal" >

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="useremail">Email <span className="span-red">*</span></Label>
                                                                <AvField name="email" value={this.state.email} validate={{ email: true, required: true }} type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                                            </div>
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password <span className="span-red">*</span></Label>
                                                                <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password" validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }} />
                                                            </div>
                                                            <div className="auth-form-group-custom mb-4">
                                                            <ReCaptchaV2
                                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                                ref={this.recaptchaRef}
                                                            />
                                                            </div>

                                                            <div className="text-center">
                                                                <Button color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">Create account and join</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>



                                                </div>
                                                }

                                                {!this.state.initialrender &&
                                                <div className="loginform">
                                                    <div className="text-center mb-5 mt-5">
                                                        <img src={logo} />
                                                    </div>
                                                    <div className="text-center pl-2 pr-2">
                                                        <h4 className="text-left font-size-22 mt-4 mb-3">You have been invited to the <span className="purple">{this.state.project_name}</span> project</h4>
                                                    </div>

                                                    <div className="pl-2 pr-2 mb-4">
                                                        <h4 className="font-size-14">Invited by <span>{this.state.inviter}</span></h4>
                                                    </div>

                                                    <div className="clogintitle mt-3 mb-3 text-center">
                                                        <h4 className="font-size-20">Don't have an account ? <a className="hand" onClick={this.signup_ajoin.bind(this)}> Sign up</a> </h4>
                                                    </div>

                                                    {this.state.error_singin && <Alert color="danger">{this.state.error_singin}</Alert>}

                                                    <div className="p-2 mt-2 mb-5">
                                                        <AvForm onValidSubmit={this.handleSubmitSigninaJoin.bind(this)} className="form-horizontal" >

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="useremail">Email <span className="span-red">*</span></Label>
                                                                <AvField name="email" value={this.state.email} validate={{ email: true, required: true }} type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                                            </div>
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password <span className="span-red">*</span></Label>
                                                                <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password" validate={{ required: { value: true }, minLength: { value: 8, errorMessage: "Min 8 chars." } }} />
                                                            </div>

                                                            <div className="text-center">
                                                                <Button color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">Sign in and join</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>



                                                </div>
                                                }

                                            </Col>

                                        </Row>
                                        }
                                        {!this.state.normal_render &&
                                            <Row className="justify-content-center">
                                                <Col>
                                                    <div className="loginform">
                                                        <div className="text-center mb-5 mt-5">
                                                            <img src={logo} />
                                                        </div>
                                                        <div className="text-center pl-2 pr-2">
                                                            <h4 className="text-left font-size-22 mt-4 mb-3">You have been invited to the project at ELLIETRAC</h4>
                                                        </div>
                                                        <div className="mb-5 mt-5">
                                                            {this.state.normal_error && <Alert color="danger">{this.state.normal_error}</Alert>}
                                                        </div>
                                                    </div>

                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default withRouter(connect(mapStatetoProps, { })(Join));
