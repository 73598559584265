import React, { Component } from "react";
import {
    Row,
    Col,
    Container,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardBody,
    Card,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonGroup,
    Badge,
    Modal,
    ModalHeader,
    ModalBody, ModalFooter
} from "reactstrap";
import {
    alert3,
    back_to_dashboard,
    close_loading,
    open_loading,
    private_post,
    private_get,
    private_del,
    getLoggedInUser,
    goNextStep,
    confirmNextStep,
    private_put,
    gettourpercent,
    getTourStateValue,
    confirmInitialStep
} from "../../helpers/fackBackend_Helper";
import {settourpercent, settourstate} from "../../store/layout/actions";
import classnames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {BILLING_URL, PAYMENTHISTORY_URL, ME_URL, MY_MEMBERSHIP_TYPE_URL} from "../../helpers/url_helper";
import {connect} from "react-redux";
import { withRouter} from 'react-router-dom';
//Import Breadcrumb

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabJustify: "1",
            projectData: [],
            payment_method: {},
            has_payment_method: false,
            holdername:"",
            cvc:"",
            exp_month:"",
            exp_year:"",
            number: "",
            addbuttontoggle: false,
            more_menu: false,
            numberdigit:"",
            start_onboard_modal: false,

        };
        this.toggleMore = this.toggleMore.bind(this);
        this.amountformatter = this.amountformatter.bind(this);
        this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
        this.statusformatter = this.statusformatter.bind(this);
        this.downloadformatter = this.downloadformatter.bind(this);
        this.download_invoice = this.download_invoice.bind(this);
    }

    tog_start_onboard_modal() {
        this.setState(prevState => ({
          start_onboard_modal: !prevState.start_onboard_modal
        }));
        this.removeBodyCss();
    }
    start_onboard() {
        this.setState({ start_onboard_modal: false });
        confirmInitialStep(5);
        goNextStep(this.props.tourtoprojecttourobj, 5);
    }
    removeBodyCss(){
        document.body.classList.add("no_padding");
    }
    skip_onboard() {
        this.setState({ start_onboard_modal: false });
    }

    async confirm_del_pm() {
        document.getElementById('pm_del_confirm_modal').style.display = 'none';
        const static_pk = "1";
        const url = BILLING_URL + static_pk + "/";
        try{
          open_loading();
          const resp = await private_del(url);
          if (resp || resp === '') {
                alert3("Success", 'success');
                const user = getLoggedInUser();
                user.has_credit = false;
                localStorage.setItem("authUser", JSON.stringify(user));
                this.loadpaymentMethods();
                this.toggleCustomJustified("1");

                const meurl = ME_URL + user.id + '/';
                const user_info = await private_get(meurl);
                var tour = user_info.tour;
                var values = {};
                if (tour !== '') {
                    values = tour;
                }
                values.step1 = 0;
                var param = {
                    tour: values
                };
                const resp = await private_put(meurl, param);
                if (resp) {
                    this.props.settourpercent(gettourpercent(values));
                    var _tour_state = getTourStateValue(values);
                    this.props.settourstate(_tour_state);
                }
          }
        } catch (err) {
          alert3(err, 'error');
        }
        close_loading();
    }
    cancel_del_pm() {
        document.getElementById('pm_del_confirm_modal').style.display = 'none';
    }
    removepaymentmethod() {
        document.getElementById('pm_del_confirm_modal').style.display = 'block';

    }
    toggleMore() {
        this.setState(prevState => ({
            more_menu: !prevState.more_menu
        }));
    }
    toggleCustomJustified(tab) {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab
			});
		}
	}
    async componentDidMount() {
        this.loadpaymentMethods();
        goNextStep(this.props.paymenttourobj, 2);
    }
    async loadpaymentMethods() {
        try {
            var url = BILLING_URL;
            var methods = await private_get(url);
            var user = getLoggedInUser();
            if (methods.length > 0) {
                this.setState({
                    payment_method: methods[0],
                    has_payment_method: true,
                    addbuttontoggle: false,
                    numberdigit: methods[0].content.number.slice(-4),
                    name: user.firstname + " " + user.lastname
                });
            } else {
                this.setState({
                    has_payment_method: false,
                    addbuttontoggle: false
                });
            }

            var resp = await private_get(PAYMENTHISTORY_URL);
            const data = [];
            for (var i=0; i < resp.length; i++) {
              var param = {};
              param.id = resp[i].id;
              param.date = resp[i].date.slice(0, 10);
              param.amount = resp[i].amount;
              param.status = resp[i].status;
              param.invoice_url = resp[i].invoice_url;
              data.push(param);
            }
            this.setState({projectData: data});

            var m_resp = await private_get(MY_MEMBERSHIP_TYPE_URL + "?current=true");
            if (m_resp.length === 0) {
                this.setState({has_membership: false});
                this.setState({no_membership_noti: "You have no membership yet."});
            } else{
                this.setState({has_membership: true});

                this.setState({start_date: m_resp[0].start_date.slice(0, 10)});
                this.setState({end_date: m_resp[0].end_date.slice(0, 10)});
                this.setState({discount: m_resp[0].discount * 100});
                this.setState({type_name: m_resp[0].type_name});
                this.setState({projects: m_resp[0].quotas.projects});
                this.setState({models: m_resp[0].quotas.models});
                this.setState({pages: m_resp[0].quotas.pages});
                this.setState({a_pages: parseInt(m_resp[0].credit.pages)});
            }
        }
        catch(e) {
        }
    }
    download_invoice(cell, row, row_index) {
        const anchor = document.createElement('a');
        anchor.href = row.invoice_url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    amountformatter(cell, row, rowIndex, formatExtraData) {
        var amount = parseFloat(row.amount) / 100;
        return (
            <div>
                ${amount}
            </div>
        )
    }
    downloadformatter(cell, row, rowIndex, formatExtraData) {
        var invoice_url = row.invoice_url;
        return (
            <div>
                <a title={invoice_url} onClick={() => {this.download_invoice(cell, row, rowIndex)}} className="btn btn-light btn-rounded">Invoice <i className="mdi mdi-download ms-2"></i></a>
            </div>
        )
    }

    statusformatter(cell, row, rowIndex, formatExtraData) {
        var status = "";
        var badge = "";
        if (parseInt(row.status) === 0) {
            status = 'No Paid';
            badge = "warning";
        } else if (parseInt(row.status) === 1) {
            status = 'Processing';
            badge = "info";
        } else if (parseInt(row.status) === 2) {
            status = 'Paid';
            badge = "success";
        } else if (parseInt(row.status) === 3) {
            status = 'Failed';
            badge = "danger";
        }
        return (
            <Badge className={"font-size-12 badge-soft-" + badge} color={badge} pill>
                {status}
              </Badge>
        )
    }
    async addpaymentmethod() {
        this.setState({addbuttontoggle: true});
        confirmNextStep(2);
        this.countintervalId = setInterval(() => {
            if (document.getElementById('ux_add_payment_method') !== null) {
                goNextStep(this.props.paymenttourobj, 2);
                clearInterval(this.countintervalId);
            }
        }, 100);

    }
    async handleSubmit(e, values) {
        try{
            var url = BILLING_URL;
            open_loading();
            const resp = await private_post(url, values);
            if (resp) {
                alert3('Success', 'success');
                const user = getLoggedInUser();
                user.has_credit = true;
                localStorage.setItem("authUser", JSON.stringify(user));
                this.loadpaymentMethods();
                this.toggleCustomJustified("1");

                /* read my tour state from database and update */
                const url = ME_URL + user.id + '/';
                const user_info = await private_get(url);
                var tour = user_info.tour;
                var values = {};
                if (tour !== '') {
                    values = tour;
                }
                values.step1 = 1;
                var param = {
                    tour: values
                };
                const resp = await private_put(url, param);
                if (resp) {
                    this.props.settourpercent(gettourpercent(values));
                    var _tour_state = getTourStateValue(values);
                    this.props.settourstate(_tour_state);
                }
                const tourstate = confirmNextStep(2);
                if(tourstate){
                    this.props.paymenttourobj.introJs.exit();
                    this.tog_start_onboard_modal();
                }
            }
        } catch (err) {
            alert3(err, 'error');
        }
        close_loading();
    }
    handleCancel() {

        this.setState({addbuttontoggle: false});
        const tourstate = confirmNextStep(2);
        if(tourstate){
            this.props.paymenttourobj.introJs.exit();
        }

    }
    render() {
        const invoicecolumns = [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        },
        {
          dataField: 'name',
          text: 'Date',
          sort: true
        }, {
          dataField: 'desc',
          text: 'View Invoice',
          sort: true
        }, {
          dataField: 'is_active',
          text: 'Download',
          sort: true
        }
        ];
        const paymentHistorycolumns = [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        },
        {
          dataField: 'date',
          text: 'Date',
          sort: true
        }, {
          dataField: 'amount',
                formatter: this.amountformatter,
          text: 'Amount',
        },{
          dataField: 'status',
          text: 'Status',
                formatter: this.statusformatter,
        }, {
          dataField: 'download',
                text: 'Invoice Url',
                formatter: this.downloadformatter
        }
        ];

        const defaultSorted = [{
          dataField: 'name',
          order: 'asc'
        }];
        const customTotal = (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Showing { from } to { to } of { size } Results
          </span>
        );
        const pageOptions = {
            alwaysShowAllBtns: true,
            prePageText: '<',
            nextPageText: '>',
            withFirstAndLast : true,
            firstPageText: '<<',
            lastPageText: '>>',
            showTotal: true,
            disablePageTitle: true,
            sizePerPage: 20,
            paginationTotalRenderer: customTotal,
            totalSize: this.state.projectData.length,
            sizePerPageList: [{
                text: '5', value: 5
              }, {
                text: '10', value: 10
              }, {
                text: '20', value: 20
              }, {
                text: 'All', value: this.state.projectData.length
              }]
        };
        return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  {/* Render Breadcrumb */}
                    <Row className="mb-5">
                        <Col>
                            <Button color="primary" type="button" onClick={() => back_to_dashboard(this.props.history)}>Back to dashboard</Button>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={7}>
                            <Nav tabs className="nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: this.state.activeTabJustify === "1"
                                        })}
                                        onClick={() => {
                                            this.toggleCustomJustified("1");
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Payment Details</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: this.state.activeTabJustify === "3"
                                        })}
                                        onClick={() => {
                                            this.toggleCustomJustified("3");
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Payment History</span>
                                    </NavLink>
                                </NavItem>

                            </Nav>

                            <TabContent activeTab={this.state.activeTabJustify}>
                                <TabPane tabId="1" className="p-3">
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="card-title-x">Payment Method</h4>
                                            <Card id="payment_method_div">
                                                <CardBody>
                                                    <Row className="mb-4">
                                                      <Col>
                                                          <div className="d-flex align-items-center justify-content-end">
                                                              {!this.state.has_payment_method && !this.state.addbuttontoggle &&
                                                                  <Button id="ux_add_payment_method_button" onClick={this.addpaymentmethod.bind(this)} className="float-right" color="primary" type="submit">Add Payment Method</Button>
                                                              }
                                                          </div>
                                                      </Col>
                                                    </Row>
                                                    {this.state.has_payment_method &&
                                                        <Row className="mb-3" id="payment_list_div">
                                                            <Col>
                                                                <div className="d-flex justify-content-between align-items-baseline">
                                                                    <div>
                                                                        <div className="d-block mb-1"><i className="fab fa-cc-mastercard font-size-24 align-middle me-2"></i><span>Credit / Debit Card</span></div>
                                                                        <div className="d-block mb-1"><span> &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679; </span><span>{this.state.numberdigit}</span></div>
                                                                        <div className="d-block"><span>{this.state.name}</span></div>
                                                                    </div>
                                                                    <ButtonGroup className="mb-2 mb-sm-0 float-right">
                                                                        <Dropdown isOpen={this.state.more_menu} toggle={this.toggleMore}>
                                                                            <DropdownToggle tag="button" className="more btn btn-primary waves-light waves-effect dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                <i className="mdi mdi-dots-vertical"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className="dropdown-menu-end">
                                                                                <DropdownItem onClick={this.removepaymentmethod.bind(this)}>Remove</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </ButtonGroup>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {!this.state.has_payment_method && this.state.addbuttontoggle &&
                                                        <Row className="mb-3" id="payment_method_add_div">
                                                            <Col>
                                                                <AvForm onValidSubmit={this.handleSubmit.bind(this)} className="needs-validation" >
                                                                    <Row>
                                                                        <Col>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label" htmlFor="holdername">Name on Card</Label>
                                                                            <AvField
                                                                            name="holdername"
                                                                            type="text"
                                                                            errorMessage="Enter your name on card"
                                                                            className="form-control"
                                                                            validate={{ required: { value: true } }}
                                                                            value={this.state.holdername}
                                                                            />
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label" htmlFor="cardnum">Card Number</Label>
                                                                            <AvField
                                                                            name="number"
                                                                            type="text"
                                                                            placeholder="0000 0000 0000 0000"
                                                                            errorMessage="Enter your card number"
                                                                            className="form-control"
                                                                            validate={{ required: { value: true } }}
                                                                            value={this.state.number}
                                                                            />
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg={6}>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label" htmlFor="cvc">CVC</Label>
                                                                            <AvField
                                                                            name="cvc"
                                                                            type="text"
                                                                            placeholder="Enter cvc code"
                                                                            errorMessage="Enter cvc code"
                                                                            className="form-control"
                                                                            validate={{ required: { value: true } }}
                                                                            value={this.state.cvc}
                                                                            />
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg={6}>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label" htmlFor="expire_month">Expiry Month</Label>
                                                                            <AvField
                                                                            name="exp_month"
                                                                            type="text"
                                                                            placeholder="MM"
                                                                            errorMessage="Enter expiry month"
                                                                            className="form-control"
                                                                            validate={{ required: { value: true } }}
                                                                            value={this.state.exp_month}
                                                                            />
                                                                        </div>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label" htmlFor="expire_year">Expiry Year</Label>
                                                                            <AvField
                                                                            name="exp_year"
                                                                            type="text"
                                                                            placeholder="YY"
                                                                            errorMessage="Enter expiry year"
                                                                            className="form-control"
                                                                            validate={{ required: { value: true } }}
                                                                            value={this.state.exp_year}
                                                                            />
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <div className="button-items">
                                                                            <Col>
                                                                                <Button id="ux_add_payment_method" color="primary" type="submit">Add</Button>
                                                                                <Button color="primary" type="button" onClick={this.handleCancel.bind(this)}>Cancel</Button>
                                                                            </Col>
                                                                        </div>
                                                                    </Row>
                                                                </AvForm>
                                                            </Col>
                                                        </Row>
                                                    }

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <h4 className="card-title-x">Membership</h4>
                                            {this.state.has_membership &&
                                            <Card>
                                                <CardBody>
                                                    <Row className="mt-4">
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Membership Type</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                <span>: {this.state.type_name}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Current Membership Cycle</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                <span>: {this.state.start_date}  --  {this.state.end_date}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Discount</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                <span>: {this.state.discount}%</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Quotas</Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <ul>
                                                                    <li>
                                                                        <Label className="form-label">Projects</Label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                <span>: {this.state.projects}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <ul>
                                                                    <li>
                                                                        <Label className="form-label">Models</Label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                <span>: {this.state.models}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <ul>
                                                                    <li>
                                                                        <Label className="form-label">Pages</Label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                <span>: {this.state.pages}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Available</Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <div className="mb-3">
                                                                <ul>
                                                                    <li>
                                                                        <Label className="form-label">Pages</Label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <div className="mb-3">
                                                                {this.state.a_pages > 0 &&
                                                                    <span>: {this.state.a_pages}</span>
                                                                }
                                                                {this.state.a_pages <= 0 &&
                                                                    <span className="pink">There is no available page</span>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                            }
                                            {!this.state.has_membership &&
                                                 <Card>
                                                    <CardBody>
                                                        <Row className="mt-4">
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label className="form-label">{this.state.no_membership_noti}</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                 </Card>
                                            }
                                        </Col>
                                    </Row>

                                </TabPane>
                                <TabPane tabId="3" className="p-3">
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                  <div className="custom-table">
                                                    <BootstrapTable bordered={ false } noDataIndication="Table is Empty" classes={"table align-middle table-nowrap"} defaultSorted={defaultSorted} keyField='id' data={ this.state.projectData } columns={ paymentHistorycolumns } pagination={ paginationFactory(pageOptions) } />
                                                  </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>

                            </TabContent>
                        </Col>
                    </Row>
                    <div className="tbindex" id="pm_del_confirm_modal">
                        <div className="">
                            <div className="modal fade show d-block" role="dialog">
                                <div className="modal-dialog modal-sm" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="text-center">
                                                <div>
                                                    <i className="yellow ri-alert-fill font-size-80"></i>
                                                </div>
                                                <div className="mb-4">
                                                <span className="font-size-16">Are you sure you want to remove this payment method?</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.confirm_del_pm.bind(this)} type="button" className="btn btn-primary">OK</button>
                                            <button onClick={this.cancel_del_pm.bind(this)} type="button" className="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"></div>
                        </div>
                    </div>


                    <Row>
                            <Col>
                                <Modal
                                  size="sm"
                                  isOpen={this.state.start_onboard_modal}
                                  toggle={this.tog_start_onboard_modal.bind(this)}
                                  centered={true}
                                >
                                  <ModalHeader toggle={() => this.setState({ start_onboard_modal: false })}>
                                      Congratulations!
                                  </ModalHeader>
                                <AvForm className="needs-validation">
                                <ModalBody>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                            You have successfully completed the first tour by adding payment method.<br/>
                                            You can continue the next tour where you could learn how to create project and get oauth tokens.</Label>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button onClick={this.skip_onboard.bind(this)} type="button" color="primary" className="waves-effect waves-light">Skip</Button>
                                      <Button onClick={this.start_onboard.bind(this)} type="button" color="primary" className="waves-effect waves-light">Ok</Button>
                                  </ModalFooter>
                                    </AvForm>
                                </Modal>
                            </Col>
                        </Row>
                  </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {settourpercent, settourstate})(withRouter(Billing));
