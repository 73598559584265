import React, { Component } from 'react';

import {Row, Col, Button, Alert, Container, Label, CardBody, Card} from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';
import logo from "../../assets/images/logo-light.png";
import {public_get} from "../../helpers/fackBackend_Helper";
import {API_URL} from "../../helpers/api_helper";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = { email: "", password: "" };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event, values) {
        try{
            const url = API_URL + "/auth/login/";
            await public_get(url);
        } catch(err) {
            this.props.apiError(err);
        }
        this.props.checkLogin(values, this.props.history);
    }

    componentDidMount() {
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    render() {

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <div className="pub-header">
                            <div>
                            </div>
                        </div>
                        <Row className="g-0 justify-content-md-center">
                            <Col lg={4} className="logincol">
                                <div className="authentication-page-content p-4 d-flex align-items-center">
                                    <div className="w-100 c-pubpage-signin">
                                        <Row className="justify-content-center">
                                            <Col>
                                                <div className="loginform">
                                                    <div className="text-center mb-5 mt-5">
                                                        <img src={logo} />
                                                    </div>
                                                    <div className="text-center pl-2 pr-2">
                                                        <h4 className="text-left font-size-28 mt-4 mb-3">Welcome back!</h4>
                                                    </div>


                                                    {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null}

                                                    <div className="p-2 mt-2">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="email">Email</Label>
                                                                <AvField name="email" value={this.state.email} type="text" className="form-control" id="email" validate={{ email: true, required: true }} placeholder="Enter email" />
                                                            </div>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password</Label>
                                                                <AvField name="password" value={this.state.password} type="password" className="form-control" id="password" validate={{ required: true }} placeholder="Enter password" />
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-p-100 w-md waves-effect waves-light" type="submit">Sign in</Button>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Link to="/forgot-password" className=""><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 mb-5 text-center">
                                                        <div>Don't have an account ? <Link to="/register" className=""> Sign up </Link> </div>

                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));